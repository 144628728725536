/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Check, ChevronsUpDown } from 'lucide-react';
import { ChatBotMeta, Webhook } from 'utils/bot';
import {
  webhookTypeDescription,
  webhookTypeIcons,
  webhookTypeNames,
} from 'components/Chatbot/Customizations/Webhooks';
import { Badge } from 'components/ui/badge';
import { cn } from 'utils/cn';

const UpdateWebhookDialog: React.FC<{
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  hookToUpdate?: number;
  chatbotVariables: string[];
  close: () => void;
}> = ({ chatInterface, setChatInterface, hookToUpdate, chatbotVariables, close }) => {
  const [show, setShow] = useState<boolean>(false);
  const [createHookEventSelection, setCreateHookEventSelection] = useState<Webhook['type']>('query');
  const [createHookEndpoint, setCreateHookEndpoint] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

  const hookToUpdateExists = hookToUpdate !== undefined;

  useEffect(() => {
    if (hookToUpdateExists) {
      const item = chatInterface.webhooks[hookToUpdate];
      setCreateHookEventSelection(item.type);
      setCreateHookEndpoint(item.url);
      if (item.type === 'data_collection') {
        const hookVar = (item.variables || []).filter((v) => chatbotVariables.includes(v));
        setSelectedVariables(hookVar);
      }
      setIsValid(true);
      setShow(true);
    }
  }, [hookToUpdate]);

  const validateEndpoint = (apiEndpoint: string) => {
    const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(:\d+)?([\/\w \.-]*)*\/?([\w\/]*)?/;
    setIsValid(urlRegex.test(apiEndpoint));
  };

  const isValidToUpdate = useMemo(() => {
    if (hookToUpdateExists) {
      const item = chatInterface.webhooks[hookToUpdate as number];
      const isWebhookVariableValid = item.type === 'data_collection' && selectedVariables.length > 0;
      return (
        isValid &&
        item &&
        createHookEventSelection &&
        createHookEndpoint &&
        (createHookEventSelection !== 'data_collection' || isWebhookVariableValid) &&
        (createHookEventSelection !== item.type ||
          createHookEndpoint !== item.url ||
          JSON.stringify(selectedVariables) !== JSON.stringify(item.variables))
      );
    }
    return false;
  }, [createHookEventSelection, createHookEndpoint, hookToUpdateExists, isValid, selectedVariables]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
      setCreateHookEventSelection('query');
      setCreateHookEndpoint('');
    }, 300);
  };

  const updateHook = () => {
    const newHooks = [...chatInterface.webhooks];
    newHooks[hookToUpdate as number] = {
      type: createHookEventSelection as Webhook['type'],
      url: createHookEndpoint,
      ...(createHookEventSelection === 'data_collection' && {
        variables: selectedVariables,
      }),
    };
    setChatInterface({
      ...chatInterface,
      webhooks: newHooks,
    });
    hide();
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Edit Webhook
                </DialogTitle>
                <div className="mt-4">
                  <h3 className="text-base font-medium leading-none tracking-tight flex items-center mb-2">
                    Event
                  </h3>
                  <Listbox value={createHookEventSelection} onChange={setCreateHookEventSelection}>
                    <div className="relative mt-1">
                      <ListboxButton className="flex h-10 w-full items-center rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 hover:bg-accent">
                        {webhookTypeIcons[createHookEventSelection]}
                        <span className="block truncate ml-2">
                          {webhookTypeNames[createHookEventSelection]}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronsUpDown
                            strokeWidth={1.75}
                            className="h-4 w-4 text-gray-400 min-w-[16px]"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions className="absolute z-[5] px-1 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {['query', 'response', 'data_collection'].map((event, eventIdx) => (
                            <ListboxOption
                              key={eventIdx}
                              className="relative flex hover:bg-accent cursor-pointer w-full select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-5"
                              value={event}
                            >
                              {({ selected }) => (
                                <>
                                  <div>
                                    <div className="flex items-center">
                                      {webhookTypeIcons[event]}
                                      <span className="block truncate ml-2">{webhookTypeNames[event]}</span>
                                    </div>
                                    <p className="text-xs text-muted-foreground">
                                      {webhookTypeDescription[event]}
                                    </p>
                                  </div>
                                  {selected ? (
                                    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                                      <Check
                                        strokeWidth={1.75}
                                        className="h-4 w-4 min-w-[16px] text-success"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Transition>
                    </div>
                  </Listbox>
                  {createHookEventSelection === 'data_collection' && (
                    <>
                      <h3 className="text-base font-medium leading-none tracking-tight flex items-center mt-4 mb-2">
                        Variables
                      </h3>
                      <div className="flex items-center gap-2 flex-wrap max-h-[230px] overflow-auto">
                        {chatbotVariables.length > 0 ? (
                          <>
                            {chatbotVariables.map((variable) => {
                              const isSelected = selectedVariables.includes(variable);
                              return (
                                <Badge
                                  key={`${variable}-variable`}
                                  variant={isSelected ? 'default' : 'outline'}
                                  className={cn(
                                    'text-sm px-4 w-fit rounded-md cursor-pointer transition-all',
                                    isSelected ? '' : 'hover:bg-muted',
                                  )}
                                  onClick={() => {
                                    if (isSelected) {
                                      setSelectedVariables(selectedVariables.filter((v) => v !== variable));
                                    } else {
                                      setSelectedVariables([...selectedVariables, variable]);
                                    }
                                  }}
                                >
                                  {variable}
                                </Badge>
                              );
                            })}
                          </>
                        ) : (
                          <Badge variant="outline" className="text-sm px-4 w-fit rounded-md font-medium">
                            No existing variables
                          </Badge>
                        )}
                      </div>
                    </>
                  )}
                  <h3 className="text-base font-medium leading-none tracking-tight flex items-center mt-4 mb-2">
                    API Endpoint
                  </h3>
                  <div className="flex-1 max-w-[868px]">
                    <Input
                      type="text"
                      autoComplete="off"
                      value={createHookEndpoint}
                      placeholder="https://my-custom-site.com/webhook/"
                      onChange={(e) => {
                        setCreateHookEndpoint(e.target.value);
                        validateEndpoint(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && isValidToUpdate) {
                          updateHook();
                        }
                      }}
                    />
                    {!isValid && createHookEndpoint.length > 0 && (
                      <p className="text-xs text-destructive ml-1 mt-1">Invalid API.</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={hide} variant="outline">
                    Close
                  </Button>
                  <Button
                    disabled={!isValidToUpdate}
                    size="default"
                    onClick={() => {
                      updateHook();
                    }}
                  >
                    {hookToUpdateExists ? 'Update' : 'Create'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateWebhookDialog;
