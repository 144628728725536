/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Label } from 'components/ui/label';
import { ShieldEllipsis, Unlock, Lock, Frame } from 'lucide-react';
import { ChatBotMeta, defaultAIErrorMessage } from 'utils/bot';
import { Textarea } from 'components/ui/textarea';
import { Toggle } from 'components/ui/toggle';
import { Switch } from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface SecurityProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  domainsTextValue: string;
  setDomainsTextValue: (meta: string) => void;
  domainsError: boolean;
  canUseUserAuthentication: boolean;
}

const Security: React.FC<SecurityProps> = ({
  chatInterface,
  setChatInterface,
  domainsTextValue,
  setDomainsTextValue,
  domainsError,
  canUseUserAuthentication,
}) => {
  const { appTitle } = useWhitelabelData();
  const userAuthenticationActive = canUseUserAuthentication && chatInterface?.webhook_auth?.enabled;
  const isAuthenticationEnabled = chatInterface?.webhook_auth?.enabled;

  return (
    <AccordionItem
      id="customizations-security"
      className="border bg-background rounded-md transition-all"
      value="security"
    >
      <AccordionTrigger className="px-6 text-left hover:no-underline">
        <div>
          <div className="flex items-centrer gap-2">
            <ShieldEllipsis strokeWidth={1.75} className="w-6 h-6" />
            Security
          </div>
          <p className="text-xs text-muted-foreground ml-8">
            Configure your AI chat’s visibility and usage limits.
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="visibility">
            Visibility
          </Label>
          <p className="text-sm text-muted-foreground">
            Private chatbots are exclusive to your account, Public chatbots are accessible to anyone with the
            link and can be embedded on your website, while Embed chatbots can be embedded on your website
            without link sharing allowed. For Public and Embed modes Domains field is required, You won&apos;t
            be able to embed bot on domains not explicitly specified in this list.
          </p>
          {userAuthenticationActive && (
            <p className="text-warning text-sm">
              To change visibility you must disable &quot;Authentication&quot; feature.
            </p>
          )}
          <div id="visibility" className="flex items-center gap-2">
            <Toggle
              disabled={userAuthenticationActive}
              aria-label="secure"
              pressed={chatInterface.visibility === 'private'}
              onPressedChange={() => {
                setChatInterface({
                  ...chatInterface,
                  visibility: 'private',
                  ...(isAuthenticationEnabled && {
                    webhook_auth: {
                      ...chatInterface?.webhook_auth,
                      enabled: false,
                    },
                  }),
                });
              }}
            >
              <Lock strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Private
            </Toggle>
            <Toggle
              aria-label="public"
              disabled={userAuthenticationActive}
              pressed={chatInterface.visibility === 'public'}
              onPressedChange={() => {
                setChatInterface({
                  ...chatInterface,
                  visibility: 'public',
                  ...(isAuthenticationEnabled && {
                    webhook_auth: {
                      ...chatInterface?.webhook_auth,
                      enabled: false,
                    },
                  }),
                });
              }}
            >
              <Unlock strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Public
            </Toggle>
            <Toggle
              aria-label="hybrid"
              disabled={userAuthenticationActive}
              pressed={chatInterface.visibility === 'hybrid'}
              onPressedChange={() => {
                setChatInterface({
                  ...chatInterface,
                  visibility: 'hybrid',
                });
              }}
            >
              <Frame strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Embed
            </Toggle>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="all-domains">
            Allow All Domains
          </Label>
          <p className="text-sm text-muted-foreground">
            By selecting this option, chatbot can be embedded and used on any website, without any domain
            restrictions.
          </p>
          <Switch
            id="all-domains"
            disabled={chatInterface.visibility === 'private'}
            checked={
              chatInterface.visibility !== 'private' ? chatInterface?.all_domains_allowed || false : false
            }
            onCheckedChange={(allowed) => {
              setChatInterface({
                ...chatInterface,
                all_domains_allowed: allowed,
              });
            }}
          />
          {chatInterface.visibility === 'private' && (
            <p className="text-xs text-warning ml-1">
              Your chatbot visibility has to be <span className="font-medium">Public/Embed</span> for this to
              work.
            </p>
          )}
        </div>
        {!chatInterface.all_domains_allowed && (
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="domains">
              Domains
            </Label>
            <p className="text-sm text-muted-foreground">
              In this field, you can input the domains where you want the chatbot to operate. Only those
              domains will be allowed to embed the chatbot.
            </p>
            <Textarea
              disabled={chatInterface.visibility === 'private'}
              id="domains"
              value={domainsTextValue}
              placeholder={window.location.hostname}
              onChange={(e) => {
                const { value } = e.target;
                setDomainsTextValue(value);
              }}
            />
            {chatInterface.visibility === 'private' ? (
              <p className="text-xs text-warning ml-1">
                Your chatbot visibility has to be <span className="font-medium">Public/Embed</span> for this
                to work.
              </p>
            ) : (
              <p className="text-xs text-muted-foreground ml-1">Enter each domain on a separate line</p>
            )}
            {domainsError && <p className="text-xs text-destructive ml-1">Invalid domain(s).</p>}
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="rate-limit">
              Messages Rate Limit
            </Label>
            <p className="text-sm text-muted-foreground">
              Limit the number of messages sent from one device on the iframe and chat bubble (this limit will
              not be applied to your account inside {appTitle}, only on websites for your users to prevent
              abuse).
            </p>
          </div>
          <div id="rate-limit">
            <div className="gap-2 flex items-center flex-wrap text-sm">
              <span className="text-muted-foreground">Limit to</span>
              <Input
                className="w-20 inline-block"
                type="number"
                value={chatInterface.rate_limit[0]}
                min={1}
                max={100}
                onChange={(e) => {
                  const int = parseInt(e.target.value, 10);
                  const curLimit = chatInterface.rate_limit;
                  setChatInterface({
                    ...chatInterface,
                    rate_limit: [int ? (int > 100 ? 100 : int) : 1, curLimit[1]],
                  });
                }}
              />
              <span className="text-muted-foreground">messages every</span>
              <Input
                className="w-20 inline-block"
                type="number"
                value={chatInterface.rate_limit[1]}
                min={1}
                max={360}
                onChange={(e) => {
                  const int = parseInt(e.target.value, 10);
                  const curLimit = chatInterface.rate_limit;
                  setChatInterface({
                    ...chatInterface,
                    rate_limit: [curLimit[0], int ? (int > 360 ? 360 : int) : 1],
                  });
                }}
              />
              <span className="text-muted-foreground">seconds</span>
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="message-limit">
                Limit Reached Warning
              </Label>
              <p className="text-sm text-muted-foreground">
                Chat will show the message once the rate limit has been reached.
              </p>
              <Input
                id="message-limit"
                type="text"
                value={chatInterface.rate_limit_message}
                onChange={(e) => {
                  const { value } = e.target;
                  setChatInterface({
                    ...chatInterface,
                    rate_limit_message: value,
                  });
                }}
              />
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="message-limit">
                Default AI Error Message
              </Label>
              <p className="text-sm text-muted-foreground">
                Write your own error message for cases when the AI doesn&apos;t work. This allows you to
                provide a custom message to inform users about the issue.
              </p>
              <Input
                id="message-limit"
                type="text"
                value={chatInterface?.default_ai_error_message || defaultAIErrorMessage}
                onChange={(e) => {
                  const { value } = e.target;
                  setChatInterface({
                    ...chatInterface,
                    default_ai_error_message: value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="message-credit-limit">
              Message Credits Limit
            </Label>
            <p className="text-sm text-muted-foreground">
              Set a monthly limit on the number of messages your chatbot can process. Once this limit is
              reached, the chatbot will not respond to any further messages until the next month. To allow an
              unlimited number of messages, set the limit to -1.
            </p>
          </div>
          <div id="message-credit-limit">
            <div className="gap-2 flex items-center flex-wrap text-sm">
              <span className="text-muted-foreground">Limit to</span>
              <Input
                className="w-[200px] inline-block"
                type="number"
                value={chatInterface.message_credit_limit || -1}
                min={-1}
                max={999999999999999}
                onChange={(e) => {
                  let int = parseInt(e.target.value || '-1', 10);

                  if (int < -1) {
                    int = -1;
                  } else if (int === 0) {
                    int = chatInterface?.message_credit_limit > 0 ? -1 : 1;
                  }

                  setChatInterface({
                    ...chatInterface,
                    message_credit_limit: int,
                  });
                }}
              />
              <span className="text-muted-foreground">message credit every month</span>
            </div>
            {chatInterface?.message_credit_limit > 0 && (
              <div className="mt-6 flex flex-col gap-2">
                <Label
                  className="text-md font-medium leading-none tracking-tight"
                  htmlFor="credit-message-limit"
                >
                  Message Credits Limit Warning
                </Label>
                <p className="text-sm text-muted-foreground">
                  Chat will show this message once the message credits limit has been reached.
                </p>
                <Input
                  id="credit-message-limit"
                  type="text"
                  value={chatInterface.credit_limit_message || ''}
                  onChange={(e) => {
                    const { value } = e.target;
                    setChatInterface({
                      ...chatInterface,
                      credit_limit_message: value,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default Security;
