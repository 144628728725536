import { useQuery, useQueryClient } from '@tanstack/react-query';
import agentService from 'api/agent';
import { AIAgent, AIAgentList, Chatbot } from 'models/api/response.types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { embeddingStatusCanChange } from 'utils/agent';
import { hasAIAgentAccessByChatbot } from 'utils/routeValidation';

const useAiAgents = () => {
  const queryClient = useQueryClient();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const agentsQueryKey = ['ai-agents', selectedChatbot?.uuid];

  // collaborators with no permissions to AIAgent page
  // don't have the ability to use agents
  const hasPermissionsForAIAgents = useMemo(() => {
    return hasAIAgentAccessByChatbot(selectedChatbot);
  }, [selectedChatbot]);

  const { data: agents } = useQuery({
    queryKey: agentsQueryKey,
    queryFn: () => agentService.getAIAgents(selectedChatbot.uuid),
    enabled: !!selectedChatbot && hasPermissionsForAIAgents,
    refetchInterval: (query) => {
      if (query?.state?.data) {
        if (
          (query?.state?.data as AIAgentList).some((ds) => {
            return (
              ['embedding', 'intents_mapping', 'not_started', 'delayed'].includes(ds.embedding_status) &&
              embeddingStatusCanChange.includes(ds.type) &&
              ds.enabled &&
              ds.active
            );
          })
        ) {
          return 5_000;
        }
        return false;
      }
      return 15_000;
    },
  });

  const refetchAgents = () => {
    queryClient.invalidateQueries({ queryKey: agentsQueryKey });
  };

  const updateAgent = (agent: AIAgent) => {
    const agentList: AIAgentList | undefined = queryClient.getQueryData(agentsQueryKey);
    if (agentList) {
      const newAgents = agentList.map((ag) => (ag.uuid === agent.uuid ? agent : ag));
      queryClient.setQueryData(agentsQueryKey, newAgents);
    } else {
      queryClient.invalidateQueries({ queryKey: agentsQueryKey });
    }
  };

  const removeAgent = (agentId: string) => {
    const agentList: AIAgentList | undefined = queryClient.getQueryData(agentsQueryKey);
    if (agentList) {
      const newAgents = agentList.filter((ag) => ag.uuid !== agentId);
      queryClient.setQueryData(agentsQueryKey, newAgents);
    } else {
      queryClient.invalidateQueries({ queryKey: agentsQueryKey });
    }
  };

  return {
    agents,
    refetchAgents,
    updateAgent,
    removeAgent,
    agentsQueryKey,
  };
};

export default useAiAgents;
