/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { BellRing, CirclePlus, Pencil } from 'lucide-react';
import { cn } from 'utils/cn';
import { Button } from 'components/ui/button';
import { ChatBotMeta, ChatbotVariable } from 'utils/bot';
import { Label } from 'components/ui/label';
import useAiAgents from 'hooks/useAiAgents';
import { Badge } from 'components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Switch } from 'components/ui/switch';
import AddNotificationChatVariablesDialog from 'components/Dialogs/Inbox/AddNotificationChatVariablesDialog';
import { Textarea } from 'components/ui/textarea';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface EmailNotifications extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  emailsTextValue: string;
  setEmailsTextValue: (meta: string) => void;
  setChatInterface: (meta: ChatBotMeta) => void;
  emailsError: boolean;
  variables: ChatbotVariable[];
}

const EmailNotifications: React.FC<EmailNotifications> = ({
  chatInterface,
  setChatInterface,
  emailsTextValue,
  setEmailsTextValue,
  emailsError,
  variables,
}) => {
  const { appEmail, appTitle } = useWhitelabelData();
  const { agents } = useAiAgents();
  const [addChatVariables, setAddChatVariables] = useState<boolean>(false);

  const emailsExist = useMemo(() => {
    return (
      chatInterface?.email_notifications?.emails && chatInterface?.email_notifications?.emails.length > 0
    );
  }, [chatInterface]);

  const variableNames = useMemo(() => {
    if (agents) {
      return variables.map((v) => v.name);
    }
    return [];
  }, [variables]);

  const notificationVariables = useMemo(() => {
    return (chatInterface?.email_notifications?.variables || []).filter((v) => variableNames.includes(v));
  }, [chatInterface, variableNames]);

  return (
    <>
      <AccordionItem
        id="customizations-email-notifications"
        className="border bg-background rounded-md transition-all"
        value="email-notifications"
      >
        <AccordionTrigger className="px-6 text-left hover:no-underline">
          <div>
            <div className="flex items-centrer gap-2">
              <BellRing strokeWidth={1.75} className="w-6 h-6" />
              Email Notifications
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Configure automatic email alerts based on AI chat engagement and user data collection.
            </p>
            {(chatInterface?.email_notifications?.daily_history_enabled ||
              chatInterface?.email_notifications?.daily_variables_enabled ||
              chatInterface?.email_notifications?.daily_variables_enabled) && (
              <div className="text-warning text-xs ml-8">
                Each email notification sent costs 15 Feature Credits.
              </div>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="emails">
              Email
            </Label>
            <p className="text-sm text-muted-foreground">
              {(!emailsExist || emailsError) &&
                `Enter email addresses where you'd like to receive emails from the
                  ${appTitle || 'App'}.`}
              {emailsExist &&
                !emailsError &&
                `Email addresses to which emails from the ${appTitle || 'App'} will be sent.`}
            </p>
            <Textarea
              id="emails"
              value={emailsTextValue}
              placeholder={appEmail}
              onChange={(e) => {
                const { value } = e.target;
                setEmailsTextValue(value);
              }}
            />
            <p className="text-xs text-muted-foreground ml-1">Enter each email on a separate line</p>
            {emailsError && <p className="text-xs text-destructive ml-1">Invalid email.</p>}
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="daily_chat_history">
              Daily Chat History
            </Label>
            <p className="text-sm text-muted-foreground">
              Receive a daily email summary of the day&apos;s chat session history.
            </p>
            <Switch
              id="daily_chat_history"
              checked={chatInterface?.email_notifications?.daily_history_enabled}
              onCheckedChange={(allow) => {
                setChatInterface({
                  ...chatInterface,
                  email_notifications: {
                    ...chatInterface?.email_notifications,
                    daily_history_enabled: allow,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="daily_data_history">
              Daily Collected Leads
            </Label>
            <p className="text-sm text-muted-foreground">
              Receive a daily email summary of all collected variables across all chat sessions.
            </p>
            <Switch
              id="daily_data_history"
              checked={chatInterface?.email_notifications?.daily_variables_enabled}
              onCheckedChange={(allow) => {
                setChatInterface({
                  ...chatInterface,
                  email_notifications: {
                    ...chatInterface?.email_notifications,
                    daily_variables_enabled: allow,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="user_form_submission">
              User Form Submission
            </Label>
            <p className="text-sm text-muted-foreground">Receive an email when the user submits the form.</p>
            <Switch
              id="user_form_submission"
              checked={chatInterface?.email_notifications?.form_submission_enabled}
              onCheckedChange={(allow) => {
                setChatInterface({
                  ...chatInterface,
                  email_notifications: {
                    ...chatInterface?.email_notifications,
                    form_submission_enabled: allow,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="chat_collected_data">
              Chat Collected Variables
            </Label>
            <p className="text-sm text-muted-foreground">
              Receive newly collected leads information (or any other selected variables) via email
              immediately after it has been collected.
            </p>
            <Switch
              id="chat_collected_data"
              checked={chatInterface?.email_notifications?.live_variables_enabled}
              onCheckedChange={(allow) => {
                setChatInterface({
                  ...chatInterface,
                  email_notifications: {
                    ...chatInterface?.email_notifications,
                    live_variables_enabled: allow,
                  },
                });
              }}
            />
          </div>
          {chatInterface?.email_notifications?.live_variables_enabled && (
            <div className="flex flex-col gap-2">
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="live_variable_list">
                Variables
              </Label>
              <p className="text-sm text-muted-foreground">
                Select the specific variables you want to send. Once collected, only the chosen variables will
                be send through the email.
              </p>
              {notificationVariables.length > 0 ? (
                <div className="flex items-center gap-2 flex-wrap">
                  {chatInterface.email_notifications.variables.map((variable) => {
                    const isExists = variableNames.includes(variable);
                    return (
                      <Badge
                        key={`${variable}-variable`}
                        variant={isExists ? 'outline' : 'destructive'}
                        className={cn(
                          'text-sm font-medium px-2 py-1 w-fit rounded-md transition-all whitespace-nowrap',
                          !isExists &&
                            'border-destructive bg-destructive/10 text-black hover:bg-destructive/10',
                        )}
                      >
                        {variable}
                      </Badge>
                    );
                  })}
                  <Button className="w-fit" onClick={() => setAddChatVariables(true)} size="sm">
                    <Pencil strokeWidth={1.75} className="mr-2 w-4 h-4" />
                    Edit
                  </Button>
                </div>
              ) : (
                <>
                  {variableNames.length > 0 ? (
                    <Button onClick={() => setAddChatVariables(true)} size="sm" className="w-fit">
                      <CirclePlus strokeWidth={1.75} className="mr-2 w-4 h-4" />
                      Add variables
                    </Button>
                  ) : (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
                            No existing variables
                          </Badge>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="font-normal">
                            We couldn&apos;t find any existing variables in your agents. Please create one to
                            proceed forward.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </>
              )}
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
      <AddNotificationChatVariablesDialog
        chatbotVariables={variableNames}
        variables={notificationVariables}
        show={addChatVariables}
        close={(vars?: string[]) => {
          setAddChatVariables(false);
          if (vars) {
            setChatInterface({
              ...chatInterface,
              email_notifications: {
                ...chatInterface.email_notifications,
                variables: vars,
              },
            });
          }
        }}
      />
    </>
  );
};

export default EmailNotifications;
