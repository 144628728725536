export const authPaths: { [key: string]: string } = {
  login: '/site/login',
  logout: '/site/logout',
  signup: '/site/signup',
  approveVerification: '/site/approve-verification',
  forgotPassword: '/site/forgot-password',
  redirect: '/site/redirect',
  referral: '/site/referral',
  logInAsAdmin: '/site/partners-log-in-as-admin',
};

type AuthPathsType = typeof authPaths;

const routePaths: {
  [key in
    | keyof AuthPathsType
    | 'default'
    | 'site'
    | 'app_sumo'
    | 'upgrade'
    | 'chatbot'
    | 'dashboard'
    | 'sources'
    | 'inbox'
    | 'preview'
    | 'customizations'
    | 'agents'
    | 'collaborators'
    | 'integrations'
    | 'widget']: string;
} = {
  default: '/',
  site: '/site',
  app_sumo: '/site/app_sumo_connect',
  upgrade: '/site/subscription-upgrade',
  purchase_succeeded: '/site/purchase-succeeded',
  chatbot: '/site/chatbot',
  dashboard: '/site/chatbot/dashboard',
  sources: '/site/chatbot/sources',
  inbox: '/site/chatbot/inbox',
  preview: '/site/chatbot/preview',
  customizations: '/site/chatbot/customizations',
  agents: '/site/chatbot/ai-agents',
  collaborators: '/site/chatbot/collaborators',
  integrations: '/site/chatbot/integrations',
  widget: '/widget/:id',
  ...authPaths,
};

export default routePaths;
