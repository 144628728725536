/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { CircleIcon, ClipboardCopy, Loader2, RotateCw, Trash } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chatbot, ChatbotDomain, ChatbotDomainList } from 'models/api/response.types';
import { cn } from 'utils/cn';
import domainService from 'api/domain';
import { useQueryClient } from '@tanstack/react-query';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useAlerts } from 'providers/AlertProvider';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import chatbotService from 'api/chatbot';
import { isDev, isPrimaryProduct } from 'utils/domain';
import { alerts } from 'utils/alert';

const Domain: React.FC<{
  domain: ChatbotDomain;
  deleteDomain: () => void;
}> = ({ domain, deleteDomain }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [metaTags, setMetaTags] = useState<{ [key: string]: string }>({});
  const [updating, setUpdating] = useState<boolean>(false);
  const isVerificationPending = domain?.verification_status === 'pending';
  const isVerificationFailed = domain?.verification_status === 'failed';
  const isVerified = !isVerificationFailed && !isVerificationPending;
  const isConfigurationPending = domain?.configuration_status === 'not_configured';
  const isConfigured = domain.configuration_status === 'configured';
  // this one can't be transfered to whielabel domain, it has to be our own
  const tableValues = isVerified
    ? ['CNAME', domain.domain.split('.')[0], isDev ? 'xin.gpt-trainer.com' : window.location.hostname]
    : [
        'TXT',
        '@',
        `${isPrimaryProduct ? 'gpt-trainer-verification' : 'chatbot-verification'}=${domain.verification_code}`,
      ];
  const finalDomainPath = `https://${domain.domain}/widget/${chatbot.uuid}`;
  const domainsQueryKey = ['chatbot-domains', chatbot?.uuid];

  const share = (index: number) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(tableValues[index]);
      addAlert({
        severity: 'success',
        message: alerts.VALUE_COPIED,
      });
    }
  };

  useEffect(() => {
    const meta = JSON.parse(domain?.meta_json || '{}');
    setMetaTags(meta);
  }, [domain]);

  const canUpdate = useMemo(() => {
    const meta = domain?.meta_json || '{}';
    return meta !== JSON.stringify(metaTags);
  }, [metaTags]);

  const refreshDomain = (restart?: boolean) => {
    if (restart) {
      setRefreshing(true);
    }
    domainService
      .refreshChatbotDomain(domain.uuid)
      .then((data) => {
        const domains: ChatbotDomainList | undefined = queryClient.getQueryData(domainsQueryKey);
        // overwrite data
        const newDomains = domains?.map((dom) => dom);
        const domIdx = newDomains?.findIndex((dom) => dom.uuid === domain.uuid);
        if (domIdx !== undefined && domIdx > -1 && newDomains) {
          newDomains[domIdx] = data;
          queryClient.setQueryData(domainsQueryKey, newDomains);
        } else {
          queryClient.invalidateQueries({ queryKey: domainsQueryKey });
        }
        setRefreshing(false);
      })
      .catch(() => {
        setRefreshing(false);
      });
  };

  const update = () => {
    setUpdating(true);
    chatbotService
      .updateChatbotDomain(domain.uuid, JSON.stringify(metaTags))
      .then(() => {
        setUpdating(false);
        refreshDomain();
        addAlert({
          severity: 'success',
          message: alerts.DOMAIN_META_TAGS(domain.domain),
        });
      })
      .catch(() => {
        setUpdating(false);
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      });
  };

  return (
    <Card className="w-full overflow-hidden">
      <CardHeader className="grid grid-cols-[1fr_auto] items-start gap-4 space-y-0 p-4">
        <div className="space-y-1 w-full overflow-hidden">
          <CardTitle className="text-md flex items-center gap-2">
            {domain.domain}
            <CircleIcon
              strokeWidth={1.75}
              className={cn(
                'h-3 w-3',
                isVerificationPending || (isConfigurationPending && isVerified)
                  ? 'fill-blue-500 text-blue-500 animate-pulse'
                  : '',
                isVerificationFailed ? 'fill-destructive text-destructive' : '',
                isConfigured ? 'fill-success text-success' : '',
              )}
            />
          </CardTitle>
          {!isVerified && <p className="text-sm text-warning">Verify the domain ownership</p>}
          {isVerified && !isConfigured && (
            <p className="text-sm font-medium text-warning">Configure your domain</p>
          )}
          <CardDescription className="w-full overflow-hidden text-wrap text-ellipsis">
            {isVerificationPending &&
              `To verify ownership of domain, navigate to your DNS provider and add a TXT record with this values. 
                Then, refresh the record to complete the verification.`}
            {isVerificationFailed && (
              <span>
                Your domain could not be verifed. Make sure you&apos;ve added the TXT record below to your
                primary domain. For example, you should add it to{' '}
                <strong className="text-secondary">my-custom-site.com</strong>, not{' '}
                <strong className="text-secondary">chat.my-custom-site.com</strong>.{' '}
                <strong>DNS records may take up to 30 minutes to propagate.</strong>
              </span>
            )}
            {isConfigurationPending &&
              isVerified &&
              `Set the following record on your DNS provider to continue. Then, refresh the record to complete the configuration.`}
            {isConfigured && (
              <>
                Your domain has been successfully configured and is now ready to use with the chatbot. Here is
                the link to your chatbot:{' '}
                <a
                  className="text-secondary hover:underline"
                  href={finalDomainPath}
                  rel="noreferrer"
                  target="_blank"
                >
                  {finalDomainPath}
                </a>
                .
              </>
            )}
          </CardDescription>
        </div>
        <div className="flex items-center gap-2">
          <button type="button" disabled={refreshing} onClick={() => refreshDomain(true)}>
            <RotateCw
              strokeWidth={1.75}
              className={cn('w-4 min-w-[16px] h-4 cursor-pointer', refreshing ? 'animate-spin' : '')}
            />
          </button>
          <button type="button" onClick={deleteDomain}>
            <Trash strokeWidth={1.75} className="w-4 min-w-[16px] h-4 text-destructive cursor-pointer" />
          </button>
        </div>
      </CardHeader>
      {!isConfigured ? (
        <CardContent className="p-4 pt-0 overflow-hidden">
          <div className="rounded-md border bg-background">
            <Table className="table-fixed">
              <TableHeader>
                <TableRow className="hover:bg-transparent">
                  <TableHead className="w-[200px] border-r">Type</TableHead>
                  <TableHead className="w-[200px] border-r">Name</TableHead>
                  <TableHead className="w-[510px]">Value</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="hover:bg-transparent">
                  <TableCell className="border-r">
                    <span className="flex items-center justify-between">
                      <p>{tableValues[0]}</p>
                      <Button
                        variant="outline"
                        className="h-8 w-8 p-0 group"
                        onClick={() => {
                          share(0);
                        }}
                      >
                        <ClipboardCopy className="w-4 h-4 text-muted-foreground group-hover:text-primary" />
                      </Button>
                    </span>
                  </TableCell>
                  <TableCell className="border-r">
                    <span className="flex items-center justify-between">
                      <p>{tableValues[1]}</p>
                      <Button
                        variant="outline"
                        className="h-8 w-8 p-0 group"
                        onClick={() => {
                          share(1);
                        }}
                      >
                        <ClipboardCopy className="w-4 h-4 text-muted-foreground group-hover:text-primary" />
                      </Button>
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="flex items-center justify-between">
                      <p>{tableValues[2]}</p>
                      <Button
                        variant="outline"
                        className="h-8 w-8 p-0 group"
                        onClick={() => {
                          share(2);
                        }}
                      >
                        <ClipboardCopy className="w-4 h-4 text-muted-foreground group-hover:text-primary" />
                      </Button>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
      ) : (
        <CardContent className="p-4 pt-0 overflow-hidden flex flex-col gap-4">
          <div>
            <h3 className="text-md font-semibold leading-none tracking-tight flex items-center">Meta tags</h3>
            <p className="text-sm text-muted-foreground mt-[0.25rem]">
              Meta tags are HTML tags that provide a brief summary of your chatbot, used by search engines and
              browsers to understand and display content, but are not visible on the page itself. You can
              specify own tags for you chatbot.
            </p>
          </div>
          <div className="grid w-full items-center gap-2">
            <div>
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="domain-title">
                Title
              </Label>
              <p className="text-sm text-muted-foreground mt-[0.25rem]">
                Webpage&apos;s title, such as a company or app name, essential for user recognition and search
                engine indexing.
              </p>
            </div>
            <Input
              type="text"
              disabled={updating}
              id="domain-title"
              autoComplete="off"
              value={metaTags?.title || ''}
              onChange={(e) => {
                setMetaTags({
                  ...metaTags,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="grid w-full items-center gap-2">
            <div>
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="domain-description">
                Description
              </Label>
              <p className="text-sm text-muted-foreground mt-[0.25rem]">
                Brief summary of webpage or chatbot content.
              </p>
            </div>
            <Input
              type="text"
              disabled={updating}
              id="domain-description"
              autoComplete="off"
              value={metaTags?.description || ''}
              onChange={(e) => {
                setMetaTags({
                  ...metaTags,
                  description: e.target.value,
                });
              }}
            />
          </div>
          <div className="grid w-full items-center gap-2">
            <div>
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="domain-url">
                URL
              </Label>
              <p className="text-sm text-muted-foreground mt-[0.25rem]">
                For specifying the webpage&apos;s address.
              </p>
            </div>
            <Input
              type="text"
              disabled={updating}
              id="domain-url"
              autoComplete="off"
              value={metaTags?.url || ''}
              onChange={(e) => {
                setMetaTags({
                  ...metaTags,
                  url: e.target.value,
                });
              }}
            />
          </div>
          <Button className="ml-auto" disabled={!canUpdate || updating} onClick={update}>
            {updating && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
            Update
          </Button>
        </CardContent>
      )}
    </Card>
  );
};

export default Domain;
