/* eslint-disable react/jsx-props-no-spreading */
import { AIAgentFunctionList, AgentRule, AgentRuleList, Chatbot } from 'models/api/response.types';
import React, { useEffect, useMemo, useState } from 'react';
import { RuleGroupTypeIC, formatQuery } from 'react-querybuilder';
import { Button } from 'components/ui/button';
import { Cable, GripVertical, Loader2, MoreHorizontal, Pencil, Trash2, Unplug } from 'lucide-react';
import { cn } from 'utils/cn';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import agentService from 'api/agent';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { validateRules } from 'utils/agent';
import StatusDot from 'components/helpers/StatusDot';
import { ChatbotVariable } from 'utils/bot';
import RuleBuilder from './RuleBuilder';

const formatQueryResult = (query: RuleGroupTypeIC) => {
  return JSON.parse(formatQuery(query, 'json_without_ids'));
};

const Rule: React.FC<{
  canRender: boolean;
  isValid: boolean;
  rule: AgentRule;
  editModeEnabled?: string;
  agents: { name: string; uuid: string }[];
  functions: AIAgentFunctionList;
  variables: ChatbotVariable[];
  ruleValidation: (id: string, valid: boolean) => void;
  editRule: () => void;
  deleteRule: () => void;
  stopEditing: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}> = ({
  canRender,
  isValid,
  editModeEnabled,
  rule,
  agents,
  functions,
  variables,
  deleteRule,
  editRule,
  stopEditing,
  ruleValidation,
  dragHandleProps,
}) => {
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryClient = useQueryClient();
  const [newQuery, setNewQuery] = useState<RuleGroupTypeIC>(rule.rule);
  const [saving, setSaving] = useState<boolean>(false);
  const rulesQueryKey = ['agent-rules', selectedChatbot.uuid];

  const canSave = useMemo(() => {
    return JSON.stringify(formatQueryResult(rule.rule)) !== JSON.stringify(formatQueryResult(newQuery));
  }, [newQuery, rule.rule]);

  const isEdit = useMemo(() => {
    return editModeEnabled === rule.uuid;
  }, [editModeEnabled]);

  const isValidRule = useMemo(() => {
    return validateRules(newQuery, agents, functions, variables);
  }, [newQuery, isEdit, agents]);

  useEffect(() => {
    if (!isEdit) {
      if (isValidRule && !isValid) {
        ruleValidation(rule.uuid, true);
      }
      if (!isValidRule && isValid) {
        ruleValidation(rule.uuid, false);
      }
    }
  }, [isValidRule, isValid, isEdit]);

  // handling scenario when supervisor settings were closed
  useEffect(() => {
    if (!isEdit && canSave) {
      setNewQuery(rule.rule);
    }
  }, [isEdit]);

  const { isActive, isInvalid } = useMemo(() => {
    return {
      isActive: !!rule.enabled && isValidRule,
      isInvalid: !isValidRule,
    };
  }, [rule, isValidRule]);

  const cancelEditing = () => {
    stopEditing();
    setNewQuery(rule.rule);
  };

  const updateRulesWithResponse = (res: AgentRule) => {
    const rules: AgentRuleList | undefined = queryClient.getQueryData(rulesQueryKey);
    if (rules) {
      const newRules = rules.map((r) => (r.uuid === rule.uuid ? res : r));
      queryClient.setQueryData(rulesQueryKey, newRules);
    } else {
      queryClient.invalidateQueries({ queryKey: rulesQueryKey });
    }
  };

  const triggerRuleEnabling = () => {
    agentService.updateAgentRule(rule.uuid, { enabled: !isActive ? 1 : 0 }).then((res) => {
      updateRulesWithResponse(res);
    });
  };

  const saveQuery = () => {
    setSaving(true);
    const queryToSave = formatQueryResult(newQuery);
    agentService
      .updateAgentRule(rule.uuid, { rule: queryToSave })
      .then((res) => {
        updateRulesWithResponse(res);
        setNewQuery(res.rule);
        setSaving(false);
        stopEditing();
      })
      .catch(() => setSaving(false));
  };

  return (
    <div
      className={cn(
        'border overflow-x-auto rounded-md relative transition-all bg-background',
        editModeEnabled && !isEdit ? 'opacity-50' : '',
      )}
    >
      <div
        className={cn(
          'w-full h-full absolute bottom-0 left-0 z-[5] cursor-default',
          !isEdit ? 'block' : 'hidden',
        )}
      />
      <div
        className={cn(
          'h-[34px] absolute bg-secondary-background right-0 top-[7px] flex items-center px-2',
          isEdit ? 'ml-[30%]' : 'w-full',
          !editModeEnabled ? 'z-[6]' : '',
        )}
      >
        {!isEdit && (
          <>
            <button type="button" {...dragHandleProps}>
              <GripVertical
                strokeWidth={1.75}
                className="h-5 w-5 text-muted-foreground hover:text-black transition-all mr-4"
              />
            </button>
            <div className="bg-background px-2 h-[32px] rounded-md border font-medium flex items-center text-xs shadow-sm">
              <StatusDot
                className="h-4 w-4 mr-2"
                status={isInvalid ? 'error' : isActive ? 'success' : 'offline'}
              />
              {isInvalid ? 'Invalid' : isActive ? 'Active' : 'Disabled'}
            </div>
          </>
        )}
        <div className="ml-auto flex items-center gap-4">
          {!isEdit ? (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    disabled={!!editModeEnabled}
                    size="icon"
                    variant="outline"
                    className="px-2 py-0 shadow-none ml-auto h-8 bg-background"
                  >
                    <MoreHorizontal strokeWidth={1.75} className="h-4 w-4 text-secondary-foreground" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" forceMount>
                  <DropdownMenuItem onClick={editRule}>
                    <Pencil strokeWidth={1.75} className="mr-2 h-4 w-4" />
                    Edit
                  </DropdownMenuItem>
                  {!isInvalid && (
                    <>
                      {isActive ? (
                        <DropdownMenuItem onClick={triggerRuleEnabling}>
                          <Unplug strokeWidth={1.75} className="mr-2 h-4 w-4" /> Disable
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem onClick={triggerRuleEnabling}>
                          <Cable strokeWidth={1.75} className="mr-2 h-4 w-4" /> Connect
                        </DropdownMenuItem>
                      )}
                    </>
                  )}
                  <DropdownMenuItem
                    onClick={deleteRule}
                    className="focus:text-destructive focus:bg-destructive/10"
                  >
                    <Trash2 strokeWidth={1.75} className="mr-2 h-4 w-4" /> Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          ) : (
            <>
              {canSave && (
                <Button onClick={saveQuery} disabled={!isValidRule || saving} size="sm">
                  {saving && (
                    <Loader2 strokeWidth={1.75} className="h-4 w-4 mr-2 animate-spin transition-all" />
                  )}
                  Save
                </Button>
              )}
              <Button className="bg-background" onClick={cancelEditing} variant="outline" size="sm">
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
      {/* no need to render such complex queries every time
      await only when supervisor settings will be opened */}
      {canRender && (
        <RuleBuilder
          disabled={!isEdit}
          agents={agents}
          functions={functions}
          variables={variables}
          query={newQuery}
          setQuery={setNewQuery}
        />
      )}
    </div>
  );
};

export default Rule;
