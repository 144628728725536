/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import { ChatBotMeta } from 'utils/bot';
import { ArrowLeft, ArrowRight, Power, Image as IconImage, Loader2, UploadIcon } from 'lucide-react';
import { Label } from 'components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Toggle } from 'components/ui/toggle';
import widgetImagePaths from 'utils/widgetImagePaths';
import WidgetButtonColorAppearance from 'components/helpers/ChatbotCustomizations/WidgetButtonColorAppearance';
import useChatbots from 'hooks/useChatbots';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot, FileResponseData } from 'models/api/response.types';
import contentService from 'api/content';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Switch } from 'components/ui/switch';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import ImageCropperDialog from 'components/Dialogs/ImageCropperDialog';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface WidgetButtonAppearanceProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const WidgetButtonAppearance: React.FC<WidgetButtonAppearanceProps> = ({
  chatInterface,
  setChatInterface,
}) => {
  const { addAlert } = useAlerts();
  const { updateChatbotByUUID } = useChatbots();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const buttonLogoRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageToUpload, setImageToUpload] = useState<File | undefined>(undefined);

  const updateButtonLogo = (file: File) => {
    setLoading(true);
    contentService.uploadChatbotFile(chatbot.uuid, file, 'chatbot_button', (response?: FileResponseData) => {
      if (response) {
        const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
        updateChatbotByUUID(chatbot.uuid, {
          ...chatbot,
          meta_json: JSON.stringify({
            ...meta,
            button: { ...meta?.button, logo: response.url },
          }),
          // need for img rerendering
          modified_at: new Date().toISOString(),
        });
        setChatInterface({
          ...chatInterface,
          button: {
            ...chatInterface?.button,
            logo: response.url,
          },
        });
      }
      setLoading(false);
    });
  };

  const deleteButtonLogo = () => {
    contentService.deleteChatbotFile(chatbot.uuid, 'chatbot_button').then(() => {
      const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
      updateChatbotByUUID(chatbot.uuid, {
        ...chatbot,
        meta_json: JSON.stringify({ ...meta, button: { ...meta?.button, logo: '' } }),
      });
      setChatInterface({
        ...chatInterface,
        button: {
          ...chatInterface?.button,
          logo: '',
        },
      });
    });
  };

  return (
    <>
      <AccordionItem
        id="customizations-widget-button"
        className="border bg-background rounded-md transition-all relative"
        value="widget-button"
      >
        <AccordionTrigger className="px-6 text-left hover:no-underline">
          <div>
            <div className="flex items-centrer gap-2">
              <Power strokeWidth={1.75} className="w-6 h-6" />
              Button
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Chat widget button position, color, and logo.
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
          <WidgetButtonColorAppearance
            isPlatformView
            chatInterface={chatInterface}
            setChatInterface={setChatInterface}
          />
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="chatbot-message-color"
            >
              Icon
            </Label>
            <Popover>
              <PopoverTrigger asChild>
                <div
                  id="button-picture-container"
                  className="w-[50px] h-[50px] rounded-full border cursor-pointer hover:scale-[1.05] transition-all flex items-center justify-center"
                >
                  <img className="w-7 h-7" src={chatInterface?.button?.icon} alt="button-icon" />
                </div>
              </PopoverTrigger>
              <PopoverContent className="flex justify-center items-center flex-wrap p-1 w-[17rem]">
                {widgetImagePaths.map((src, index) => (
                  <Toggle key={index} pressed={chatInterface?.button?.icon === src}>
                    <img
                      src={src}
                      className="w-6 h-6 cursor-pointer group-hover:fill-blue-500 transition-all"
                      alt={`logo-${index + 1}`}
                      onClick={() => {
                        setChatInterface({
                          ...chatInterface,
                          button: {
                            ...chatInterface?.button,
                            icon: src,
                          },
                        });
                      }}
                    />
                  </Toggle>
                ))}
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="use-button-logo"
            >
              Use Logo on Button
            </Label>
            <p className="text-sm text-muted-foreground">
              Enable this option to display logo on the button instead of the default icon, if a logo is
              available.
            </p>
            <Switch
              id="use-button-logo"
              checked={chatInterface?.button?.use_logo || false}
              onCheckedChange={(allowed) => {
                setChatInterface({
                  ...chatInterface,
                  button: {
                    ...chatInterface.button,
                    use_logo: allowed,
                  },
                });
              }}
            />
          </div>
          {chatInterface?.button?.use_logo && (
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center"
                htmlFor="watermark-logo"
              >
                Logo
              </Label>
              <div className="flex items-center gap-2">
                <div
                  id="watermark-logo-container"
                  className="w-[50px] bg-background h-[50px] relative rounded-full border-[1px] flex items-center justify-center"
                >
                  {!chatInterface?.button?.logo ? (
                    <IconImage strokeWidth={1.75} className="w-6 h-6" />
                  ) : (
                    <img
                      className="w-[50px] h-[50px] rounded-full"
                      src={`${chatInterface.button.logo}?v=${chatbot.modified_at}`}
                      alt="Chatbot Button Logo"
                    />
                  )}
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <Button
                      disabled={loading}
                      size="sm"
                      variant="outline"
                      className="bg-background"
                      onClick={() => {
                        if (!loading) {
                          buttonLogoRef?.current?.click();
                        }
                      }}
                    >
                      {loading ? (
                        <Loader2 className="mr-2 w-4 h-4 animate-spin transition-all" />
                      ) : (
                        <UploadIcon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                      )}
                      Upload
                    </Button>
                    <Button
                      disabled={!chatInterface?.button?.logo}
                      size="sm"
                      variant="ghost"
                      onClick={deleteButtonLogo}
                    >
                      Remove
                    </Button>
                  </div>
                  <p className="text-muted-foreground text-xs mt-1">
                    Supports JPG, PNG, and SVG files up to 1MB
                  </p>
                </div>
              </div>
              <Input
                className="hidden"
                accept="image/png, image/jpg, image/svg+xml"
                ref={buttonLogoRef}
                id="profile-picture"
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    if (file.size <= 1000000) {
                      setImageToUpload(file);
                      e.target.value = '';
                    } else {
                      addAlert({
                        severity: 'error',
                        message: alerts.IMAGE_SIZE_EXCEEDED,
                      });
                    }
                  }
                }}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="widget-alignment"
            >
              Horizontal Alignment
            </Label>
            <p className="text-sm text-muted-foreground">
              Choose the Horizontal Alignment to position your chat on either the left or right side of the
              screen.
            </p>
            <div id="widget-alignment" className="flex items-center gap-2">
              <Toggle
                aria-label="left"
                pressed={chatInterface.widget_vertical_align === 'left'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    widget_vertical_align: 'left',
                  });
                }}
              >
                <ArrowLeft strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Left
              </Toggle>
              <Toggle
                aria-label="right"
                pressed={
                  !chatInterface.widget_vertical_align || chatInterface.widget_vertical_align === 'right'
                }
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    widget_vertical_align: 'right',
                  });
                }}
              >
                <ArrowRight strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Right
              </Toggle>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
      <ImageCropperDialog
        title="Button Logo"
        img={imageToUpload}
        cropperCallback={(croppedImage?: File) => {
          if (croppedImage) {
            updateButtonLogo(croppedImage);
          }
          setImageToUpload(undefined);
        }}
      />
    </>
  );
};

export default WidgetButtonAppearance;
