/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-new */
import React, { useEffect, useMemo, useState } from 'react';
import { Database, Palette, Settings } from 'lucide-react';
import { Chatbot, Chatbots } from 'models/api/response.types';
import { useDispatch, useSelector } from 'react-redux';
import chatbotService from 'api/chatbot';
import {
  getHasUnsavedChanges,
  getSelectedChatbot,
  setHasUnsavedChanges,
  setSelectedChatbot,
} from 'store/reducers/ui';
import SaveChangesHeader from 'components/Chatbot/Customizations/SaveChangesHeader';
import { useLocation } from 'react-router-dom';
import { ChatBotMeta, ChatbotVariable } from 'utils/bot';
import Security from 'components/Chatbot/Customizations/Security';
import SettingsConversations from 'components/Chatbot/Customizations/SettingsConversations';
import CustomDomains from 'components/Chatbot/Customizations/CustomDomains/CustomDomains';
import Authentication from 'components/Chatbot/Customizations/Authentication';
import GeneralAppearance from 'components/Chatbot/Customizations/GeneralAppearance';
import MessagesAppearance from 'components/Chatbot/Customizations/MessagesAppearance';
import WidgetButtonAppearance from 'components/Chatbot/Customizations/WidgetButtonAppearance';
import SuggestedPromptsAppearance from 'components/Chatbot/Customizations/SuggestedPromptsAppearance';
import WatermarkAppearance from 'components/Chatbot/Customizations/WatermarkAppearance';
import Webhooks from 'components/Chatbot/Customizations/Webhooks';
import { useAlerts } from 'providers/AlertProvider';
import useChatbots from 'hooks/useChatbots';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { alerts } from 'utils/alert';
import EmailNotifications from 'components/Chatbot/Customizations/EmailNotifications';
import { isPrimaryProduct } from 'utils/domain';
import ChatAppearancePreview from 'components/helpers/ChatAppearancePreview';
import Disclaimer from 'components/Chatbot/Customizations/Disclaimer';
import FormSettings from 'components/Chatbot/Customizations/FormSettings';
import { Accordion } from 'components/ui/accordion';
import LabelsSettings from 'components/Chatbot/Customizations/LabelsSettings';
import VariablesSettings from 'components/Chatbot/Customizations/VariablesSettings';
import HumanEscalationSettings from 'components/Chatbot/Customizations/HumanEscalationSettings';

type Section =
  | 'security'
  | 'conversations'
  | 'custom-domains'
  | 'authentication'
  | 'general'
  | 'messages'
  | 'button'
  | 'suggested-prompts'
  | 'watermark'
  | 'webhooks'
  | 'email-notifications'
  | 'disclaimer'
  | 'form'
  | 'variables'
  | 'labels'
  | 'human-escalation';

const Customizations: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const {
    canUseWebhooks,
    canUseCustomWatermark,
    canUseUserIdentity,
    canUseCustomDomains,
    chatbotLabelLimit,
    chatbotVariableLimit,
    canEnableHumanEscalation,
  } = useSubscriptionInfo();
  const { addAlert } = useAlerts();
  const { chatbots, setChatbotData } = useChatbots();
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const urlSearchParams = new URLSearchParams(search);
  const providedChatbot = urlSearchParams.get('bot');
  const metadata = JSON.parse(chatbot.meta_json) as ChatBotMeta;
  const variables = JSON.parse(chatbot.variables_json) as ChatbotVariable[];
  const [domainsTextValue, setDomainsTextValue] = useState<string>(metadata.domains.join('\n'));
  const [emailsTextValue, setEmailsTextValue] = useState<string>(
    (metadata?.email_notifications?.emails || []).join('\n'),
  );
  const [validDomains, setValidDomains] = useState<string[]>(metadata.domains);
  const [validNotificationEmails, setValidNotificationEmails] = useState<string[]>(
    metadata?.email_notifications?.emails || [],
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [chatInterface, setChatInterface] = useState<ChatBotMeta>(metadata);
  const [variablesInterface, setVariablesInterface] = useState<ChatbotVariable[]>(variables);
  const [initialMessages, setInitialMessages] = useState<string>(chatInterface.initial_messages.join('\n\n'));
  const [initialRender, setInitialRender] = useState<boolean>(true);

  useEffect(() => {
    if (providedChatbot && chatbots) {
      const selectedChatbot = chatbots.find((c: Chatbot) => c.uuid === providedChatbot);
      if (selectedChatbot) {
        dispatch(setSelectedChatbot(selectedChatbot));
      }
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [chatbots]);

  const setInitialData = () => {
    if (chatbot) {
      const chatbotMeta = JSON.parse(chatbot.meta_json) as ChatBotMeta;
      const chatbotVariables = JSON.parse(chatbot.variables_json) as ChatbotVariable[];
      setValidDomains(chatbotMeta.domains);
      setDomainsTextValue(chatbotMeta.domains.join('\n'));
      setEmailsTextValue((chatbotMeta?.email_notifications?.emails || []).join('\n'));
      setInitialMessages(chatbotMeta.initial_messages.join('\n\n'));
      setValidNotificationEmails(metadata?.email_notifications?.emails || []);
      setChatInterface(chatbotMeta);
      setVariablesInterface(chatbotVariables);
    }
  };

  // set data on initial render
  // meta of chatbot can be changed, so avoid of losing "Edited data"
  // make sure this one called only when you are swithcing chatbots
  // or first entering this route
  useEffect(() => {
    if (!initialRender) {
      setInitialData();
    } else {
      setInitialRender(false);
    }
  }, [chatbot.uuid]);

  // track unsaved changes
  // need timeout because some settings like LABELS do not need this
  useEffect(() => {
    // Clear the existing timeout
    const timeoutId = setTimeout(() => {
      if (
        JSON.stringify(metadata) !==
          JSON.stringify({
            ...chatInterface,
            domains: validDomains,
            email_notifications: {
              ...chatInterface.email_notifications,
              emails: validNotificationEmails,
            },
          }) ||
        JSON.stringify(variables) !== JSON.stringify(variablesInterface)
      ) {
        dispatch(setHasUnsavedChanges(true));
      } else if (hasUnsavedChanges) {
        dispatch(setHasUnsavedChanges(false));
      }
    }, 100);

    // Cleanup function to clear timeout if the component unmounts or dependencies change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    chatInterface,
    validDomains,
    validNotificationEmails,
    metadata,
    variables,
    hasUnsavedChanges,
    variablesInterface,
  ]);

  const isValidWebsiteUrl = (websiteUrl: string) => {
    try {
      new URL(websiteUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const extractDomain = (url: string) => {
    const parsedUrl = new URL(url);
    const domain = parsedUrl.hostname.replace('www.', '');
    return domain;
  };

  // logic to check if all urls are valid
  // and extract all lines to valid hostnames
  const isDomainsError = useMemo(() => {
    let allValidDomains = true;
    const domainLines = domainsTextValue.split('\n'); // Split the text into lines
    // Remove any empty lines
    const domains = domainLines.filter((line) => line.trim() !== '');
    const domainPattern = /^([A-Za-z0-9](?:(?:[A-Za-z0-9-]){0,61}[A-Za-z0-9])?\.)+[A-Za-z]{2,}$/i;
    const valDom: string[] = [];
    domains.forEach((dom) => {
      if (isValidWebsiteUrl(dom)) {
        valDom.push(extractDomain(dom));
      } else if (domainPattern.test(dom)) {
        valDom.push(dom.replace('www.', ''));
      } else {
        allValidDomains = false;
      }
    });

    setValidDomains(valDom);
    return !allValidDomains;
  }, [domainsTextValue]);

  // check for all valid emails in email notification list
  const isNotificationEmailsError = useMemo(() => {
    let allValidEmails = true;
    const emailLines = emailsTextValue.split('\n'); // Split the text into lines
    // Remove any empty lines
    const emails = emailLines.filter((line) => line.trim() !== '');
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valEmails: string[] = [];
    emails.forEach((em) => {
      if (emailPattern.test(em)) {
        valEmails.push(em);
      } else {
        allValidEmails = false;
      }
    });

    setValidNotificationEmails(valEmails);
    return !allValidEmails;
  }, [emailsTextValue]);

  // check if users form is valid
  const isFormError = useMemo(() => {
    if (chatInterface?.form?.fields) {
      const fieldNames = new Set();
      return chatInterface?.form?.fields.some((field) => {
        if (fieldNames.has(field.label)) {
          return true;
        }

        fieldNames.add(field.label);

        if (
          field.label.length === 0 ||
          (field.type === 'dropdown' && (!field.dropdown_options || field.dropdown_options.length === 0))
        ) {
          return true;
        }
        return false;
      });
    }
    return false;
  }, [chatInterface?.form]);

  // identity verification check
  const isAuthenticationUrlError = useMemo(() => {
    const urlRegex = /^https?:\/\/[\w.-]+(?:\:[0-9]+)?\/[^\s]+$/;
    return (
      chatInterface?.webhook_auth?.enabled &&
      ((chatInterface?.webhook_auth?.url && !urlRegex.test(chatInterface?.webhook_auth.url)) ||
        !chatInterface?.webhook_auth?.url)
    );
  }, [chatInterface?.webhook_auth]);

  const success = () => {
    addAlert({
      severity: 'success',
      message: alerts.CHANGES_SAVED,
    });
  };

  const error = (message: string) => {
    addAlert({
      severity: 'error',
      message,
    });
  };

  const saveChanges = () => {
    if (!isDomainsError && !isAuthenticationUrlError && !isNotificationEmailsError && !isFormError) {
      setSaving(true);
      const newInterface = JSON.stringify({
        ...chatInterface,
        domains: validDomains,
        email_notifications: {
          ...chatInterface.email_notifications,
          emails: validNotificationEmails,
        },
      });
      const newVariables = JSON.stringify(variablesInterface);
      if (newInterface !== chatbot.meta_json || newVariables !== chatbot.variables_json) {
        chatbotService
          .updateChatbot(chatbot.uuid, {
            meta_json: newInterface,
            variables_json: newVariables,
          })
          .then((data: Chatbot) => {
            const newChatbots = [...(chatbots as Chatbots)].map((bot) =>
              bot.uuid === data.uuid ? data : bot,
            );
            setChatbotData(newChatbots);
            dispatch(setSelectedChatbot(data));
            success();
            setSaving(false);
          })
          .catch(() => {
            error(alerts.PAGE_REFRESH_ERROR);
            setSaving(false);
          });
      } else {
        success();
        setSaving(false);
      }
    } else {
      error(alerts.FIX_ISSUES);
      setSaving(false);
    }
  };

  const settingsMenuItems = [
    {
      sectionId: 'security' as Section,
      disabled: false,
      block: (index: number) => (
        <Security
          key={index}
          chatInterface={chatInterface}
          canUseUserAuthentication={canUseUserIdentity}
          setChatInterface={setChatInterface}
          domainsTextValue={domainsTextValue}
          setDomainsTextValue={setDomainsTextValue}
          domainsError={isDomainsError}
        />
      ),
    },
    {
      disabled: false,
      sectionId: 'conversations' as Section,
      block: (index: number) => (
        <SettingsConversations
          key={index}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      disabled: !canUseCustomDomains,
      sectionId: 'custom-domains' as Section,
      block: (index: number) => <CustomDomains key={index} />,
    },
    {
      sectionId: 'disclaimer' as Section,
      disabled: false,
      block: (index: number) => (
        <Disclaimer
          key={index}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          setShowDisclaimer={setShowDisclaimer}
        />
      ),
    },
    {
      sectionId: 'human-escalation' as Section,
      disabled: !canEnableHumanEscalation,
      block: (index: number) => <HumanEscalationSettings key={index} disabled={!canEnableHumanEscalation} />,
    },
  ].sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1;
    }
    if (!a.disabled && b.disabled) {
      return -1;
    }
    return 0;
  });

  const dataMenuItems = [
    {
      disabled: !chatbotLabelLimit,
      sectionId: 'labels' as Section,
      block: (index: number) => (
        <LabelsSettings
          setChatInterface={setChatInterface}
          chatInterface={chatInterface}
          disabled={!chatbotLabelLimit}
          key={index}
        />
      ),
    },
    {
      disabled: !chatbotVariableLimit,
      sectionId: 'variables' as Section,
      block: (index: number) => (
        <VariablesSettings
          variables={variablesInterface}
          setVariables={setVariablesInterface}
          disabled={!chatbotVariableLimit}
          key={index}
        />
      ),
    },
    {
      disabled: false,
      sectionId: 'form' as Section,
      block: (index: number) => (
        <FormSettings
          key={index}
          setShowForm={setShowForm}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      disabled: !canUseWebhooks,
      sectionId: 'webhooks' as Section,
      block: (index: number) => (
        <Webhooks
          key={index}
          variables={variablesInterface}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          disabled={!canUseWebhooks}
        />
      ),
    },
    {
      disabled: false,
      sectionId: 'email-notifications' as Section,
      block: (index: number) => (
        <EmailNotifications
          key={index}
          variables={variablesInterface}
          emailsError={isNotificationEmailsError}
          emailsTextValue={emailsTextValue}
          setEmailsTextValue={setEmailsTextValue}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      disabled: !canUseUserIdentity,
      sectionId: 'authentication' as Section,
      block: (index: number) => (
        <Authentication
          key={index}
          isUrlError={isAuthenticationUrlError}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          disabled={!canUseUserIdentity}
        />
      ),
    },
  ]
    .filter((block) => isPrimaryProduct || (!isPrimaryProduct && block.sectionId !== 'email-notifications'))
    .sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1;
      }
      if (!a.disabled && b.disabled) {
        return -1;
      }
      return 0;
    });

  const appearanceMenuItems = [
    {
      sectionId: 'general' as Section,
      disabled: false,
      block: (index: number) => (
        <GeneralAppearance key={index} chatInterface={chatInterface} setChatInterface={setChatInterface} />
      ),
    },
    {
      sectionId: 'messages' as Section,
      disabled: false,
      block: (index: number) => (
        <MessagesAppearance
          key={index}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          initialMessages={initialMessages}
          setInitialMessages={setInitialMessages}
        />
      ),
    },
    {
      sectionId: 'suggested-prompts' as Section,
      disabled: false,
      block: (index: number) => (
        <SuggestedPromptsAppearance
          key={index}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      sectionId: 'watermark' as Section,
      disabled: !canUseCustomWatermark,
      block: (index: number) => (
        <WatermarkAppearance key={index} chatInterface={chatInterface} setChatInterface={setChatInterface} />
      ),
    },
    {
      sectionId: 'button' as Section,
      disabled: false,
      block: (index: number) => (
        <WidgetButtonAppearance
          key={index}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
  ].sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1;
    }
    if (!a.disabled && b.disabled) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <SaveChangesHeader discardChanges={setInitialData} saveChanges={saveChanges} saving={saving} />
      <div className="h-full w-full flex flex-col lg:flex-row overflow-auto p-4 gap-12">
        <Accordion type="single" collapsible className="w-full lg:max-w-[calc(100%-525px)]">
          <div className="flex flex-row gap-2 text-small align-center px-2">
            <div className="p-2 h-fit bg-yellow-400 rounded-full">
              <Settings className="w-5 h-5" strokeWidth={2} />
            </div>
            <span className="my-auto text-sm text-muted-foreground">
              <strong>Settings</strong> - Customize settings, data collection, and appearance.
            </span>
          </div>
          <div className="mx-6 h-4 border-l border-solid">
            <span className="opacity-0">c</span>
          </div>
          {settingsMenuItems.map((item, index) => {
            return (
              <>
                {item.block(index)}
                <div key={`border-${index}`} className="mx-6 h-4 border-l border-solid">
                  <span className="opacity-0">c</span>
                </div>
              </>
            );
          })}
          <div className="flex flex-row gap-2 text-small align-center px-2">
            <div className="p-2 h-fit bg-indigo-600 rounded-full">
              <Database className="w-5 h-5 text-background" strokeWidth={2} />
            </div>
            <span className="my-auto text-sm text-muted-foreground">
              <strong>User Data Management</strong> - Collect and manage user data during engagement.
            </span>
          </div>
          <div className="mx-6 h-4 border-l border-solid">
            <span className="opacity-0">c</span>
          </div>
          {dataMenuItems.map((item, index) => {
            return (
              <>
                {item.block(index + 20)}
                <div key={`border-${index + 20}`} className="mx-6 h-4 border-l border-solid">
                  <span className="opacity-0">c</span>
                </div>
              </>
            );
          })}
          <div className="flex flex-row gap-2 text-small align-center px-2">
            <div className="p-2 h-fit bg-teal-600 rounded-full">
              <Palette className="w-5 h-5 text-background" strokeWidth={2} />
            </div>
            <span className="my-auto text-sm text-muted-foreground">
              <strong>Appearance & Style</strong> - Customize how your AI chat appears to users.
            </span>
          </div>
          <div className="mx-6 h-4 border-l border-solid">
            <span className="opacity-0">c</span>
          </div>
          {appearanceMenuItems.map((item, index) => {
            return (
              <>
                {item.block(index + 40)}
                {index !== appearanceMenuItems.length - 1 && (
                  <div key={`border-${index + 40}`} className="mx-6 h-4 border-l border-solid">
                    <span className="opacity-0">c</span>
                  </div>
                )}
              </>
            );
          })}
          <div className="h-12 hidden lg:block opacity-0">empty-holder</div>
        </Accordion>
        <div className="mx-auto lg:mx-0 lg:sticky lg:top-0 lg:bottom-4 flex flex-col gap-4 w-[450px]">
          <ChatAppearancePreview
            chatbot={chatbot}
            isPlatformView
            showDisclaimer={showDisclaimer}
            setShowDisclaimer={setShowDisclaimer}
            showForm={showForm}
            setShowForm={setShowForm}
            chatInterface={chatInterface}
          />
        </div>
      </div>
    </>
  );
};
export default Customizations;
