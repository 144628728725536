/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { cn } from 'utils/cn';
import { Loader2, LocateFixed, MessageCircle, Shield, X } from 'lucide-react';
import { Separator } from 'components/ui/separator';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { AIAgentCreateBody, Chatbot } from 'models/api/response.types';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import agentService from 'api/agent';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import useAiAgents from 'hooks/useAiAgents';
import { predefinedAgentsData } from 'utils/agent';
import ImageGeneratorDisclaimerDialog from 'components/Dialogs/Agents/ImageGeneratorDisclaimerDialog';

interface IPredefinedTemplate {
  disabled?: boolean;
  title: string;
  description: string;
  icon: JSX.Element;
  data: any;
}

const PredefinedTemplates: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const { isGPT4Enabled } = useSubscriptionInfo();
  const { agents, refetchAgents } = useAiAgents();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [creating, setCreating] = useState<number>(-1);
  const [showDisclaimerDialog, setShowDisclaimerDialog] = useState<boolean>(false);

  const spamDefenseAgentExists = useMemo(() => {
    return agents?.some((agent) => agent.type === 'spam-defense');
  }, [agents]);

  const createAIAgent = (data: AIAgentCreateBody, index: number) => {
    if (data.name === 'Image Generator') {
      setShowDisclaimerDialog(true);
    }
    setCreating(index);
    agentService
      .createAIAgent({
        ...data,
        description: data.description,
        prompt: data.prompt,
        chatbot_uuid: selectedChatbot.uuid,
      })
      .then(() => {
        refetchAgents();
        hide();
        setCreating(-1);
      })
      .catch(() => {
        setCreating(-1);
      });
  };

  const predefinedTemplates: IPredefinedTemplate[] = useMemo(() => {
    const templates = [
      {
        icon: <MessageCircle strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-slate-600" />,
        title: 'General Q&A',
        description: `The General Q&A agent handles general user inquiries 
        and provides informed answers based on knowledge from the source library.`,
        data: predefinedAgentsData.general_qa(isGPT4Enabled),
      },
      {
        icon: <LocateFixed strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-yellow-500" />,
        title: 'Fixed Response Agent',
        description: `The Fixed Response Agent outputs a pre-canned response no matter what input is made by the user. It is particularly useful for handling queries that are out-of-scope of your chatbot and should not be routed to a LLM.`,
        data: predefinedAgentsData.fixed_response,
      },
      // {
      //   disabled: spamDefenseAgentExists,
      //   icon: <Shield strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-destructive" />,
      //   title: 'Spam Defense Agent',
      //   description: `Spam Defense is designed to handle incoming queries that are spammy, malicious, or otherwise unrelated to your chatbot's designed scope of operations. It does not rely on any LLM, but rather outputs a fixed response regardless of what the user input.`,
      //   data: predefinedAgentsData.spam_defense,
      // },
    ];

    return templates;
  }, []);

  return (
    <>
      <div
        className={cn(
          'z-[3] absolute left-0 top-0 h-full transition-all duration-300 overflow-hidden ease-out w-full md:w-[500px]',
          show ? 'translate-x-[0px]' : '-translate-x-[120%]',
        )}
      >
        <div className="bg-background shadow-sm border-r h-full overflow-hidden flex flex-col pb-6">
          <div className="flex items-center text-lg font-semibold text-foreground px-6 pt-4">
            <h1>AI Agent Templates</h1>
            <button
              type="button"
              onClick={hide}
              className="rounded-sm border p-1 opacity-70 ring-offset-background transition-opacity hover:opacity-100 ml-auto"
            >
              <X strokeWidth={1.75} className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          <Separator className="my-4" />
          <div className="flex flex-col gap-4 px-6 overflow-auto">
            {predefinedTemplates.map((template, index) => {
              const { icon, title, disabled, data } = template;
              const isSpamDefense = data.type === 'spam-defense';
              const singleAgentOnly = isSpamDefense && spamDefenseAgentExists;
              return (
                <Card key={index} className=" w-full flex flex-col relative">
                  <CardHeader className="flex flex-row items-center gap-2 space-y-0 p-4 pb-0">
                    {icon}
                    <CardTitle className="line-clamp-1">{title}</CardTitle>
                  </CardHeader>
                  <Separator className="my-2" />
                  <CardContent className="p-4 pt-0 flex-1 flex flex-col">
                    <CardDescription className="mb-4 line-clamp-5">{template.description}</CardDescription>
                    <div className="flex items-center gap-2 mt-auto justify-end">
                      {singleAgentOnly ? (
                        <Button variant="outline" disabled>
                          Added (Only One)
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              createAIAgent(data, index);
                            }}
                            disabled={creating > -1 || disabled}
                            variant="outline"
                          >
                            {creating === index && (
                              <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Choose template
                          </Button>
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
      <ImageGeneratorDisclaimerDialog
        show={showDisclaimerDialog}
        close={() => setShowDisclaimerDialog(false)}
      />
    </>
  );
};

export default PredefinedTemplates;
