/* eslint-disable no-new */
import sourceService from 'api/source';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Loader2, File, CirclePlus } from 'lucide-react';
import { useAlerts } from 'providers/AlertProvider';
import React, { useRef, useState } from 'react';
import { alerts } from 'utils/alert';
import { nonValidSitemapExtensions } from 'utils/dataSources';
import { Chatbot } from 'models/api/response.types';
import SiteMapUrlList from './SIteMapUrlList';

// can't use redux selected chatbot as this uses also in chatbot template creation flow
interface ISiteMapUloader {
  chatbot: Chatbot;
  close: () => void;
}

const SiteMapUploader: React.FC<ISiteMapUloader> = ({ chatbot, close }) => {
  const { addAlert } = useAlerts();
  const [creating, setCreating] = useState<boolean>(false);
  const [siteMapUrl, setSiteMapUrl] = useState('');
  const [siteMapUrlError, setSiteMapUrlError] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [siteMapResults, setSiteMapResults] = useState<
    | {
        urlSet: string[];
        siteMapSet: string[];
      }
    | undefined
  >(undefined);

  const isValidUrl = (websiteUrl: string) => {
    try {
      new URL(websiteUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSiteMapSourceCreate = (url: string) => {
    setCreating(true);
    sourceService
      .getSiteMapSources(url, chatbot.uuid)
      .then(({ data }) => {
        const xmlDoc = new DOMParser().parseFromString(data, 'text/xml');
        const xmlTag = xmlDoc.documentElement.tagName;
        const extractedUrls: string[] = [];
        const extractedSiteMaps: string[] = [];
        if (xmlTag === 'urlset') {
          const urlsNodeList = xmlDoc.querySelectorAll('url loc');
          urlsNodeList.forEach((urlNode) => {
            if (!nonValidSitemapExtensions.some((extension) => urlNode?.textContent?.endsWith(extension))) {
              extractedUrls.push(urlNode.textContent || '');
            }
          });
        } else {
          const siteMapNodeList = xmlDoc.querySelectorAll('sitemap loc');
          siteMapNodeList.forEach((sitemap) => {
            extractedSiteMaps.push(sitemap.textContent || '');
          });
        }
        if (extractedUrls.length > 0 || extractedSiteMaps.length > 0) {
          setSiteMapResults({
            urlSet: extractedUrls,
            siteMapSet: extractedSiteMaps,
          });
        } else {
          addAlert({
            severity: 'info',
            message: alerts.SITEMAP_XML_ERROR,
          });
        }
        setCreating(false);
      })
      .catch((err) => {
        setCreating(false);
        if (err?.response?.status === 400) {
          addAlert({
            severity: 'error',
            message: alerts.CONTENT_FETCH_ERROR,
          });
        } else if (err?.response?.status === 403) {
          addAlert({
            severity: 'error',
            message: err?.response?.data?.error || alerts.SITEMAP_XML_RATE_LIMIT_ERROR,
          });
        } else if (err?.response?.status === 402) {
          addAlert({
            severity: 'error',
            message: alerts.FEATURE_CREDITS_ERROR,
            timeout: 10000,
          });
        } else if (
          err?.response?.status !== 500 &&
          !err?.response?.data?.error?.message &&
          err?.response?.data?.error?.includes('too big')
        ) {
          addAlert({
            severity: 'error',
            message: alerts.SITEMAP_CONTENT_LIMIT_ERROR,
          });
        } else {
          addAlert({
            severity: 'error',
            message: alerts.SITEMAP_LOAD_ERROR,
          });
        }
      });
  };

  const fileInputOnChange = () => {
    // setFileSources([]);
    if (fileInputRef?.current?.files?.length) {
      const { files } = fileInputRef.current;
      // user can walways upload only one sitemap file at a time
      const fileToUse = Array.from(files)[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const xmlContent = e.target?.result as string;
        try {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlContent, 'text/xml');
          const xmlTag = xmlDoc.documentElement.tagName;
          const extractedUrls: string[] = [];
          const extractedSiteMaps: string[] = [];
          if (xmlTag === 'urlset') {
            const urlsNodeList = xmlDoc.querySelectorAll('url loc');
            urlsNodeList.forEach((urlNode) => {
              if (urlNode.textContent) {
                extractedUrls.push(urlNode.textContent);
              }
            });
          } else {
            const siteMapNodeList = xmlDoc.querySelectorAll('sitemap loc');
            siteMapNodeList.forEach((sitemap) => {
              if (sitemap.textContent) {
                extractedSiteMaps.push(sitemap.textContent);
              }
            });
          }
          if (extractedUrls.length > 2000) {
            addAlert({
              severity: 'error',
              message: alerts.SITEMAP_CONTENT_LIMIT_ERROR,
            });
          } else if (extractedUrls.length > 0 || extractedSiteMaps.length > 0) {
            setSiteMapResults({
              urlSet: extractedUrls,
              siteMapSet: extractedSiteMaps,
            });
          } else {
            addAlert({
              severity: 'info',
              message: alerts.SITEMAP_XML_ERROR,
            });
          }
        } catch (err) {
          addAlert({
            severity: 'error',
            message: alerts.SITEMAP_LOAD_ERROR,
          });
        }
      };
      reader.readAsText(fileToUse);
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      {!siteMapResults ? (
        <div className="w-full max-h-full overflow-auto rounded-md bg-background p-6 text-left align-middle shadow-sm border">
          <h2 className="text-lg font-medium leading-6 text-gray-900">Add Website Sitemap</h2>
          <p className="font-normal text-muted-foreground text-sm mt-2">
            You can add a sitemap to the source list by either entering a URL that points to your sitemap or
            by directly uploading an XML file containing your sitemap.
          </p>
          <p className="font-normal text-warning text-sm mt-2">
            By using our web scraper, you acknowledge that you are authorized to capture and use all the data,
            including any personal identifying information, on the webpages you specify. You are responsible
            for maintaining compliance with your local copyright and data privacy laws.
          </p>
          <p className="font-normal text-warning text-sm mt-2">
            We will only load valid URLs from the sitemap, excluding any paths that point to invalid targets
            such as images, files, and other non-URL resources. This ensures that only appropriate and
            actionable web addresses are processed.
          </p>
          <div className="mt-6">
            <div className="flex items-center gap-4">
              <div className="flex-1">
                <Input
                  id="url"
                  type="url"
                  value={siteMapUrl}
                  placeholder="https://www.example.com/sitemap.xml"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSiteMapUrl(value);
                    if (value.length > 0) {
                      if (!isValidUrl(value) || !value.endsWith('.xml')) {
                        setSiteMapUrlError(true);
                      } else {
                        setSiteMapUrlError(false);
                      }
                    } else {
                      setSiteMapUrlError(false);
                    }
                  }}
                />
                {siteMapUrlError && (
                  <p className="text-xs text-destructive ml-1 mt-1">Invalid Sitemap URL.</p>
                )}
              </div>
              <Button
                onClick={() => {
                  handleSiteMapSourceCreate(siteMapUrl);
                }}
                className="self-start"
                disabled={siteMapUrlError || siteMapUrl.length === 0 || creating}
              >
                {creating ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" strokeWidth={1.75} />
                ) : (
                  <CirclePlus className="mr-2 h-4 w-4" strokeWidth={1.75} />
                )}
                {creating ? 'Adding' : 'Add'}
              </Button>
            </div>
          </div>
          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">Or</span>
            </div>
          </div>
          <Button
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
            variant="outline"
            className="w-full"
          >
            <File className="w-4 h-4 mr-2" strokeWidth={1.75} />
            Choose XML file
          </Button>
          <Input
            className="hidden"
            accept=".xml"
            ref={fileInputRef}
            id="file"
            type="file"
            onChange={fileInputOnChange}
          />
        </div>
      ) : (
        <SiteMapUrlList
          chatbot={chatbot}
          creatingSiteMap={creating}
          siteMapResults={siteMapResults}
          reRunSiteMap={(url: string) => {
            handleSiteMapSourceCreate(url);
          }}
          close={(try_again?: boolean) => {
            if (try_again) {
              setSiteMapResults(undefined);
            } else {
              close();
            }
          }}
        />
      )}
    </>
  );
};

export default SiteMapUploader;
