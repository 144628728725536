/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-new */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable consistent-return */
import { v4 as uuidv4 } from 'uuid';
import React, { ReactNode, createContext, useContext, useReducer } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import ToastMessage, { ToastCloseButton } from 'components/helpers/ToastMessage';
import useUserInfo from 'hooks/useUserInfo';
import userService from 'api/user';

type AlertSeverity =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'app_updates_available'
  | 'requested_human_escalation'
  | 'monitored_messages'
  | 'new_features'
  | 'attention_needed'
  | 'preview_he_disabled';

interface AlertCreateBody {
  severity: AlertSeverity;
  message: string;
  timeout?: number;
  alertPayload?: any;
}

const disableAutoCloseAlerts: AlertSeverity[] = [
  'preview_he_disabled',
  'app_updates_available',
  'requested_human_escalation',
  'new_features',
  'attention_needed',
  'monitored_messages',
];

export const crossBrowserTabsNotifications = ['requested_human_escalation', 'monitored_messages'];

const updatesSeenMetaAlerts: AlertSeverity[] = ['attention_needed', 'new_features'];

export interface Alert extends AlertCreateBody {
  id: string;
}

type Action = { type: 'ADD_ALERT'; payload: Alert } | { type: 'REMOVE_ALERT'; payload: string };

interface AlertsContextProps {
  alerts: Alert[];
  addAlert: (alert: AlertCreateBody) => void;
  removeAlert: (id: string) => void;
}

const AlertsContext = createContext<AlertsContextProps | undefined>(undefined);

const initialState: Alert[] = [];

const alertsReducer = (state: Alert[], action: Action): Alert[] => {
  switch (action.type) {
    case 'ADD_ALERT':
      return [...state, action.payload];
    case 'REMOVE_ALERT':
      return state.filter((alert) => alert.id !== action.payload);
    default:
      return state;
  }
};

const AlertsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userInfo, refetchUser } = useUserInfo();
  const [state, dispatch] = useReducer(alertsReducer, initialState);

  const updateUserLastUpdateSeen = () => {
    if (userInfo) {
      userService
        .updateUser({
          meta_json: JSON.stringify({
            ...JSON.parse(userInfo.meta_json),
            last_updates_seen_at: new Date().toISOString(),
          }),
        })
        .then(() => {
          refetchUser();
        })
        .catch((err) => err);
    }
  };

  // Toast - used for showing alert in ui
  // State - for global tracking
  const addAlert = (alert: AlertCreateBody) => {
    const newAlert = { ...alert, id: uuidv4() };
    toast(({ closeToast }) => <ToastMessage closeToast={closeToast} alert={newAlert} />, {
      autoClose: disableAutoCloseAlerts.includes(alert.severity) ? false : alert.timeout || 5000,
      onClose: () => {
        if (updatesSeenMetaAlerts.includes(newAlert.severity)) {
          updateUserLastUpdateSeen();
        }
        dispatch({ type: 'REMOVE_ALERT', payload: newAlert.id });
      },
    });
    dispatch({
      type: 'ADD_ALERT',
      payload: newAlert,
    });
  };

  const removeAlert = (id: string) => {
    dispatch({ type: 'REMOVE_ALERT', payload: id });
  };

  return (
    <AlertsContext.Provider value={{ alerts: state, addAlert, removeAlert }}>
      <ToastContainer
        position="top-right"
        transition={Slide}
        limit={4}
        stacked
        draggable
        pauseOnFocusLoss
        closeButton={({ closeToast }) => <ToastCloseButton closeToast={closeToast} />}
      />
      {children}
    </AlertsContext.Provider>
  );
};

const useAlerts = (): AlertsContextProps => {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error('useAlerts must be used within an AlertsProvider');
  }
  return context;
};

export { AlertsProvider, useAlerts };
