/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Speech } from 'lucide-react';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { Badge } from 'components/ui/badge';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlerts } from 'providers/AlertProvider';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot, ChatbotHumanEscalation, ChatbotHumanEscalationUpdateBody } from 'models/api/response.types';
import chatbotService from 'api/chatbot';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';

import debounce from 'utils/debounce';
import { alerts } from 'utils/alert';
import useChatbots from 'hooks/useChatbots';
import HumanEscalationOptions from 'components/helpers/ChatbotCustomizations/HumanEscalationOptions';
import { cn } from 'utils/cn';

interface HumanEscalationSettingsProps {
  disabled: boolean;
}

const HumanEscalationSettings: React.FC<HumanEscalationSettingsProps> = ({ disabled }) => {
  const queryClient = useQueryClient();
  const { updateChatbotByUUID } = useChatbots();
  const { addAlert } = useAlerts();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryKey = ['chatbot-human-escalation', selectedChatbot.uuid];
  const [mounted, setMounted] = useState<boolean>(false);
  const [agentExists, setAgentExists] = useState<boolean>(true);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => chatbotService.getChatbotHumanEscalationSettings(selectedChatbot.uuid),
    enabled: !disabled,
  });

  // error can be thrown if agent doesn't exist at all
  useEffect(() => {
    if (error && (error as any)?.response?.data?.error === 'human escalation agent not found') {
      setAgentExists(false);
    }
  }, [error]);

  // state for tracking changes to call debounce
  const [settings, setSettings] = useState<ChatbotHumanEscalation>({
    enabled: 0,
    human_escalation_settings: {
      human_requested_message: '',
      live_chat_start_announcement: '',
      live_chat_end_announcement: '',
      notifications_enabled: false,
      sound_enabled: false,
      monitored_messages: [],
    },
  });

  // when getting the data, use it
  // only on mount
  useEffect(() => {
    if (data && !mounted) {
      const { enabled, human_escalation_settings } = data;
      setSettings({
        enabled,
        human_escalation_settings,
      });
      setMounted(true);
    }
  }, [data]);

  // first time when user doesn't have HE agent
  const createAgent = () => {
    chatbotService
      .udpateChatbotHumanEscalationSettings(selectedChatbot.uuid, { enabled: 1 })
      .then((result) => {
        queryClient.setQueryData(queryKey, result);
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      });
  };

  const updateHE = useMutation({
    mutationFn: (payload: { uuid: string; body: ChatbotHumanEscalationUpdateBody }) =>
      chatbotService.udpateChatbotHumanEscalationSettings(payload.uuid, payload.body),
    onMutate: (payload) => {
      const newData = { ...data, ...payload.body } as ChatbotHumanEscalation;
      queryClient.setQueryData(queryKey, newData);
    },
    onSuccess: (_, context) => {
      updateChatbotByUUID(selectedChatbot.uuid, {
        ...selectedChatbot,
        human_escalation_enabled: !!context.body.enabled,
      });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey });
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
    },
  });

  // Data update for existing HE
  useEffect(() => {
    if (data && mounted) {
      // as we have more than just this, need to check only based on these params
      const { enabled, human_escalation_settings } = data;
      if (JSON.stringify({ enabled, human_escalation_settings }) !== JSON.stringify(settings)) {
        const update = debounce(() => updateHE.mutate({ uuid: selectedChatbot.uuid, body: settings }), 800);
        update();
      }
    }
  }, [settings, selectedChatbot.uuid]);

  return (
    <AccordionItem
      id="customizations-human-escalation"
      className="border bg-background rounded-md transition-all relative"
      value="human-escalation"
      disabled={disabled}
    >
      {disabled && <Badge className="absolute -top-2 p-1 right-12 rounded-md">Upgrade Required</Badge>}
      <AccordionTrigger className={cn('px-6 text-left hover:no-underline', disabled && 'opacity-40')}>
        <div>
          <div className="flex items-centrer gap-2">
            <Speech strokeWidth={1.75} className="w-6 h-6 " />
            Human Support Handover
          </div>
          <p className="text-xs text-muted-foreground ml-8">
            Configure how the AI should hand over the conversation to a human agent.
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="border-t py-6 px-6 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight"
            htmlFor="toggle-human-escalation"
          >
            Enable Human Escalation
          </Label>
          <p className="text-muted-foreground text-sm">
            By enabling chatbot will be able to transfer chat to real human for customer support
          </p>
          <Switch
            id="toggle-human-escalation"
            checked={!!settings.enabled}
            onCheckedChange={(enable) => {
              // if agent doesn't exist we need to initiate a creation
              // otherwise regular update
              if (!agentExists) {
                createAgent();
              } else {
                setSettings({
                  ...settings,
                  enabled: enable ? 1 : 0,
                });
              }
            }}
          />
        </div>
        {!!settings.enabled && (
          <>
            <HumanEscalationOptions
              settings={settings.human_escalation_settings}
              setSettings={(sett: ChatbotHumanEscalation['human_escalation_settings']) => {
                setSettings({
                  ...settings,
                  human_escalation_settings: sett,
                });
              }}
            />
          </>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

export default HumanEscalationSettings;
