/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Braces, Pencil, PlusCircle, X } from 'lucide-react';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { Badge } from 'components/ui/badge';
import { ChatbotVariable } from 'utils/bot';
import CreateEditChatbotVariableDialog from 'components/Dialogs/Chatbot/CreateEditChatbotVariableDialog';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Button } from 'components/ui/button';

interface VariablesSettingsProps {
  disabled: boolean;
  variables: ChatbotVariable[];
  setVariables: (vars: ChatbotVariable[]) => void;
}

const VariablesSettings: React.FC<VariablesSettingsProps> = ({ disabled, variables, setVariables }) => {
  const { chatbotVariableLimit } = useSubscriptionInfo();
  const [openParameterDialog, setOpenParameterDialog] = useState<boolean>(false);
  const [variableToEdit, setVariableToEdit] = useState<undefined | ChatbotVariable>(undefined);
  const [canAddVariables, setCanAddVariables] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;
    if (!div) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
          const newState = div.getAttribute('data-state');
          if (newState === 'open' && chatbotVariableLimit > variables.length) {
            setCanAddVariables(true);
          } else {
            setCanAddVariables(false);
          }
        }
      });
    });

    observer.observe(div, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  const updateVariable = (newVariable: ChatbotVariable) => {
    if (variableToEdit) {
      const index = variables.findIndex((v) => v.name === variableToEdit.name);
      const newVariables = [...variables];
      newVariables[index] = newVariable;
      setVariables(newVariables);
    } else {
      setVariables([newVariable, ...variables]);
    }
    setVariableToEdit(undefined);
  };

  return (
    <>
      <AccordionItem
        ref={divRef}
        id="customizations-variables"
        disabled={disabled}
        className="border bg-background rounded-md transition-all"
        value="variables"
      >
        {disabled && <Badge className="absolute -top-2 p-1 right-12 rounded-md">Upgrade Required</Badge>}
        <AccordionTrigger className="px-6 text-left hover:no-underline gap-4">
          <div>
            <div className="flex items-centrer gap-2">
              <Braces strokeWidth={1.75} className="w-6 h-6 " />
              User Data Collection (Pre-defined Variables)
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Capture user data conversationally and store it into a list of pre-defined variables.
            </p>
          </div>
          {canAddVariables && (
            <Button
              className="ml-auto"
              onClick={(e) => {
                e.stopPropagation();
                setOpenParameterDialog(true);
              }}
            >
              <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
              Add variable
            </Button>
          )}
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-4">
          <p className="text-sm text-muted-foreground">
            Define the information you would like to store in the session cache. The session cache persists
            for as long as the chatbot is active. Variables within the session cache are shared across all
            agents and can be used as parameters or criteria for function calling, user data collection (e.g.
            name, email, phone, etc.), or session metadata storage.
          </p>
          {variables.length > 0 ? (
            <div className="flex flex-col gap-2">
              {variables.map((variable, index) => {
                return (
                  <div
                    key={variable.name}
                    className="rounded-md border p-2 px-4 flex items-start space-x-4 transition-all overflow-hidden"
                  >
                    <div className="space-y-1 flex-1 overflow-hidden">
                      <p className="text-sm font-medium leading-none text-left">{variable.name}</p>
                      <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                        {variable.description}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setVariableToEdit(variable);
                        setOpenParameterDialog(true);
                      }}
                      className="ml-4 mt-3"
                    >
                      <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setVariables(variables.filter((_item, currentIndex) => currentIndex !== index));
                      }}
                      className="ml-1 mt-3"
                    >
                      <X
                        strokeWidth={1.75}
                        className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
              No existing variables
            </Badge>
          )}
        </AccordionContent>
      </AccordionItem>
      {openParameterDialog && (
        <CreateEditChatbotVariableDialog
          variableToEdit={variableToEdit}
          currentVariables={variables}
          close={(variable?: ChatbotVariable) => {
            if (variable) {
              updateVariable(variable);
            } else {
              setVariableToEdit(undefined);
            }
            setOpenParameterDialog(false);
          }}
        />
      )}
    </>
  );
};

export default VariablesSettings;
