/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import 'react-querybuilder/dist/query-builder.css';
import { TabsContent } from 'components/ui/tabs';
import { Button } from 'components/ui/button';
import { Loader2, PlusCircle } from 'lucide-react';
import { AIAgentFunctionList, AgentRule, AgentRuleList, Chatbot } from 'models/api/response.types';
import { Badge } from 'components/ui/badge';
import agentService from 'api/agent';
import {
  getSelectedChatbot,
  getShowAgentRuleInvalidState,
  setShowAgentRuleInvalidState,
} from 'store/reducers/ui';
import { useSelector, useDispatch } from 'react-redux';
import { initialRuleQuery } from 'utils/agent';
import { useQueryClient } from '@tanstack/react-query';
import DeleteAgentRuleDialog from 'components/Dialogs/Agents/DeleteAgentRuleDialog';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import reorder from 'utils/reorder';
import { ChatbotVariable } from 'utils/bot';
import Rule from './Rule';

const RuleTab: React.FC<{
  opened: boolean;
  rules: AgentRuleList;
  agents: { name: string; uuid: string }[];
  functions: AIAgentFunctionList;
  variables: ChatbotVariable[];
}> = ({ opened, rules, agents, functions, variables }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const showAgentRuleInvalidState = useSelector(getShowAgentRuleInvalidState);
  const [creating, setCreating] = useState<boolean>(false);
  const [ruleToEdit, setRuleToEdit] = useState<string | undefined>(undefined);
  const [ruleToDelete, setRuleToDelete] = useState<string | undefined>(undefined);
  const [rulesValidation, setRulesValidation] = useState<{ [key: string]: boolean }>({});
  const rulesQueryKey = ['agent-rules', selectedChatbot.uuid];

  const allRulesAreValid = useMemo(() => {
    if (Object.values(rulesValidation).length > 0) {
      return Object.values(rulesValidation).every((state) => state);
    }
    return true;
  }, [rulesValidation]);

  useEffect(() => {
    if (allRulesAreValid && showAgentRuleInvalidState) {
      dispatch(setShowAgentRuleInvalidState(false));
    }
    if (!allRulesAreValid && !showAgentRuleInvalidState) {
      dispatch(setShowAgentRuleInvalidState(true));
    }
  }, [allRulesAreValid]);

  useEffect(() => {
    if (!opened && ruleToEdit) {
      setRuleToEdit(undefined);
    }
  }, [opened]);

  // whenever rule length changes need to remove non
  // existing rules from rule validator
  useEffect(() => {
    if (Object.values(rulesValidation).length > 0) {
      const validation = { ...rulesValidation };
      Object.keys(validation).forEach((key) => {
        if (!rules.some((r) => r.uuid === key)) {
          delete validation[key];
        }
      });
      setRulesValidation(validation);
    }
  }, [rules.length]);

  const createNewRule = () => {
    setCreating(true);
    // have to add default agent to prevent validation errors
    const updatedInitialQuery = { ...initialRuleQuery } as unknown as any;
    updatedInitialQuery.rules[2].value = agents[0].uuid;
    agentService
      .createAgentRule(selectedChatbot.uuid, {
        priority: rules.length + 1,
        enabled: 0,
        rule: updatedInitialQuery,
      })
      .then((res) => {
        setCreating(false);
        const existingRules: AgentRuleList | undefined = queryClient.getQueryData(rulesQueryKey);
        if (existingRules) {
          const newRules = [...existingRules, res];
          queryClient.setQueryData(rulesQueryKey, newRules);
        } else {
          queryClient.invalidateQueries({ queryKey: rulesQueryKey });
        }
        setTimeout(() => {
          const container = document.getElementById('tabs');
          if (container) {
            container.scrollTo({
              top: container.scrollHeight,
              behavior: 'smooth',
            });
          }
        }, 300);
      })
      .catch(() => setCreating(false));
  };

  const columnDragEnd = (response: DropResult) => {
    if (!response.destination || response.destination.index === response.source.index) return;
    const { destination, source } = response;
    const rule = rules[source.index];
    const newOrder = reorder(rules, source.index, destination.index);
    const prevColumn = newOrder[destination.index - 1];
    const nextColumn = newOrder[destination.index + 1];

    let newPriorityId = rule.priority;
    if (prevColumn && nextColumn) {
      newPriorityId = (prevColumn.priority + nextColumn.priority) / 2;
    } else if (prevColumn) {
      newPriorityId = prevColumn.priority + 1;
    } else if (nextColumn) {
      newPriorityId = (0 + nextColumn.priority) / 2;
    } else {
      newPriorityId = 1;
    }

    const newRules = newOrder.map((r) => (r.uuid === rule.uuid ? { ...r, priority: newPriorityId } : r));
    queryClient.setQueryData(rulesQueryKey, newRules);

    agentService.updateAgentRule(rule.uuid, { priority: newPriorityId }).catch(() => {
      queryClient.invalidateQueries({ queryKey: rulesQueryKey });
    });
  };

  const handleRuleValidation = (id: string, isValid: boolean) => {
    setRulesValidation((prevStates) => {
      const newStates = { ...prevStates, [id]: isValid };
      return newStates;
    });
  };

  return (
    <>
      <TabsContent className="flex flex-col gap-6 mt-0" value="rules">
        <p className="text-sm text-muted-foreground">
          Using conditional logic builder, specify criteria that, when satisfied, would deterministically
          assign a User Facing Agent to handle the incoming user query. These conditions are evaluated every
          time the user submits an input to the chatbot. If there are multiple conditions in the queue, the
          first condition that evaluates to <strong>TRUE</strong> will be executed and all subsequent
          evaluations will be skipped. If no condition evaluates to <strong>TRUE</strong>, then the AI
          Supervisor will continue on with default behavior and use its own judgement.
        </p>
        <div className="flex w-full items-center justify-between">
          {rules.length === 0 && (
            <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
              No existing rules
            </Badge>
          )}
          {rules.length < 20 && (
            <Button
              className={rules.length > 0 ? 'ml-auto' : ''}
              disabled={creating || !!ruleToEdit}
              onClick={createNewRule}
            >
              {creating ? (
                <Loader2 strokeWidth={1.75} className="h-4 w-4 mr-2 animate-spin transition-all" />
              ) : (
                <PlusCircle strokeWidth={1.75} className="h-4 w-4 mr-2" />
              )}
              Create new rule
            </Button>
          )}
        </div>
        <DragDropContext onDragEnd={columnDragEnd}>
          <Droppable droppableId="droppableAgentRules" direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {rules.map((rule: AgentRule, index) => (
                  <Draggable
                    key={rule.uuid}
                    draggableId={rule.uuid}
                    index={index}
                    isDragDisabled={!!ruleToEdit}
                  >
                    {(secondaryProvided) => (
                      <div
                        className="mb-6"
                        ref={secondaryProvided.innerRef}
                        {...secondaryProvided.draggableProps}
                      >
                        <Rule
                          canRender={opened}
                          isValid={rulesValidation[rule.uuid]}
                          key={rule.uuid}
                          editModeEnabled={ruleToEdit}
                          rule={rule}
                          agents={agents}
                          functions={functions}
                          variables={variables}
                          ruleValidation={handleRuleValidation}
                          deleteRule={() => {
                            setRuleToDelete(rule.uuid);
                          }}
                          editRule={() => {
                            setRuleToEdit(rule.uuid);
                          }}
                          stopEditing={() => {
                            setRuleToEdit(undefined);
                          }}
                          dragHandleProps={secondaryProvided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TabsContent>
      <DeleteAgentRuleDialog
        rule={ruleToDelete}
        close={() => {
          setRuleToDelete(undefined);
        }}
      />
    </>
  );
};

export default RuleTab;
