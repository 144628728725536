import React from 'react';
import { Skeleton } from 'components/ui/skeleton';
import { DataRow } from 'utils/sources';
import { sourceFinalStates } from './SourceStatus';

const SourceTitle: React.FC<{
  source: DataRow;
}> = ({ source }) => {
  return (
    <>
      <div className="overflow-hidden flex items-center">
        {source?.title ? (
          <p className="line-clamp-1">{source.title}</p>
        ) : !sourceFinalStates.includes(source.status) ? (
          <Skeleton className="h-[24px] w-[200px]" />
        ) : null}
      </div>
    </>
  );
};

export default SourceTitle;
