/* eslint-disable consistent-return */
import React, { ReactNode, createContext, useContext, useReducer, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/reducers/session';
import { getSelectedChatbot } from 'store/reducers/ui';
import useSocket from 'hooks/useSocket';
import { Chatbot, HERequestNotification, MonitoredMessageNotification } from 'models/api/response.types';
import sessionService from 'api/session';
import useAppVisibility from 'hooks/useAppVisibility';
import { hasInboxAccessByChatbot } from 'utils/routeValidation';
import { useAlerts } from './AlertProvider';

type UnreadSessionsAction =
  | { type: 'DECREMENT' }
  | { type: 'ALL_READ' }
  | { type: 'UPDATE'; payload: number };

interface ChatContextProps {
  unreadSessions: number;
  decrementUnreads: () => void;
  markAllAsRead: () => void;
  updateUnreads: (value: number) => void;
  subscribeToEvent:
    | ((eventName: string, callback: (...args: any[]) => void) => (() => void) | undefined)
    | undefined;
  emitEvent: ((eventName: string, data: any, callback?: any) => void) | undefined;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

const unreadSessionsReducer = (state: number, action: UnreadSessionsAction): number => {
  switch (action.type) {
    case 'DECREMENT':
      return state - 1;
    case 'UPDATE':
      return action.payload;
    case 'ALL_READ':
      return 0;
    default:
      return state;
  }
};

const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { addAlert } = useAlerts();
  const user = useSelector(getCurrentUser);
  const isVisible = useAppVisibility();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { subscribeToEvent, emitEvent } = useSocket(true, user?.access_token);
  const [unreadSessions, unreadSessionsDispatch] = useReducer(unreadSessionsReducer, 0);

  // collaborators with no permissions to Inbox doesn't need to call
  // anything from ChatProvider
  const { hasPermissionsForInbox, canAccessLiveChats } = useMemo(() => {
    return {
      hasPermissionsForInbox: hasInboxAccessByChatbot(chatbot),
      canAccessLiveChats: chatbot.human_escalation_enabled,
    };
  }, [chatbot]);

  const decrementUnreads = () => {
    unreadSessionsDispatch({ type: 'DECREMENT' });
  };
  const markAllAsRead = () => {
    unreadSessionsDispatch({ type: 'ALL_READ' });
  };
  const updateUnreads = (value: number) => {
    unreadSessionsDispatch({ type: 'UPDATE', payload: value });
  };

  // trigger for unread messages on load
  useEffect(() => {
    if (hasPermissionsForInbox) {
      sessionService.getChatbotUnreadSessionCount(chatbot.uuid).then((response: number) => {
        updateUnreads(response);
      });

      if (canAccessLiveChats && emitEvent) {
        emitEvent('subscribe_chatbot_unread_sessions', chatbot.uuid);

        return () => {
          emitEvent('unsubscribe_chatbot_unread_sessions', chatbot.uuid);
        };
      }
    }
  }, [chatbot.uuid, emitEvent, canAccessLiveChats, hasPermissionsForInbox]);

  // any user global events, currently chat notifications only
  useEffect(() => {
    if (hasPermissionsForInbox) {
      if (emitEvent) {
        emitEvent('subscribe_user_events', undefined);
      }

      return () => {
        emitEvent('unsubscribe_user_events', undefined);
      };
    }
  }, [user, emitEvent, hasPermissionsForInbox]);

  // subscribe to receive changes for unreads sessions
  useEffect(() => {
    if (canAccessLiveChats && hasPermissionsForInbox) {
      const cancelSubForUnreadSessions = subscribeToEvent('unread_sessions', (value: number) => {
        updateUnreads(value);
      });

      return () => {
        cancelSubForUnreadSessions?.();
      };
    }
  }, [subscribeToEvent, canAccessLiveChats, hasPermissionsForInbox]);

  useEffect(() => {
    if (hasPermissionsForInbox && canAccessLiveChats) {
      // when client specifically requested to talk with the human
      const cancelSubForHERequests = subscribeToEvent(
        'requested_human_escalation',
        (data: HERequestNotification) => {
          addAlert({
            severity: 'requested_human_escalation',
            alertPayload: data,
            message: 'Support requested from the user',
          });
        },
      );

      // when ai responded with monitored message or similar
      const cancelSubForMessageMonitorRequests = subscribeToEvent(
        'monitored_messages',
        (data: MonitoredMessageNotification) => {
          addAlert({
            severity: 'monitored_messages',
            alertPayload: data,
            message: 'Monitored message response',
          });
        },
      );

      return () => {
        cancelSubForHERequests?.();
        cancelSubForMessageMonitorRequests?.();
      };
    }
  }, [subscribeToEvent, isVisible, hasPermissionsForInbox, canAccessLiveChats]);

  const contextValue: ChatContextProps = useMemo(() => {
    return {
      unreadSessions,
      decrementUnreads,
      updateUnreads,
      subscribeToEvent,
      markAllAsRead,
      emitEvent,
    };
  }, [unreadSessions, subscribeToEvent, emitEvent, user]);

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};

const useChat = (): ChatContextProps => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }
  return context;
};

export { ChatProvider, useChat };
