/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { Separator } from 'components/ui/separator';
import { AIAgent } from 'models/api/response.types';
import { CircleIcon, MoreHorizontal, Pencil, Trash2, Lock, BrainCircuit } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Button } from 'components/ui/button';
import { Badge } from 'components/ui/badge';
import { cn } from 'utils/cn';
import { IntentToDelete, IntentToUpdate } from 'components/Dialogs/Agents/CreateEditAgentIntentDialog';

export interface Intent {
  intent: string;
  examples: string[];
}

const AgentIntent: React.FC<{
  isAlwaysActive: boolean;
  agent: AIAgent;
  updateIntent: (intentToUpdate: IntentToUpdate) => void;
  deleteIntent: (intentToDelete: IntentToDelete) => void;
}> = ({ isAlwaysActive, agent, updateIntent, deleteIntent }) => {
  const { embedding_status, intents_json } = agent;
  const { intents, locked_intents } = JSON.parse(intents_json || '{}');

  const { canDeleteIntents, intentsCount } = useMemo(() => {
    const intentsCounter = (intents?.length || 0) + (locked_intents?.length || 0);
    return {
      canDeleteIntents: intentsCounter > 1,
      intentsCount: intentsCounter,
    };
  }, [intents, locked_intents]);

  const { isLoading } = useMemo(() => {
    return {
      isLoading:
        !isAlwaysActive && ['embedding', 'intents_mapping', 'not_started'].includes(embedding_status),
    };
  }, [agent]);

  const generateIntentMenu = (idx: number, is_locked: boolean) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="px-2 py-0 shadow-none h-8">
            <MoreHorizontal strokeWidth={1.75} className="h-4 w-4 text-secondary-foreground" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" forceMount>
          <DropdownMenuItem
            onClick={() => {
              updateIntent({
                intent: is_locked ? locked_intents[idx] : intents[idx],
                index: idx,
                is_locked,
              });
            }}
          >
            <Pencil strokeWidth={1.75} className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          {canDeleteIntents && (
            <DropdownMenuItem
              onClick={() => deleteIntent({ index: idx, is_locked })}
              className="focus:text-destructive focus:bg-destructive/10"
            >
              <Trash2 strokeWidth={1.75} className="mr-2 h-4 w-4" /> Delete
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  const renderIntentItem = (intent: Intent, idx: number, is_locked: boolean) => {
    return (
      <AccordionItem disabled={isLoading} className="px-4" key={idx} value={intent.intent}>
        <div className="flex items-center gap-4">
          <AccordionTrigger className="w-full font-normal">{intent.intent}</AccordionTrigger>
          {isLoading && !is_locked ? (
            <CircleIcon strokeWidth={1.75} className="h-3 w-3 fill-blue-500 text-blue-500 animate-pulse" />
          ) : (
            generateIntentMenu(idx, is_locked)
          )}
        </div>
        <AccordionContent>
          {intent?.examples?.length > 0 && (
            <div className="mt-2 flex items-center gap-2 whitespace-nowrap flex-wrap">
              {intent?.examples?.length > 0 ? (
                <>
                  {intent?.examples.map((example, index) => {
                    return (
                      <Badge
                        className="overflow-hidden line-clamp-1 text-ellipsis"
                        variant="outline"
                        key={index}
                      >
                        {example}
                      </Badge>
                    );
                  })}
                </>
              ) : (
                <span>No provided examples</span>
              )}
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    );
  };

  if (intentsCount <= 0) {
    return (
      <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
        No existing intents for this agent
      </Badge>
    );
  }

  return (
    <div className="rounded-md border" key={agent.uuid}>
      {intents?.length > 0 && (
        <>
          <div className="text-sm px-4 py-2 bg-secondary-background rounded-t-md">
            <div className="flex items-center font-semibold">
              <BrainCircuit strokeWidth={1.75} className="mr-2 h-4 w-4 min-w-[16px]" />
              AI-Generated Intents
            </div>
            <p className="text-sm text-muted-foreground">
              Re-generated automatically each time Agent is updated.
            </p>
          </div>
          <Separator orientation="horizontal" />
          <Accordion type="multiple" className="w-full">
            {intents?.map((intent: Intent, index: number) => renderIntentItem(intent, index, false))}
          </Accordion>
        </>
      )}
      {locked_intents?.length > 0 && (
        <>
          <div
            className={cn(
              'text-sm px-4 py-2 bg-secondary-background',
              (!intents || intents.length === 0) && 'rounded-t-md',
            )}
          >
            <div className="flex items-center font-semibold">
              <Lock strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Fixed Intents
            </div>
            <p className="text-sm text-muted-foreground">
              Permanently saved and does not get re-generated each time Agent is updated.
            </p>
          </div>
          <Separator orientation="horizontal" />
          <Accordion type="multiple" className="w-full">
            {locked_intents?.map((intent: Intent, index: number) => renderIntentItem(intent, index, true))}
          </Accordion>
        </>
      )}
    </div>
  );
};

export default AgentIntent;
