/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { Label } from 'components/ui/label';
import { ShieldCheck } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Switch } from 'components/ui/switch';
import { Textarea } from 'components/ui/textarea';
import { Input } from 'components/ui/input';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface DisclaimerProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  setShowDisclaimer: (show: boolean) => void;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ chatInterface, setChatInterface, setShowDisclaimer }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;
    if (!div) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
          const newState = div.getAttribute('data-state');
          if (newState === 'open' && chatInterface?.disclaimer?.enabled) {
            setShowDisclaimer(true);
          } else {
            setShowDisclaimer(false);
          }
        }
      });
    });

    observer.observe(div, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, [chatInterface?.disclaimer?.enabled]);

  return (
    <AccordionItem
      ref={divRef}
      id="customizations-disclaimer"
      className="border bg-background rounded-md transition-all"
      value="disclaimer"
    >
      <AccordionTrigger className="px-6 text-left hover:no-underline">
        <div>
          <div className="flex items-centrer gap-2">
            <ShieldCheck strokeWidth={1.75} className="w-6 h-6 " />
            Disclaimer
          </div>
          <p className="text-xs text-muted-foreground ml-8">
            Custom disclaimer for users to accept your terms before chatting, enhancing compliance and trust.
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="border-t py-6 px-6 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="disclaimer">
            Enable Disclaimer
          </Label>
          <p className="text-sm text-muted-foreground">
            By enabling the disclaimer, a pop-up will appear for users to accept your terms before starting
            the chat. The chat will remain disabled until the user accepts the disclaimer.
          </p>
          <Switch
            id="disclaimer"
            checked={chatInterface?.disclaimer?.enabled || false}
            onCheckedChange={(show) => {
              setChatInterface({
                ...chatInterface,
                disclaimer: {
                  ...chatInterface.disclaimer,
                  enabled: show,
                },
              });
              setShowDisclaimer(show);
            }}
          />
        </div>
        {chatInterface?.disclaimer?.enabled && (
          <>
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight"
                htmlFor="disclaimer-description"
              >
                Description
              </Label>
              <p className="text-sm text-muted-foreground">
                Please enter the text for your disclaimer below. This is the message that users will need to
                accept before they can start chatting. You can use Markdown elements for formatting.{' '}
                <a
                  className="text-secondary font-medium hover:underline"
                  href="https://www.markdownguide.org/cheat-sheet/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </p>
              <Textarea
                className="min-h-[100px]"
                id="disclaimer-description"
                value={chatInterface?.disclaimer?.description || ''}
                placeholder="We endeavor to deliver accurate and comprehensive information through ..."
                onChange={(e) => {
                  setChatInterface({
                    ...chatInterface,
                    disclaimer: {
                      ...chatInterface.disclaimer,
                      description: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight"
                htmlFor="disclaimer-accept-button"
              >
                Accept Button Label
              </Label>
              <p className="text-muted-foreground text-sm">
                The button that users has to click to accept disclaimer.
              </p>
              <Input
                id="disclaimer-accept-button"
                type="text"
                placeholder="I agree"
                value={chatInterface?.disclaimer?.button_text || ''}
                onChange={(e) => {
                  setChatInterface({
                    ...chatInterface,
                    disclaimer: {
                      ...chatInterface.disclaimer,
                      button_text: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

export default Disclaimer;
