/* eslint-disable import/prefer-default-export */

export const alerts = {
  SCRIPT_COPIED: 'Script successfully copied.',
  IFRAME_COPIED: 'Iframe successfully copied.',
  DOMAIN_EXISTS: 'Domain already exists.',
  VALUE_COPIED: 'Value successfully copied.',
  LINK_COPIED: 'Link successfully copied.',
  KEY_COPIED: 'Key successfully copied.',
  SOMETHING_WRONG: 'Something went wrong. Please try again later.',
  PAGE_REFRESH_ERROR: 'Something went wrong. Please refresh the page and try again.',
  SUBSCRIPTION_UPDATE: 'Subscription updated successfully.',
  SUBSCRIPTION_CANCEL: 'Subscription cancelled successfully.',
  URL_SUBSCRIPTION_LIMIT: 'We encountered a problem retrieving the URL due to subscription limits.',
  GOOGLE_ISS_REDIRECT_FAILED:
    'Google Drive IIS Redirect Configuration Invalid. If you believe this is an error or need assistance, please contact our support team.',
  SLACK_SUCCESS: 'Slack integration completed successfully! Test it out in your workspace now.',
  SLACK_ERROR: 'Sorry, something went wrong with Slack installation, please try again.',
  CHATBOT_UUID_COPIED: 'Chatbot uuid successfully copied.',
  SESSION_UUID_COPIED: 'Session uuid successfully copied.',
  CHANGES_SAVED: 'Your changes have been saved.',
  AGENT_SAVE_CHANGES_ERROR: `Please save changes before proceeding to another section.`,
  AGENT_SAVE_CHANGES_CREATE_ERROR: `Please fix issues before proceeding with agent creation.`,
  AGENT_UPDATE: `Agent has been successfully updated.`,
  AGENT_INTENTS_UPDATED: 'Agent and its intents have been successfully updated.',
  FIX_ISSUES: 'Please fix issue(s) before saving.',
  INVALID_REFERRAL: 'Invalid referral code or email.',
  INVALID_REFERRAL_LINK: 'Invalid referral link.',
  INVALID_VERIFICATION_CODE: 'Invalid verification code.',
  EMAIL_EXISTS: 'Email already exists.',
  REVISE_ANSWER_UPDATE: 'Answer has been successfully updated and added to your sources for easy reference.',
  URL_EXISTS_ERROR: `Sorry, this URL already exists in the chatbot's sources. Please use a different URL or check the existing sources.`,
  PASSWORD_UPDATE: 'Password was updated successfully.',
  ALREADY_INVITED_ERROR: 'This user has already been invited.',
  INVITE_DELETE_SUCCESS: 'Invite was deleted successfully.',
  COLLAB_DELETE_SUCCESS: 'Collaborator was deleted successfully.',
  SINGLE_AGENT_DELETE_ERROR:
    'Cannot delete this agent as it is the only active and enabled User Facing agent. Please create a new active and enabled User Facing agent before attempting to delete this one.',
  UNAVAILABLE_MODE: 'Public/Private modes are not available for this feature. Switching visibility to Embed.',
  GOOGLE_AUTH_FAILED:
    'Google Drive Authentication Failed. Check credentials and permissions. If you believe this is an error or need assistance, please contact our support team.',
  APP_SUMO_ERROR:
    'Please register a new account for the AppSumo plan or contact support for plan cancellation assistance.',
  APP_SUMO_CODE_ERROR: 'Invalid AppSumo code.',
  APP_SUMO_IDENTITY_ERROR: 'Invalid AppSumo identity.',
  INVITE_SEND_ERROR: 'Something went wrong when we tried to send a new invite. Please try again later.',
  SURVEY_SUCCESS: `You've successfully completed the survey. Thank you for sharing your feedback with us.`,
  SURVEY_ERROR:
    'Oops, something went wrong while submitting your survey. We will request you to complete the survey at a later time. Thank you for your understanding and patience.',
  SOMETHING_WRONG_LONG:
    'Something went wrong. Please try again later. If you believe this is an error or need assistance, please contact our support team.',
  SOURCE_CHUNKS_SAVE_ERROR: 'Some data source chunks have errors. Please fix them before saving.',
  SOURCE_CHUNKS_SAVE_SUCCESS: 'Data source chunks have been successfully updated.',
  SOURCE_TABLE_UPDATE_SUCCESS: 'Table has been successfully updated.',
  SOURCE_CHUNKS_LONG: 'Some chunks were too long, please shorten and resubmit.',
  SOURCE_CHUNK_LONG: 'Chunk is too long, please shorten and resubmit.',
  SOURCE_CHUNKS_CLEANUP_SUCCESS: 'Clean up completed, please save to keep the changes.',
  SOURCE_CELL_ERROR: "Column header can't be empty.",
  SOURCE_COLUMN_EXISTS: 'Column header with this name already exists.',
  SOURCE_ROW_HEADER_EXISTS: 'Row header with this name already exists.',
  SITEMAP_LOAD_ERROR:
    'Something went wrong while extracting the sitemap. Please try again or contact support.',
  SITEMAP_XML_ERROR:
    'We encountered an issue while trying to retrieve the URLs from the provided sitemap XML. URL you entered either does not exist or is currently inaccessible.',
  SITEMAP_XML_RATE_LIMIT_ERROR:
    "Failed to fetch the sitemap.xml. You may have reached the server's rate limit. Please try again later.",
  SITEMAP_CONTENT_LIMIT_ERROR:
    'Sitemap is too big to extract. Please try a smaller sitemap or contact support.',
  CONTENT_FETCH_ERROR: `Content Fetch Unsuccessful. We're currently unable to access the content 
from the requested resource due to restrictions on their server.`,
  FEATURE_CREDITS_ERROR: `You do not have enough Feature Credits to use advanced scraping.`,
  LOAD_URL_ERROR:
    "Failed to crawl the URL. You may have reached the server's rate limit. Please try again later.",
  COLLAB_PERMISSION_UPDATE_SUCCESS: (email: string) => `Permissions for ${email} were update successfully.`,
  INVITE_SEND_SUCCESS: (email: string) => `We have successfully sent an invitation to ${email}.`,
  FILE_DOWNLOAD_LOAD: (filename: string) => `Downloading ${filename}.`,
  FILE_DOWNLOAD_SUCCESS: (filename: string) => `${filename} successfully donwloaded.`,
  FILE_DONWLOAD_ERROR: (filename: string) =>
    `Something went wrong with donwloading ${filename}. Please try again later.`,
  DOMAIN_META_TAGS: (domain: string) => `Meta tags for ${domain} updated successfully.`,
  INVITE_ACCEPT: (name: string) => `You have successfully accepted the invitation to join ${name} chatbot.`,
  BOT_TRASNFER_OWNERSHIP_REQUEST_CREATE: (botName: string, email: string) =>
    `Your request for transferring ${botName} ownership to ${email}  has been submitted successfully.`,
  BOT_TRASNFER_OWNERSHIP_REQUEST_DELETED: 'Ownership transfer request was successfully deleted.',
  BOT_TRASNFER_OWNERSHIP_SUCCESSS: (botName: string) =>
    `Ownership transfer for the ${botName} has been successfully completed.`,
  WHATSAPP_SETUP_SUCCESS: 'WhatsApp integration completed successfully.',
  WHATSAPP_SETUP_ERROR: 'Sorry, something went wrong during WhatsApp integration, please try again.',
  INSTAGRAM_SETUP_SUCCESS: 'Your Instagram account was connected to the chatbot successfully.',
  INSTAGRAM_SETUP_ERROR:
    'Sorry, something went wrong while connecting your Instagram account to the chatbot. Please try again.',
  MESSENGER_SETUP_SUCCESS: 'Your chatbot is now connected to your Facebook business page.',
  MESSENGER_SETUP_ERROR:
    'Sorry, something went wrong during Facebook Messenger integration, please try again.',
  FACEBOOK_ACCOUNT_CONNECTION_ERROR:
    'We encountered a problem connecting to your Facebook account. Please try again.',
  VERIFICATION_CODE_LOCKED_ERROR:
    'You have entered an invalid verification code five times. For security reasons, you are temporarily locked out from trying again. Please wait 10 minutes before attempting to verify your code again.',
  WHATSAPP_DUPLICATE_ERROR:
    'Sorry, it seems that this phone number is already connected to another chatbot. Please disconnect the other chatbot or add a new phone number.',
  INSTAGRAM_DUPLICATE_ERROR:
    'Sorry, it seems that this Instagram account is already connected to another chatbot. Please disconnect the other chatbot or add a new Instagram account.',
  MESSENGER_DUPLICATE_ERROR:
    'Sorry, it seems that this Facebook page is already connected to another chatbot. Please disconnect the other chatbot or add a new Facebook page.',
  IMAGE_SIZE_EXCEEDED:
    'The uploaded file exceeds the 1MB limit. Please reduce the file size by cropping the image or using a compression tool.',
  HUBSPOT_SETUP_SUCCESS: 'Your chatbot is now connected to your HubSpot Account.',
  HUBSPOT_SETUP_ERROR: 'Sorry, something went wrong while setting up HubSpot integration, please try again.',
  HUBSPOT_DUPLICATE_ERROR:
    'Sorry, it seems that this HubSpot page is already connected to another chatbot. Please disconnect the other chatbot or add a new HubSpot account.',
  ZENDESK_SETUP_SUCCESS: 'Your chatbot is now connected to your Zendesk Account.',
  ZENDESK_SETUP_ERROR: 'Sorry, something went wrong while setting up Zendesk integration, please try again.',
  ZENDESK_DUPLICATE_ERROR:
    'Sorry, it seems that this Zendesk page is already connected to another chatbot. Please disconnect the other chatbot or add a new Zendesk account.',
  DELETE_TAG: "If you wish to delete this tag, please double-click the 'X' button.",
  DELETE_SOURCE_TAG: "If you wish to remove the tag from the source, please double-click on 'X'.",
  SELECT_SOURCES_TAG:
    'If you wish to assign tags to sources, please first select sources in the table and then click the button to proceed.',
  CREATE_TAG:
    'In order to assign tags to a source, you first need to create tags. Please click on the "Tags" button to add new tags.',
  HE_PREVIEW:
    'Human escalation cannot be tested through Preview. You can test this feature using "Connect/Integrate" by accessing the link or adding the chatbot to your website.',
};
