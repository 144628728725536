/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import { Pencil, X } from 'lucide-react';
import { ChatbotLabel } from 'models/api/response.types';
import React from 'react';

const LabelList: React.FC<{
  labels: ChatbotLabel[];
  editLabel: (label: ChatbotLabel) => void;
  deleteLabel: (uuid: string) => void;
}> = ({ labels, editLabel, deleteLabel }) => {
  return (
    <div className="flex flex-col gap-2">
      {labels
        .sort((a, b) => b.created_at.localeCompare(a.created_at) * 1)
        .map((label, index) => {
          return (
            <div
              key={`${label.name}+${index}`}
              className="rounded-md border p-2 px-4 flex items-start space-x-4 transition-all overflow-hidden bg-background"
            >
              <div style={{ backgroundColor: label.color }} className="w-3 h-3 min-w-[12px] rounded-full" />
              <div className="space-y-1 flex-1 overflow-hidden">
                <p className="text-sm font-medium leading-none text-left">{label.name}</p>
                <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                  {label.criteria}
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  editLabel(label);
                }}
                className="ml-4 mt-3"
              >
                <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
              </button>
              <button type="button" onClick={() => deleteLabel(label.uuid)} className="ml-1 mt-3">
                <X
                  strokeWidth={1.75}
                  className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                />
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default LabelList;
