/* eslint-disable prefer-const */
/* eslint-disable react/no-unstable-nested-components */
import { AIAgentFunctionList } from 'models/api/response.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QueryBuilder, { RuleGroupTypeIC, RuleType } from 'react-querybuilder';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { v4 as uuidv4 } from 'uuid';
import {
  booleanVariableOperators,
  defaultAssignAgentRule,
  defaultAvoidAgentRule,
  defaultBooleanSelectors,
  defaultRuleFields,
  defaultSendMessageRule,
  numberVariableOperators,
  queriesOperators,
  ruleCombinators,
  specialRuleCombinators,
  stringVariableOperators,
} from 'utils/agent';
import { Separator } from 'components/ui/separator';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Plus, X } from 'lucide-react';
import { cn } from 'utils/cn';
import { ChatbotVariable, defaultBotInitialMessage } from 'utils/bot';

const RuleBuilder: React.FC<{
  disabled: boolean;
  query: RuleGroupTypeIC;
  agents: { name: string; uuid: string }[];
  functions: AIAgentFunctionList;
  variables: ChatbotVariable[];
  setQuery: (queryData: RuleGroupTypeIC) => void;
}> = ({ disabled, query, agents, functions, variables, setQuery }) => {
  const onQueryChange = (data: RuleGroupTypeIC) => {
    setQuery(data);
  };

  // validatior that validates certain rules
  const validator = useCallback(
    (r: RuleType): boolean => {
      const { field, value, operator } = r;
      if (field.startsWith('function_')) {
        const funcId = field.replace('function_', '');
        return functions.some((func) => func.uuid === funcId);
      }
      if (field.startsWith('variable')) {
        const variableName = field.replace('variable_', '');
        return (
          (['collected', 'not_collected'].includes(operator) || !!value) &&
          variables.some((v) => v.name === variableName)
        );
      }
      if (['next_agent_uuid', 'current_agent_uuid', 'avoid_agent_uuid'].includes(field)) {
        return !!value && agents.some((agent) => agent.uuid === value);
      }
      return true;
    },
    [agents],
  );

  // hook that watches for query changes
  // if last combinator is SEND, need to apply last rull to message_response
  // if last combinator ASSIGN, need to apply last rule to next_agent_uuid
  // if last combinator AVOID,  need to apply last rule to avoid_agent_uuid
  useEffect(() => {
    if (query?.rules) {
      const newRules = [...query.rules];
      const lastCombinator = query.rules[query.rules.length - 2] as string; // last combinator is second last item in array ALWAYS
      const lastRule = query.rules[query.rules.length - 1] as RuleType;
      // update last rule in rules array
      if (lastCombinator === 'send' && lastRule?.field !== 'message_response') {
        newRules[newRules.length - 1] = {
          ...defaultSendMessageRule,
          value: defaultBotInitialMessage,
          id: uuidv4(),
        };
        setQuery({ ...query, rules: newRules as any });
      }
      if (lastCombinator === 'assign' && lastRule?.field !== 'next_agent_uuid') {
        newRules[newRules.length - 1] = { ...defaultAssignAgentRule, value: agents[0].uuid, id: uuidv4() };
        setQuery({ ...query, rules: newRules as any });
      }
      if (lastCombinator === 'avoid' && lastRule?.field !== 'avoid_agent_uuid') {
        newRules[newRules.length - 1] = { ...defaultAvoidAgentRule, value: agents[0].uuid, id: uuidv4() };
        setQuery({ ...query, rules: newRules as any });
      }
    }
  }, [query?.rules]);

  const addNewRuleOrGroup = (q: RuleType<string, string, any, string> | RuleGroupTypeIC) => {
    const assignIndex = query.rules.length - 2; // ALWAYS add before last combinator
    let newRules = [...query.rules];
    if (assignIndex > -1) {
      newRules = [...query.rules.slice(0, assignIndex), 'and', q, ...query.rules.slice(assignIndex)];
    }
    const newQueryData = {
      ...query,
      rules: newRules as any,
    };
    setQuery(newQueryData);
  };

  const addNewRuleInGroup = (index: number) => {
    const newField: RuleType<string, string, any, string> = {
      field: 'total_user_queries',
      operator: '=',
      value: '3',
      id: uuidv4(),
    };
    const currentGroup = { ...(query.rules[index] as RuleGroupTypeIC) };
    if (currentGroup.rules.length === 0) {
      currentGroup.rules = [newField];
    } else {
      currentGroup.rules = [...currentGroup.rules, 'and', newField] as any;
    }
    const newQuery = { ...query };
    newQuery.rules = [...newQuery.rules] as any;
    newQuery.rules[index] = currentGroup;
    setQuery(newQuery);
  };

  // need to build custom options base on agent's data
  const customFieldOptions = useMemo(() => {
    const agentFields = agents.map((agent) => {
      return {
        name: `agent_${agent.uuid}`,
        defaultValue: 1,
        label: agent.name,
        operators: queriesOperators,
        validator,
      };
    });
    const functionFields = functions.map((func) => {
      return {
        name: `function_${func.uuid}`,
        defaultValue: 1,
        label: func.uuid,
        operators: queriesOperators,
        validator,
      };
    });
    const variableFields = variables.map((variable) => {
      return {
        name: `variable_${variable.name}`,
        label: variable.name,
        type: variable?.type || 'string',
        defaultValue:
          variable?.type === 'number' ? 1 : variable?.type === 'boolean' ? 'true' : 'default_value',
        validator,
        operators:
          variable?.type === 'number'
            ? numberVariableOperators
            : variable?.type === 'boolean'
              ? booleanVariableOperators
              : stringVariableOperators,
      };
    });

    return [...agentFields, ...functionFields, ...variableFields];
  }, [agents]);

  const defaultRuleFieldsOptions = useMemo(() => {
    const fields = Object.values(defaultRuleFields).flat();
    return fields.map((field) => {
      return {
        ...field,
        validator,
      };
    });
  }, [agents]);

  const groupedFieldOptions = useMemo(() => {
    const result = customFieldOptions.reduce(
      (acc, item) => {
        if (item.name.startsWith('function')) {
          acc.functions.push(item);
        } else if (item.name.startsWith('variable')) {
          acc.variables.push(item);
        } else {
          acc.agents.push(item);
        }
        return acc;
      },
      { agents: [] as any[], functions: [] as any[], variables: [] as any[] },
    );
    // filter empty results
    return Object.entries(result).reduce(
      (acc, [key, value]) => {
        if (value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {} as { [key: string]: any[] },
    );
  }, [agents]);

  return (
    <QueryBuilder
      fields={[...defaultRuleFieldsOptions, ...customFieldOptions]}
      query={query}
      combinators={ruleCombinators}
      onAddRule={(prop) => {
        addNewRuleOrGroup(prop);
        return false;
      }}
      onAddGroup={(prop) => {
        addNewRuleOrGroup(prop);
        return false;
      }}
      onQueryChange={onQueryChange}
      showCombinatorsBetweenRules
      independentCombinators
      controlClassnames={{ queryBuilder: 'queryBuilder' }}
      controlElements={{
        addRuleAction: (props) => {
          return (
            <Button
              disabled={disabled}
              onClick={(e) => {
                // for adding rule into the group separate handler
                if (props.level > 0) {
                  addNewRuleInGroup(props.path[0]);
                } else {
                  props.handleOnClick(e);
                }
              }}
              className="bg-background disabled:opacity-100 "
              size="sm"
              variant="outline"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Rule
            </Button>
          );
        },
        addGroupAction: (props) => {
          const { level } = props;
          if (level === 1) {
            return null;
          }
          return (
            <Button
              disabled={disabled}
              onClick={props.handleOnClick}
              className="bg-background disabled:opacity-100"
              size="sm"
              variant="outline"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Group
            </Button>
          );
        },
        combinatorSelector: (props) => {
          // assign && send rules can be used only in pre last path which is rule.length - 2
          const isLastPath = props.path[0] === (props?.rules?.length || 0) - 2;
          // divide combinators based on last path, last path only special combinators
          // other paths can't use them
          const options = isLastPath
            ? props.options.slice(ruleCombinators.length - specialRuleCombinators.length)
            : props.options.slice(0, ruleCombinators.length - specialRuleCombinators.length);
          return (
            <Select disabled={isLastPath} value={props.value} onValueChange={props.handleOnChange}>
              <SelectTrigger className="w-[120px] bg-background h-8 text-sm hover:bg-accent disabled:opacity-100 hover:text-accent-foreground disabled:bg-background">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {options.map((option) => {
                  const { name, label } = option as unknown as any;
                  return (
                    <SelectItem key={name} value={name}>
                      {label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          );
        },
        fieldSelector: (props) => {
          // last one only agent selector
          const isLastPath = props.path[0] === query.rules.length - 1;
          const lastField = isLastPath
            ? props.rule.field === 'next_agent_uuid'
              ? defaultRuleFields.result_rules[0]
              : props.rule.field === 'message_response'
                ? defaultRuleFields.result_rules[1]
                : defaultRuleFields.result_rules[2]
            : null;
          const generalOptions = { ...defaultRuleFields, ...groupedFieldOptions };

          return (
            <Select value={props.value} onValueChange={props.handleOnChange}>
              <SelectTrigger
                disabled={disabled || isLastPath}
                className="min-w-[260px] w-[260px] bg-background h-8 text-sm hover:bg-accent hover:text-accent-foreground disabled:opacity-100 disabled:bg-background"
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {isLastPath && lastField ? (
                  <SelectItem key={lastField.name} value={lastField.name}>
                    {lastField.label}
                  </SelectItem>
                ) : (
                  <>
                    {Object.keys(generalOptions).map((keyGroup) => {
                      //  last parameters that works only for the last step
                      if (keyGroup === 'result_rules') {
                        return null;
                      }
                      const optionLabel =
                        keyGroup === 'agents'
                          ? 'All User Facing Agents'
                          : keyGroup === 'current_agent'
                            ? 'Current User Facing Agent'
                            : keyGroup === 'functions'
                              ? 'Functions'
                              : keyGroup === 'variables'
                                ? 'Variables'
                                : 'Conversation';
                      return (
                        <SelectGroup key={keyGroup}>
                          <SelectLabel className="bg-secondary-background">{optionLabel}</SelectLabel>
                          {(generalOptions as any)[keyGroup].map((option: any) => {
                            let { name, label } = option;
                            if (name.startsWith('function')) {
                              const exitstingFunction = functions.find((func) => func.uuid === label);
                              if (exitstingFunction) {
                                label = `Total # of times ${exitstingFunction.name} is called`;
                              }
                            }

                            if (name.startsWith('variable')) {
                              const exitstingVariable = variables.find((v) => v.name === label);
                              if (exitstingVariable) {
                                label = exitstingVariable.name;
                              }
                            }

                            if (name.startsWith('agent')) {
                              const exitstingAgent = agents.find((v) => v.name === label);
                              if (exitstingAgent) {
                                label = `Total # of input queries handled by ${exitstingAgent.name}`;
                              }
                            }

                            return (
                              <SelectItem key={name} value={name}>
                                {label}
                              </SelectItem>
                            );
                          })}

                          {keyGroup !== 'variables' && (
                            <Separator className="mt-1" orientation="horizontal" />
                          )}
                        </SelectGroup>
                      );
                    })}
                  </>
                )}
              </SelectContent>
            </Select>
          );
        },
        operatorSelector: (props) => {
          let isLastAssignedRule = props.path[0] === query.rules.length - 1;
          const propsOptions = props.options as { name: string; label: string }[];
          if (!propsOptions.some((op) => op && op?.name === props?.value)) {
            props.handleOnChange(propsOptions[0].name);
          }

          return (
            <Select value={props.value} onValueChange={props.handleOnChange}>
              <SelectTrigger
                disabled={disabled || isLastAssignedRule}
                className="min-w-[150px] w-[150px] bg-background h-8 text-sm hover:bg-accent hover:text-accent-foreground disabled:opacity-100 disabled:bg-background"
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {props.options.map((option) => {
                  const { name, label } = option as unknown as any;
                  // this one needs only for getting the variable type, can't use as a value
                  if (label === 'boolean_hidden') {
                    return null;
                  }
                  return (
                    <SelectItem key={name} value={name}>
                      {label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          );
        },
        valueEditor: (props) => {
          // need value to handle re-renders of react-query builder
          const [inputValue, setInputValue] = useState<any>(props.value);
          const { field, fieldData, operator } = props;
          const isSelector = ['current_agent_uuid', 'next_agent_uuid', 'avoid_agent_uuid'].includes(field);
          const isNumberInput = fieldData?.operators?.some((op) => op.label === 'Less than or equal to');
          const isBooleanOperator = ['collected', 'not_collected'].includes(operator);
          const isBooleanValue =
            !isBooleanOperator && fieldData?.operators?.some((op) => op.label === 'boolean_hidden');
          const isTextField = !isNumberInput && !isBooleanValue && !isSelector;
          if (isBooleanOperator) {
            return null;
          }
          if (isSelector || isBooleanValue) {
            const value = isBooleanValue
              ? props.value
              : agents.some((ag) => ag.uuid === props.value)
                ? props.value
                : props.value
                  ? 'INVALID AGENT'
                  : '';

            return (
              <Select value={value} onValueChange={props.handleOnChange}>
                <SelectTrigger
                  disabled={disabled}
                  className="flex-1 bg-background h-8 text-sm hover:bg-accent hover:text-accent-foreground disabled:opacity-100 disabled:bg-background"
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {isBooleanValue ? (
                    <>
                      {defaultBooleanSelectors.map((bool) => {
                        const { name, label } = bool;
                        return (
                          <SelectItem key={name} value={label}>
                            {name}
                          </SelectItem>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {agents.map((agent) => {
                        const { name, uuid } = agent;
                        return (
                          <SelectItem key={uuid} value={uuid}>
                            {name}
                          </SelectItem>
                        );
                      })}
                      <SelectItem className="hidden" value="INVALID AGENT">
                        INVALID AGENT
                      </SelectItem>
                    </>
                  )}
                </SelectContent>
              </Select>
            );
          }
          if (isNumberInput) {
            return (
              <Input
                disabled={disabled}
                onChange={(e) => {
                  let val = parseInt(e.target.value, 10);
                  if (val > 50) {
                    val = 50;
                  }
                  setInputValue(val);
                }}
                min={1}
                max={50}
                onBlur={() => {
                  setInputValue(!inputValue ? 1 : inputValue);
                  props.handleOnChange(!inputValue ? 1 : inputValue);
                }}
                className="flex-1 h-8 disabled:opacity-100 bg-background"
                type="number"
                value={inputValue}
              />
            );
          }
          if (isTextField) {
            return (
              <Input
                disabled={disabled}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onBlur={() => {
                  props.handleOnChange(inputValue);
                }}
                className={cn(
                  'flex-1 h-8 disabled:opacity-100 bg-background',
                  !inputValue && 'border-destructive focus-visible:ring-destructive',
                )}
                type="text"
                value={inputValue}
              />
            );
          }
          return null;
        },
        removeRuleAction: (props) => {
          if (disabled || props.path[0] === query.rules.length - 1 || query.rules.length === 3) {
            return null;
          }
          return (
            <Button
              onClick={props.handleOnClick}
              className="bg-background hover:text-destructive h-8 w-8 ml-auto"
              size="icon"
              variant="outline"
            >
              <X className="h-4 w-4" />
            </Button>
          );
        },
        removeGroupAction: (props) => {
          if (props.path[0] === query.rules.length - 1 || query.rules.length === 3) {
            return null;
          }
          return (
            <Button
              onClick={props.handleOnClick}
              className="bg-background hover:text-destructive h-8 w-8"
              size="icon"
              variant="outline"
            >
              <X className="h-4 w-4" />
            </Button>
          );
        },
      }}
    />
  );
};

export default RuleBuilder;
