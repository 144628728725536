/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import ConversationChart from 'components/Chatbot/Dashboard/ConverastionChart';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { BookCopy, Bot, Download, MessageCircle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChartTime,
  getDashboardChartTime,
  getSelectedChatbot,
  setDashboardChartTime,
} from 'store/reducers/ui';
import { useQuery } from '@tanstack/react-query';
import sourceService from 'api/source';
import { Chatbot, IPopularSource } from 'models/api/response.types';
import { Skeleton } from 'components/ui/skeleton';
import { format } from 'date-fns';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import PopularSource from 'components/Chatbot/Dashboard/PopularSource';
import sessionService from 'api/session';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { exportPopularSources } from 'utils/export';
import { useAlerts } from 'providers/AlertProvider';
import useDataSources from 'hooks/useDataSources';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import { numberWithDots } from 'utils/formatting';
import { alerts } from 'utils/alert';

const calculatePercentageChange = (prev: number, current: number) => {
  if (prev === 0 && current === 0) {
    return '0%';
  }
  if (prev === 0) {
    return 'N/A';
  }

  const difference = current - prev;
  const percentageChange = ((difference / prev) * 100).toFixed(2);

  return `${parseInt(percentageChange, 10) > 0 ? '+' : ''}${percentageChange}%`;
};

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const chartRange = useSelector(getDashboardChartTime);
  const { addAlert } = useAlerts();
  const { sources } = useDataSources();
  const { chatbotSubscription, canSeeDashboard } = useSubscriptionInfo();

  const chartDashboardKey = [`sessionChart-${chartRange}`, chatbot?.uuid];

  const { data: sessionComparisonData } = useQuery({
    queryKey: ['session-comparison-data', chatbot?.uuid],
    enabled: canSeeDashboard,
    queryFn: () => sessionService.sessionDashboardComparisonData(chatbot?.uuid),
  });

  const sessionBoxData = useMemo(() => {
    if (sessionComparisonData) {
      return {
        total: sessionComparisonData.total,
        difference: calculatePercentageChange(
          sessionComparisonData.last_month,
          sessionComparisonData.this_month,
        ),
      };
    }
    return undefined;
  }, [sessionComparisonData]);

  const { data: popularSources } = useQuery({
    queryKey: ['bot-popular-sources', chatbot?.uuid],
    enabled: canSeeDashboard,
    queryFn: () => sourceService.chatbotPopularSources(chatbot?.uuid),
  });

  const { data: sessionDashboardData } = useQuery({
    queryKey: chartDashboardKey,
    enabled: canSeeDashboard,
    queryFn: () => sessionService.sessionDashboardChartData(chatbot?.uuid, chartRange),
  });

  const sourceData = useMemo(() => {
    if (sources) {
      const total = sources.length;
      const active = sources.filter((src) => src.status === 'success').length;
      const last_trained =
        sources.sort((a, b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime())[0]
          ?.modified_at || new Date();
      return {
        total,
        active,
        last_trained,
      };
    }
    return undefined;
  }, [sources]);

  const skeletonArray = [4, 3, 1, 6, 5, 5, 7];

  return (
    <div
      className={cn(
        'h-full py-4 px-4 sm:px-6 flex flex-col gap-4 relative',
        canSeeDashboard ? 'overflow-auto' : 'overflow-hidden',
      )}
    >
      <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Chatbot</CardTitle>
            <Bot strokeWidth={1.75} className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold line-clamp-1">{chatbot?.name}</div>
            <p
              className="text-xs text-muted-foreground mr-2 whitespace-nowrap line-clamp-1 cursor-pointer"
              onClick={() => {
                if ('clipboard' in navigator) {
                  navigator.clipboard.writeText(chatbot?.uuid);
                  addAlert({
                    severity: 'success',
                    message: alerts.CHATBOT_UUID_COPIED,
                  });
                }
              }}
            >
              UUID: {chatbot?.uuid}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Conversations</CardTitle>
            <MessageCircle strokeWidth={1.75} className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            {sessionComparisonData?.total !== undefined ? (
              <>
                <div className="text-2xl font-bold">{numberWithDots(sessionComparisonData?.total)}</div>
              </>
            ) : (
              <Skeleton className="h-[32px] w-[150px]" />
            )}
            {sessionBoxData?.difference && (
              <p className="text-xs text-muted-foreground">{sessionBoxData.difference} from last month</p>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Sources</CardTitle>
            <BookCopy strokeWidth={1.75} className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            {sourceData && canSeeDashboard ? (
              <>
                <div className="text-2xl font-bold">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span>{sourceData.active}</span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="font-normal">Active sources</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>{' '}
                  /{' '}
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span className="text-sm">{sourceData.total} </span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="font-normal">Total sources</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <p className="text-xs text-muted-foreground">
                  Last trained on: {format(new Date(sourceData.last_trained), 'yyyy-MM-dd HH:mm')}
                </p>
              </>
            ) : (
              <>
                <Skeleton className="w-[100px] h-[30px]" />
                <Skeleton className="w-[150px] h-[14px] mt-1" />
              </>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-7">
        <Card className="col-span-4">
          <CardHeader className="flex flex-row items-center">
            <div>
              <CardTitle>Conversations</CardTitle>
              <CardDescription className="mt-1">
                Data is sorted based on the time when the conversations were created.
              </CardDescription>
            </div>
            <Select
              disabled={!canSeeDashboard}
              value={chartRange}
              onValueChange={(value) => {
                dispatch(setDashboardChartTime(value as ChartTime));
              }}
            >
              <SelectTrigger className="w-[180px] ml-auto">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="week">Week</SelectItem>
                <SelectItem value="month">Month</SelectItem>
                <SelectItem value="3months">3 Months</SelectItem>
                <SelectItem value="6months">6 Months</SelectItem>
                <SelectItem value="year">Year</SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent className="pl-2 flex flex-col min-h-[350px]">
            {sessionComparisonData?.total === undefined || sessionComparisonData.total > 0 ? (
              <ConversationChart
                range={canSeeDashboard ? chartRange : 'week'}
                data={canSeeDashboard ? sessionDashboardData?.time_series : skeletonArray}
              />
            ) : (
              <div className="max-w-[430px] w-full my-auto mx-auto rounded-md border text-sm font-medium text-center p-1 border-warning bg-warning/10">
                <p>Oops! Looks like we don&apos;t have any data at the moment.</p>
              </div>
            )}
          </CardContent>
        </Card>
        <Card className="col-span-4 xl:col-span-3">
          <CardHeader className="flex flex-row items-center space-y-0 gap-4">
            <div className="mr-auto">
              <CardTitle>Most popular sources</CardTitle>
              <CardDescription>Most frequent sources in user chats.</CardDescription>
            </div>
            {popularSources && popularSources?.length > 0 && canSeeDashboard && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => exportPopularSources(popularSources)}
                      size="icon"
                      variant="outline"
                    >
                      <Download strokeWidth={1.75} className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="font-normal">Export data</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </CardHeader>
          <CardContent className="min-h-[300px] sm:min-h-auto max-h-[350px] overflow-y-auto h-full flex flex-col">
            {popularSources && canSeeDashboard ? (
              <>
                {popularSources.length > 0 ? (
                  <div className="space-y-6">
                    {popularSources
                      .sort((a: IPopularSource, b: IPopularSource) => b.count - a.count)
                      .map((src) => (
                        <PopularSource src={src} enabled key={src.uuid} />
                      ))}
                  </div>
                ) : (
                  <div className="max-w-[430px] w-full my-auto mx-auto rounded-md border text-sm font-medium text-center p-1 border-warning bg-warning/10">
                    <p>Oops! Looks like we don&apos;t have any data at the moment.</p>
                  </div>
                )}
              </>
            ) : (
              <div className="space-y-6">
                {skeletonArray.map((_, i) => (
                  <PopularSource key={i} />
                ))}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      {chatbotSubscription && !canSeeDashboard && (
        <div className="absolute w-full h-full top-0 left-0 backdrop-blur-md flex items-center justify-center">
          <div className="p-4 text-center bg-background rounded-md border shadow-md">
            <UpgradeRequired className="relative" heading="Upgrade to Unlock Dashboard">
              Please upgrade your subscription to access dashboard feature. Enhance your experience by gaining
              access to custom dashboards, allowing you to get more out of our platform.
            </UpgradeRequired>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
