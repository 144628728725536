/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useMemo, useState } from 'react';
import { ChatBotMeta } from 'utils/bot';
import {
  Subtitles,
  Pencil,
  MessageSquare,
  Mail,
  LinkIcon,
  AlignStartVertical,
  AlignEndVertical,
  RectangleHorizontal,
  StretchHorizontal,
  GripVertical,
  X,
} from 'lucide-react';
import { cn } from 'utils/cn';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import UpdateSuggestedPromptDialog from 'components/Dialogs/Chatbot/UpdateSuggestedPromptDialog';
import { Toggle } from 'components/ui/toggle';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import reorder from 'utils/reorder';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

const promptsTypeIcons: { [key: string]: ReactNode } = {
  query: <MessageSquare strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
  email: <Mail strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
  link: <LinkIcon strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
};

const promptsTypeNames: { [key: string]: ReactNode } = {
  query: 'User Message',
  link: 'Navigate to URL',
  email: 'Send Email',
};

type EventSelection = 'query' | 'email' | 'link';

interface SuggestedPromptsAppearanceProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const SuggestedPromptsAppearance: React.FC<SuggestedPromptsAppearanceProps> = ({
  chatInterface,
  setChatInterface,
}) => {
  const [createPromptEventSelection, setCreatePromptEventSelection] = useState<EventSelection>('query');
  const [suggestedPromptLabel, setSuggestedPromptLabel] = useState<string>('');
  const [suggestedPromptInput, setSuggestedPromptInput] = useState<string>('');
  const [preCannedResponse, setPreCannedResponse] = useState<string>('');
  const [promptToUpdate, setPromptToUpdate] = useState<number | undefined>(undefined);

  const isValidToCreate = useMemo(() => {
    return createPromptEventSelection && suggestedPromptInput && suggestedPromptLabel;
  }, [createPromptEventSelection, suggestedPromptLabel, suggestedPromptInput]);

  const createSuggestedPrompt = () => {
    setChatInterface({
      ...chatInterface,
      suggested_prompts: [
        ...chatInterface.suggested_prompts,
        {
          type: createPromptEventSelection as EventSelection,
          label: suggestedPromptLabel,
          data: suggestedPromptInput,
          pre_canned_response: createPromptEventSelection === 'query' ? preCannedResponse.trim() : '',
        },
      ],
    });
    setPreCannedResponse('');
    setCreatePromptEventSelection('query');
    setSuggestedPromptLabel('');
    setSuggestedPromptInput('');
  };

  const columnDragEnd = (response: DropResult) => {
    if (!response.destination || response.destination.index === response.source.index) return;
    const { destination, source } = response;
    const items = reorder(chatInterface.suggested_prompts, source.index, destination.index);
    setChatInterface({
      ...chatInterface,
      suggested_prompts: items,
    });
  };

  const isDragDisabled = useMemo(() => {
    return chatInterface?.suggested_prompts.length === 0;
  }, [chatInterface?.suggested_prompts]);

  const createButton = () => {
    return (
      <Button disabled={!isValidToCreate} className="w-fit ml-auto" onClick={createSuggestedPrompt}>
        Create
      </Button>
    );
  };

  return (
    <>
      <AccordionItem
        id="customizations-suggested-prompts"
        className="border bg-background rounded-md transition-all"
        value="suggested-prompts"
      >
        <AccordionTrigger className="px-6 text-left hover:no-underline">
          <div>
            <div className="flex items-centrer gap-2">
              <Subtitles strokeWidth={1.75} className="w-6 h-6" />
              Suggested Prompts
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Buttons to send pre-composed messages or perform pre-determined actions within the AI chat.
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6">
          <DragDropContext onDragEnd={columnDragEnd}>
            <Droppable droppableId="droppablePrompts" direction="vertical">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {chatInterface?.suggested_prompts?.map((prompt, index) => (
                    <Draggable
                      key={`${prompt.label}+${prompt.type}+${prompt.data}`}
                      draggableId={`${prompt.label}+${prompt.type}+${prompt.data}`}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(secondaryProvided) => (
                        <div
                          ref={secondaryProvided.innerRef}
                          {...secondaryProvided.draggableProps}
                          className="flex items-center gap-4 w-full mb-2"
                        >
                          <div className="flex-1 bg-background outline-none ml-[2px] rounded-md border p-2 -mx-2 flex items-start space-x-4 overflow-hidden">
                            {promptsTypeIcons[prompt.type]}
                            <div className="space-y-1 flex-1 overflow-hidden">
                              <p className="text-sm font-medium leading-none text-left">{prompt.label}</p>
                              <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                                {prompt.type === 'link' && (
                                  <>
                                    Navigate to:{' '}
                                    <a
                                      className="font medium text-secondary hover:underline"
                                      href={prompt.data}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {prompt.data}
                                    </a>
                                  </>
                                )}
                                {prompt.type === 'email' && (
                                  <>
                                    Send email to:{' '}
                                    <a
                                      className="font medium text-secondary hover:underline"
                                      href={`mailto:${prompt.data}`}
                                    >
                                      {prompt.data}
                                    </a>
                                  </>
                                )}
                                {prompt.type === 'query' && <>Query: {prompt.data}</>}
                              </p>
                              {prompt.type === 'query' && prompt.pre_canned_response && (
                                <p className="text-sm text-muted-foreground line-clamp-3 overflow-hidden text-ellipsis text-left">
                                  Response: {prompt.pre_canned_response}
                                </p>
                              )}
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setPromptToUpdate(index);
                              }}
                              className="ml-4 mt-3"
                            >
                              <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                const newSuggestedPrompts = [...chatInterface.suggested_prompts];
                                newSuggestedPrompts.splice(index, 1);
                                setChatInterface({
                                  ...chatInterface,
                                  suggested_prompts: newSuggestedPrompts,
                                });
                              }}
                              className="ml-1 mt-3"
                            >
                              <X
                                strokeWidth={1.75}
                                className="w-4 min-w-[16px] h-4 transition-all hover:text-destructive cursor-pointer"
                              />
                            </button>
                          </div>
                          {!isDragDisabled && (
                            <button type="button" {...secondaryProvided.dragHandleProps}>
                              <GripVertical
                                strokeWidth={1.75}
                                className="w-6 h-6 text-muted-foreground hover:text-black transition-all"
                              />
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="flex flex-col gap-2 mt-6">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="show-prompt-icons"
            >
              Show Prompt Icons
            </Label>
            <p className="text-sm text-muted-foreground">
              By selecting this option, all prompts will display associated icons.
            </p>
            <Switch
              id="show-prompt-icons"
              checked={chatInterface?.show_suggested_prompt_icons || false}
              onCheckedChange={(allowed) => {
                setChatInterface({
                  ...chatInterface,
                  show_suggested_prompt_icons: allowed,
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-6">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="prompt-view"
            >
              Alignment
            </Label>
            <div id="promp-view" className="flex flex-wrap items-center gap-2">
              <Toggle
                className="whitespace-nowrap"
                aria-label="dark"
                pressed={
                  !chatInterface?.suggested_prompts_view ||
                  chatInterface?.suggested_prompts_view === 'horizontal-single'
                }
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    suggested_prompts_view: 'horizontal-single',
                  });
                }}
              >
                <RectangleHorizontal strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Horizontal-Single
              </Toggle>
              <Toggle
                className="whitespace-nowrap"
                aria-label="dark"
                pressed={chatInterface?.suggested_prompts_view === 'horizontal-multi'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    suggested_prompts_view: 'horizontal-multi',
                  });
                }}
              >
                <StretchHorizontal strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Horizontal-Multi
              </Toggle>
              <Toggle
                className="whitespace-nowrap"
                aria-label="vertical"
                pressed={chatInterface?.suggested_prompts_view === 'vertical-start'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    suggested_prompts_view: 'vertical-start',
                  });
                }}
              >
                <AlignStartVertical strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Vertical Start
              </Toggle>
              <Toggle
                className="whitespace-nowrap"
                aria-label="vertical"
                pressed={chatInterface?.suggested_prompts_view === 'vertical-end'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    suggested_prompts_view: 'vertical-end',
                  });
                }}
              >
                <AlignEndVertical strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Vertical End
              </Toggle>
            </div>
          </div>
          <h3 className="text-md font-medium leading-none tracking-tight flex items-center mt-6">Types</h3>
          <p className="text-sm text-muted-foreground my-2">
            To get started, please choose type for suggested prompt to create.
          </p>
          <button
            type="button"
            className={cn(
              '-mx-2 w-full flex items-start space-x-4 rounded-md p-2 transition-all',
              createPromptEventSelection === 'query'
                ? 'bg-accent text-accent-foreground cursor-default'
                : 'hover:bg-accent hover:text-accent-foreground',
            )}
            onClick={() => {
              setCreatePromptEventSelection('query');
            }}
          >
            {promptsTypeIcons.query}
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none text-left">{promptsTypeNames.query}</p>
              <p className="text-sm text-muted-foreground text-left">Message is sent to the chat.</p>
            </div>
          </button>
          <button
            type="button"
            className={cn(
              '-mx-2 w-full flex items-start space-x-4 rounded-md p-2 transition-all',
              createPromptEventSelection === 'link'
                ? 'bg-accent text-accent-foreground cursor-default'
                : 'hover:bg-accent hover:text-accent-foreground',
            )}
            onClick={() => {
              setCreatePromptEventSelection('link');
            }}
          >
            {promptsTypeIcons.link}
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none text-left">{promptsTypeNames.link}</p>
              <p className="text-sm text-muted-foreground text-left">Opens specified URL.</p>
            </div>
          </button>
          <button
            type="button"
            className={cn(
              '-mx-2 w-full flex items-start space-x-4 rounded-md p-2 transition-all',
              createPromptEventSelection === 'email'
                ? 'bg-accent text-accent-foreground cursor-default'
                : 'hover:bg-accent hover:text-accent-foreground',
            )}
            onClick={() => {
              setCreatePromptEventSelection('email');
            }}
          >
            {promptsTypeIcons.email}
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none text-left">{promptsTypeNames.email}</p>
              <p className="text-sm text-muted-foreground text-left">Email client is opened.</p>
            </div>
          </button>
          <h3 className="text-md font-medium leading-none tracking-tight flex items-center mt-6">Label</h3>
          <p className="text-sm text-muted-foreground my-2">
            Text that will appear on the button for your suggested prompt.
          </p>
          <div className="flex-1 max-w-[868px]">
            <Input
              type="text"
              autoComplete="off"
              disabled={!createPromptEventSelection}
              value={suggestedPromptLabel}
              placeholder={
                createPromptEventSelection === 'link'
                  ? 'Check pricing plans'
                  : createPromptEventSelection === 'email'
                    ? 'Contact support'
                    : 'How do I get started?'
              }
              onChange={(e) => {
                setSuggestedPromptLabel(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && isValidToCreate) {
                  createSuggestedPrompt();
                }
              }}
            />
          </div>
          <h3 className="text-md font-medium leading-none tracking-tight flex items-center mt-6 mb-2">
            {createPromptEventSelection === 'link'
              ? 'URL'
              : createPromptEventSelection === 'email'
                ? 'Email'
                : !createPromptEventSelection
                  ? 'Action'
                  : 'Prompt'}
          </h3>
          <p className="text-sm text-muted-foreground mb-2">
            Action to be performed when the button is clicked.
          </p>
          <div className="flex items-start gap-4">
            <div className="flex-1">
              <Input
                type="text"
                autoComplete="off"
                disabled={!createPromptEventSelection}
                value={suggestedPromptInput}
                placeholder={
                  createPromptEventSelection === 'query'
                    ? 'How do I get started?'
                    : createPromptEventSelection === 'email'
                      ? 'support@example.com'
                      : 'https://my-custom-site.com'
                }
                onChange={(e) => {
                  setSuggestedPromptInput(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isValidToCreate) {
                    createSuggestedPrompt();
                  }
                }}
              />
            </div>
            {createPromptEventSelection !== 'query' && createButton()}
          </div>
          {createPromptEventSelection === 'query' && (
            <>
              <h3 className="text-md font-medium leading-none tracking-tight flex items-center mt-6 mb-2">
                Pre Canned Response
              </h3>
              <p className="text-sm text-muted-foreground mb-2">
                Write your own response to use as a displayed message and save credits, or leave it blank for
                AI-generated replies. You can use Markdown elements for formatting.{' '}
                <a
                  className="text-secondary font-medium hover:underline"
                  href="https://www.markdownguide.org/cheat-sheet/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </p>
              <div className="flex items-end gap-4">
                <div className="flex-1">
                  <Textarea
                    className="min-h-[100px]"
                    id="expected-response"
                    value={preCannedResponse}
                    placeholder="To get started you ..."
                    onChange={(e) => {
                      setPreCannedResponse(e.target.value);
                    }}
                  />
                </div>
                {createButton()}
              </div>
            </>
          )}
        </AccordionContent>
      </AccordionItem>
      <UpdateSuggestedPromptDialog
        promptToUpdate={promptToUpdate}
        chatInterface={chatInterface}
        setChatInterface={setChatInterface}
        close={() => {
          setPromptToUpdate(undefined);
        }}
      />
    </>
  );
};

export default SuggestedPromptsAppearance;
