import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';

const SubscriptionLimitsExceededDialog: React.FC<{
  show: boolean;
  close: () => void;
  type: 'chatbot' | 'source-chunks' | 'auto-retrain' | 'ai-agent' | 'agent-functions' | 'supervisor-settings';
}> = ({ show, close, type }) => {
  const { canUpgrade, agentFunctionsMaxCount } = useSubscriptionInfo();
  const navigate = useNavigate();

  const dialogData = useMemo(() => {
    if (type === 'chatbot') {
      return {
        title: 'Chatbot Limit Exceeded',
        message: `It looks like you've reached your current chatbot limit. To create more, please ${canUpgrade ? 'upgrade your subscription or' : ''} purchase an add-on.`,
        canUpgrade,
      };
    }
    if (type === 'source-chunks') {
      if (!canUpgrade) {
        return {
          title: 'View Content Data Unavailable',
          message: `Your plan doesn't allow to view and manage content of this source. Please contact support for more information.`,
          canUpgrade: false,
        };
      }
      return {
        title: 'Upgrade Required',
        message: `Viewing and managing content of this source is not available for your subscription plan. Please upgrade your subscription.`,
        canUpgrade: true,
      };
    }
    if (type === 'auto-retrain') {
      if (!canUpgrade) {
        return {
          title: 'Auto Re-train Unavailable',
          message: `Your plan doesn't allow to set re-train frequency. Please contact support for more information.`,
          canUpgrade: false,
        };
      }
      return {
        title: 'Upgrade Required',
        message: `Setting re-train frequency is not available for your subscription plan. Please upgrade your subscription.`,
        canUpgrade: true,
      };
    }
    if (type === 'supervisor-settings') {
      if (!canUpgrade) {
        return {
          title: 'Supervisor Settings Unavailable',
          message: `Your plan doesn't allow to access Supervisor Settings feature. Please contact support for more information.`,
          canUpgrade: false,
        };
      }
      return {
        title: 'Upgrade Required',
        message: `Please upgrade your subscription to access Supervisor Settings feature.`,
        canUpgrade: true,
      };
    }
    if (type === 'ai-agent') {
      if (!canUpgrade) {
        return {
          title: 'Maximum Agent Limit Reached',
          message: `You have reached the maximum number of agents that can be created. 
            To add new agents, please consider removing or consolidating existing ones.`,
          canUpgrade: false,
        };
      }
      return {
        title: 'Maximum Agent Limit Reached',
        message: `You have reached the maximum number of agents allowed by your current plan. 
          To create additional agents, please upgrade your subscription.`,
        canUpgrade: true,
      };
    }
    if (type === 'agent-functions') {
      if (!canUpgrade) {
        return {
          title: 'Maximum Agent Functions Limit Reached',
          message: `You have reached the maximum number of functions that can be created for this agent. 
          To add new functions, please consider removing or consolidating existing ones.`,
          canUpgrade: false,
        };
      }
      if (agentFunctionsMaxCount === 0) {
        return {
          title: 'Upgrade Required',
          message: `Functions are not available under your current subscription plan. To utilize this feature, please upgrade your subscription.`,
          canUpgrade: true,
        };
      }
      return {
        title: 'Maximum Agent Functions Limit Reached',
        message: `You have reached the maximum number of functions that can be created for this agent 
        allowed by your current plan. To create additional agents, please upgrade your subscription.`,
        canUpgrade: true,
      };
    }
    return {
      title: 'Subscription Limits Exceeded',
      message: `You have reached the limit for your subscription. Please upgrade your subscription.`,
      canUpgrade: true,
    };
  }, [type, canUpgrade]);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  {dialogData.title}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">{dialogData.message}</p>
                </div>

                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={close} variant="outline">
                    Close
                  </Button>
                  {dialogData.canUpgrade && (
                    <Button
                      onClick={() => {
                        navigate(routePaths.upgrade);
                      }}
                    >
                      Upgrade plan
                    </Button>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SubscriptionLimitsExceededDialog;
