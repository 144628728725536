/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Bookmark, PlusCircle } from 'lucide-react';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { Badge } from 'components/ui/badge';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChatbot, setSelectedChatbot } from 'store/reducers/ui';
import {
  Chatbot,
  ChatbotLabel,
  ChatbotLabelCreateBody,
  ChatbotLabelUpdateBody,
  Chatbots,
} from 'models/api/response.types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import chatbotService from 'api/chatbot';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Button } from 'components/ui/button';
import CreateEditChatbotLabelDialog from 'components/Dialogs/Chatbot/CreateEditChatbotLabelDialog';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import LabelList from 'components/helpers/ChatbotCustomizations/LabelList';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { ChatBotMeta } from 'utils/bot';
import useChatbots from 'hooks/useChatbots';

interface LabelSettingsProps {
  disabled: boolean;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const LabelSettings: React.FC<LabelSettingsProps> = ({ disabled, chatInterface, setChatInterface }) => {
  const dispatch = useDispatch();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { chatbots, setChatbotData } = useChatbots();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const { chatbotLabelLimit } = useSubscriptionInfo();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryKey = ['chatbot-labels', selectedChatbot.uuid];
  const [openLabelDialog, setOpenLabelDialog] = useState<boolean>(false);
  const [canAddLabels, setCanAddLabels] = useState<boolean>(false);
  const [labelToEdit, setLabelToEdit] = useState<undefined | ChatbotLabel>(undefined);
  const divRef = useRef<HTMLDivElement>(null);

  const { data: labels } = useQuery({
    queryKey,
    queryFn: () => chatbotService.getChatbotLabels(selectedChatbot.uuid),
    enabled: !disabled,
  });

  useEffect(() => {
    const div = divRef.current;
    if (!div) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
          const newState = div.getAttribute('data-state');
          if (newState === 'open' && chatbotLabelLimit > (labels?.length || 0)) {
            setCanAddLabels(true);
          } else {
            setCanAddLabels(false);
          }
        }
      });
    });

    observer.observe(div, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, [chatbotLabelLimit, labels]);

  // need to enable lable outside of whole chatbot flow, as it's separate flow and we don't
  // want to show usnaved changes here
  const toggleLabelFeature = (enable: boolean) => {
    const newInterface = {
      ...chatInterface,
      message_labeling_enabled: enable,
    };
    chatbotService
      .updateChatbot(chatbot.uuid, {
        meta_json: JSON.stringify(newInterface),
      })
      .then((data: Chatbot) => {
        const newChatbots = [...(chatbots as Chatbots)].map((bot) => (bot.uuid === data.uuid ? data : bot));
        setChatbotData(newChatbots);
        dispatch(setSelectedChatbot(data));
        setChatInterface(newInterface);
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      });
  };

  const deleteLabel = useMutation({
    mutationFn: (uuid: string) => chatbotService.deleteChatbotLabel(uuid),
    onMutate: (uuid: string) => {
      const labelList: ChatbotLabel[] | undefined = queryClient.getQueryData(queryKey);
      if (labelList) {
        const newLabels = labelList.filter((s) => s.uuid !== uuid);
        queryClient.setQueryData(queryKey, newLabels);
      }
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey });
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
    },
  });

  const updateLabel = useMutation({
    mutationFn: (payload: { uuid: string; label: ChatbotLabelUpdateBody }) =>
      chatbotService.updateChatbotLabel(payload.uuid, payload.label),
    onSuccess: (result) => {
      const labelList: ChatbotLabel[] | undefined = queryClient.getQueryData(queryKey);
      if (labelList) {
        const newLabels = labelList.map((s) => (s.uuid === result.uuid ? result : s));
        queryClient.setQueryData(queryKey, newLabels);
      } else {
        queryClient.invalidateQueries({ queryKey });
      }
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
    },
  });

  const createLabel = useMutation({
    mutationFn: (payload: { uuid: string; label: ChatbotLabelCreateBody }) =>
      chatbotService.createChatbotLabel(payload.uuid, payload.label),
    onSuccess: (result) => {
      const labelList: ChatbotLabel[] | undefined = queryClient.getQueryData(queryKey);
      if (labelList) {
        queryClient.setQueryData(queryKey, [result, ...labelList]);
      } else {
        queryClient.invalidateQueries({ queryKey });
      }
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
    },
  });

  return (
    <>
      <AccordionItem
        ref={divRef}
        id="customizations-labels"
        disabled={disabled}
        className="border bg-background rounded-md transition-all"
        value="labels"
      >
        {disabled && <Badge className="absolute -top-2 p-1 right-12 rounded-md">Upgrade Required</Badge>}
        <AccordionTrigger className="px-6 text-left hover:no-underline gap-4">
          <div>
            <div className="flex items-centrer gap-2">
              <Bookmark strokeWidth={1.75} className="w-6 h-6 " />
              Conversation Labeling
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Use AI to label conversations based on pre-defined criteria. Monitor and classify the
              conversation for advanced analytics.
            </p>
          </div>
          {canAddLabels && chatInterface?.message_labeling_enabled && (
            <Button
              className="ml-auto"
              onClick={(e) => {
                e.stopPropagation();
                setOpenLabelDialog(true);
              }}
            >
              <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
              Add label
            </Button>
          )}
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight"
              htmlFor="toggle-chatbot-labels"
            >
              Enable Chat Labeling
            </Label>
            <p className="text-muted-foreground text-sm">
              By enabling this feature, the chatbot will track conversations and assign labels to each session
              based on predefined criteria.
            </p>
            <Switch
              id="toggle-chatbot-labels"
              checked={!!chatInterface?.message_labeling_enabled}
              onCheckedChange={toggleLabelFeature}
            />
          </div>
          {chatInterface?.message_labeling_enabled && (
            <>
              {labels && labels.length > 0 ? (
                <LabelList
                  labels={labels}
                  editLabel={(label: ChatbotLabel) => {
                    setLabelToEdit(label);
                    setOpenLabelDialog(true);
                  }}
                  deleteLabel={(uuid: string) => deleteLabel.mutate(uuid)}
                />
              ) : (
                <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
                  No existing labels
                </Badge>
              )}
            </>
          )}
        </AccordionContent>
      </AccordionItem>
      {openLabelDialog && (
        <CreateEditChatbotLabelDialog
          labelToEdit={labelToEdit}
          currentLabels={labels || []}
          close={(label?: ChatbotLabelCreateBody | ChatbotLabelUpdateBody) => {
            if (label) {
              if (labelToEdit) {
                updateLabel.mutate({ uuid: labelToEdit.uuid, label });
              } else {
                createLabel.mutate({ uuid: selectedChatbot.uuid, label });
              }
            } else {
              setLabelToEdit(undefined);
            }
            setOpenLabelDialog(false);
          }}
        />
      )}
    </>
  );
};

export default LabelSettings;
