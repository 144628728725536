/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { Contact } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import ChatbotFormBuilder from 'components/helpers/ChatbotCustomizations/ChatbotFormBuilder';

interface FormProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  setShowForm: (show: boolean) => void;
}

const FormSettings: React.FC<FormProps> = ({ setShowForm, chatInterface, setChatInterface }) => {
  const { appTitle } = useWhitelabelData();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;
    if (!div) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
          const newState = div.getAttribute('data-state');
          if (newState === 'open' && chatInterface?.form?.fields?.some((field) => field.enabled)) {
            setShowForm(true);
          } else {
            setShowForm(false);
          }
        }
      });
    });

    observer.observe(div, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, [chatInterface?.form?.fields]);

  const updateChatbotMeta = (inter: ChatBotMeta) => {
    setChatInterface(inter);
    if (inter?.form?.fields.filter((field) => field.enabled).length === 0) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <AccordionItem
        ref={divRef}
        id="customizations-form"
        className="border bg-background rounded-md transition-all"
        value="form"
      >
        <AccordionTrigger className="px-6 text-left hover:no-underline">
          <div>
            <div className="flex items-centrer gap-2">
              <Contact strokeWidth={1.75} className="w-6 h-6" />
              Form
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Use a custom form to collect data from users.
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
          <div className="text-muted-foreground">
            As your users/customers interact with your chatbot, you can collect valuable user information for
            various purposes with {appTitle}&apos;s built-in data collection form, which will appear within
            the chat interface.
            <span className="text-xs mt-1 text-warning block">
              Note: Form is non-functional in Preview Mode.
            </span>
          </div>
          <ChatbotFormBuilder chatInterface={chatInterface} setChatInterface={updateChatbotMeta} />
        </AccordionContent>
      </AccordionItem>
    </>
  );
};

export default FormSettings;
