import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import ChatBubbleIntegration from './ChatBubbleIntegration';
import IframeIntegaration from './IframeIntegration';

const WixIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img className="w-[40px] h-[40px] rounded-md" src="/img/integrations/wix/wix.png" alt="Wix" />
          <h1 className="text-xl font-semibold">Add to Wix</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6" defaultValue="iframe">
        <TabsList>
          <TabsTrigger value="iframe">Add to Page</TabsTrigger>
          <TabsTrigger value="bubble">Chat Button</TabsTrigger>
        </TabsList>
        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
          In order to experience the fully functional capabilities of the chatbot within your development
          environment, we recommend enabling the <strong>&quot;Allow all domains&quot;</strong> option within
          the Chatbot settings. This will allow you to explore and test the chatbot&apos;s functionalities
          seamlessly. Feel free to disable this feature once your website is ready for deployment.
        </div>
        <TabsContent value="iframe">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Enter <strong>&quot;Edit mode&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/iframe-1.png" alt="Enter Edit mode" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Click <strong>&quot;Add Elements&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/iframe-2.png" alt="Add elements" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Click <strong>&quot;Embed code&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/iframe-3.png" alt="Embed code" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4. Click <strong>&quot;Embed HTML&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/iframe-4.png" alt="Embed HTML" />
          <p className="font-normal text-muted-foreground text-base my-4">5. Paste our code to form:</p>
          <IframeIntegaration isWix />
          <img className="mt-4" src="/img/integrations/wix/iframe-5.png" alt="Paste the code" />
        </TabsContent>
        <TabsContent value="bubble">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Open <strong>&quot;Settings&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/widget-1.png" alt="Open settings" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Click <strong>&quot;Custom code&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/widget-2.png" alt="Click Custom code" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Click <strong>&quot;Add custom code&quot;</strong>.
          </p>
          <img src="/img/integrations/wix/widget-3.png" alt="Add Custom code" />
          <p className="font-normal text-muted-foreground text-base my-4">4. Paste our code to form:</p>
          <ChatBubbleIntegration />
          <img className="mt-4" src="/img/integrations/wix/widget-4.png" alt="Paste code to form" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default WixIntegration;
