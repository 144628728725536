/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import sessionService from 'api/session';
import axios from 'axios';
import {
  ChatSession,
  Chatbot,
  ChatMessages,
  ChatMessage as ChatMessageType,
  RealtimeChatMessage as RealtimeChatMessageType,
  WhiteLabelData,
} from 'models/api/response.types';
import { useLocation, useParams } from 'react-router-dom';
import {
  ChatBotMeta,
  generateChatBotMessageColors,
  generateChatMainColors,
  generateUserMessageColors,
  chatSizeVariants,
  ThemeType,
  poweredByClass,
} from 'utils/bot';
import { cn } from 'utils/cn';
import { isDomainValid } from 'utils/domain';
import { isSessionStorageAvailable, isValidJson } from 'utils/sessions';
import BotInvalidSetup from 'components/Widget/BotInvalidSetup';
import ChatInput from 'components/helpers/chat/ChatInput';
import ChatStreamingResponse from 'components/helpers/chat/StreamingResponse/ChatStreamingResponse';
import ChatMessage from 'components/helpers/chat/ChatMessage/ChatMessage';
import ChatInitialMessages from 'components/helpers/chat/ChatInitialMessages';
import ChatHeader from 'components/helpers/chat/ChatHeader';
import chatbotService from 'api/chatbot';
import ChatSuggestedPrompts from 'components/helpers/chat/ChatSuggestedPrompts';
import ChatScrollContainer from 'components/helpers/chat/ChatScrollContainer';
import { QueryToStream, defaultQueryToStream } from 'models/api/chat.types';
import {
  canRemoveAppBranding,
  canSeeChatbotProfilePicture,
  canUseCustomBrandWatermark,
  canUseUserIdentity,
} from 'utils/plans';
import useSocket from 'hooks/useSocket';
import RealtimeChatMessage from 'components/helpers/chat/ChatMessage/RealtimeChatMessage';
import { createRealTimeMessage, isChatMessage, scrollIfAtBottom, scrollToBottom } from 'utils/chat';
import { addCookie, deleteSessionCookie, findCookieByKey } from 'utils/cookies';
import WidgetForm, { FormData } from 'components/helpers/chat/WidgetForm';
import WidgetDisclaimer from 'components/helpers/chat/WidgetDisclaimer';

const Widget: React.FC = () => {
  const params = useParams();
  const chatbotId = params?.id || '';
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const [chatbot, setChatbot] = useState<Chatbot | undefined>(undefined);
  const [currentSession, setCurrentSession] = useState<ChatSession | undefined>(undefined);
  const [chatMessages, setChatMessages] = useState<ChatMessages | undefined>(undefined);
  const [messageAwaitingOnSM, setMessageAwaitingOnSM] = useState<QueryToStream>(defaultQueryToStream);
  const [queryToStream, setQueryToStream] = useState<QueryToStream>(defaultQueryToStream);
  const isWidget = urlSearchParams.get('widget') || '';
  const isWixIntegration = urlSearchParams.get('wix') || '';
  const token = urlSearchParams.get('token') || '';
  const [streamingResponse, setStreamingResponse] = useState<string>('');
  const [setUpError, setSetUpError] = useState<string>('');
  const [defaultTheme, setDefaultTheme] = useState<string>(urlSearchParams.get('theme') || '');
  const [formData, setFormData] = useState<FormData | undefined>(undefined);
  const [appData, setAppData] = useState<WhiteLabelData['app'] | undefined>(undefined);
  const [formDataAwaitsForSubmission, setFormDataAwaitsForSubmission] = useState<boolean>(false);
  const [authenticatedData, setAuthenticatedData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [authenticationCompleted, setAuthenticationCompleted] = useState<boolean>(true);
  const [sessionSearchCompleted, setSessionSearchCompleted] = useState<boolean>(false);
  const [canUseHumanEscalation, setCanUseHumanEscalation] = useState<boolean>(false);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [streaming, setStreaming] = useState<boolean>(false);
  const [isEmbedded, setIsEmbedded] = useState<boolean>(false);
  const [appDomain] = useState(window.location.hostname);
  const parentDomain = document?.referrer ? new URL(document.referrer).hostname : undefined;
  const cookieSessionKey = `${appDomain}-chatbot-${chatbotId}-session=`;
  const cookieDisclaimerKey = `${appDomain}-chatbot-${chatbotId}-disclaimer=`;
  const sessionFormSubmissionKey = `${appDomain}-chatbot-${chatbotId}-form`;

  useEffect(() => {
    chatbotService
      .getChatbotById(chatbotId)
      .then((bot) => {
        const metadata: ChatBotMeta = JSON.parse(bot.meta_json);
        setDefaultTheme(metadata.theme);
        let botActive = true;
        // trial check
        if (bot?.usage_limits?.limited_trial && bot?.user_created_at) {
          const trialDays = bot?.usage_limits?.trial_days || 0;
          const userCreationTime = new Date(bot?.user_created_at);
          const trialEndDate = new Date(userCreationTime);
          trialEndDate.setDate(userCreationTime.getDate() + trialDays);
          if (new Date() > trialEndDate) {
            botActive = false;
          }
        }
        // check if chatbot is active
        if (!bot.active) {
          botActive = false;
        }

        if (!botActive) {
          setSetUpError('Your subscription has expired. Please renew to continue chatting.');
          return;
        }

        if (parentDomain) {
          setIsEmbedded(true);
        }

        // add || window.location.hostname === "localhost" to see in local env
        if (
          (parentDomain &&
            !metadata?.all_domains_allowed &&
            isDomainValid(parentDomain, metadata.domains, !!isWixIntegration)) ||
          (parentDomain && metadata?.all_domains_allowed) ||
          (!parentDomain && metadata.visibility === 'public')
        ) {
          if (metadata?.webhook_auth?.enabled && bot?.usage_limits && canUseUserIdentity(bot.usage_limits)) {
            setAuthenticationCompleted(false);
          }
          // if human escalation enabled
          if (bot?.usage_limits?.human_escalation_enabled) {
            setCanUseHumanEscalation(true);
          }

          // if disclaimer enabled
          if (metadata?.disclaimer?.enabled) {
            const isDisclaimerAccepted = findCookieByKey(cookieDisclaimerKey);
            if (!isDisclaimerAccepted) {
              setShowDisclaimer(true);
            }
          }

          // Parent domain is valid, perform necessary actions
          setAppData(bot?.app);
          setChatbot(bot);
        } else if (parentDomain) {
          setSetUpError('No allowed domains were found.');
        } else {
          setSetUpError('Link is not available.');
        }
      })
      .catch(() => {
        setSetUpError('Permissions are restricted, or the ID for the Chatbot is invalid.');
      });
  }, []);

  // getting all necessary data from session
  useEffect(() => {
    if (isSessionStorageAvailable()) {
      const savedFormData = sessionStorage.getItem(sessionFormSubmissionKey);
      if (savedFormData) {
        setFormData(JSON.parse(savedFormData));
      }
    }
  }, []);

  // SOCKET CONNECTION
  const { subscribeToEvent, emitEvent } = useSocket(canUseHumanEscalation);

  useEffect(() => {
    const subscribeStatus = subscribeToEvent?.(
      'human_escalation_status_change',
      (status: ChatSession['human_escalation_status']) => {
        if (currentSession) {
          // for HE we need to preserve session even if it's disabled,
          // otherwise user will lose the ability to chat with admin
          addCookie(`${cookieSessionKey}${currentSession.uuid}`, 1);
          setCurrentSession((curreSession) =>
            curreSession ? { ...curreSession, human_escalation_status: status } : undefined,
          );
        }
      },
    );

    const announcementMessage = subscribeToEvent?.('announcement', (message: RealtimeChatMessageType) => {
      setChatMessages((prev) => (prev ? [...prev, message] : [message]));
      scrollIfAtBottom(100);
    });

    const subscribeMessage = subscribeToEvent?.(
      'message',
      (message: ChatMessageType | RealtimeChatMessageType) => {
        if (!isChatMessage(message) && message.type !== 'user') {
          setChatMessages((prev) => (prev ? [...prev, message] : [message]));
          scrollIfAtBottom(100);
        }
      },
    );

    return () => {
      subscribeStatus?.();
      subscribeMessage?.();
      announcementMessage?.();
    };
  }, [subscribeToEvent, currentSession]);

  useEffect(() => {
    if (currentSession) {
      emitEvent?.('join_chat', currentSession.uuid);
    }
  }, [currentSession, canUseHumanEscalation]);

  // parsed object for chatbot metadata
  const chatbotMetadata = useMemo(() => {
    if (chatbot) {
      return JSON.parse(chatbot.meta_json) as ChatBotMeta;
    }
    return undefined;
  }, [chatbot]);

  useEffect(() => {
    if (chatbotMetadata) {
      // if chatbot doesn't use default Inter font, load font and display it
      if (chatbotMetadata?.font_style && chatbotMetadata?.font_style !== 'Inter') {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${chatbotMetadata?.font_style?.replace('"', '')}:wght@300;400;500;600&display=swap`;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
        return () => {
          document.head.removeChild(link);
        };
      }
    }
  }, [chatbotMetadata]);

  const { canSeeProfilePicture, canUseWatermark, canRemoveGPTMark } = useMemo(() => {
    if (chatbot?.usage_limits) {
      return {
        canSeeProfilePicture: canSeeChatbotProfilePicture(chatbot.usage_limits),
        canUseWatermark: canUseCustomBrandWatermark(chatbot.usage_limits),
        canRemoveGPTMark: canRemoveAppBranding(chatbot.usage_limits),
      };
    }

    return {
      canSeeProfilePicture: false,
      canUseWatermark: false,
    };
  }, [chatbot, chatbotMetadata]);

  const showWatermark = useMemo(() => {
    return !canRemoveGPTMark || (canUseWatermark && !!chatbotMetadata?.custom_watermark?.label);
  }, [canRemoveGPTMark, chatbotMetadata]);

  // initial scroll
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const container = document.getElementById('chat-container');
      if (container) {
        container.scrollTop = container.scrollHeight;
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  // chat history search
  useEffect(() => {
    if (chatbotMetadata && !sessionSearchCompleted) {
      const sessionId = findCookieByKey(cookieSessionKey)?.split('=')[1];

      if (sessionId) {
        sessionService
          .getSessionById(sessionId)
          .then((data) => {
            setCurrentSession(data);
            setSessionSearchCompleted(true);
          })
          .catch(() => {
            // can't find session, allow user to start new session
            deleteSessionCookie(cookieSessionKey, sessionId);
            setSessionSearchCompleted(true);
          });
      } else {
        setSessionSearchCompleted(true);
      }
    }
  }, [chatbotMetadata, sessionSearchCompleted]);

  // authentication validation
  useEffect(() => {
    if (chatbotMetadata && !authenticationCompleted) {
      if (token) {
        chatbotService
          .authenticateCustomerUser(chatbotId, token)
          .then((data) => {
            if (chatbotMetadata?.webhook_auth?.save_response && isValidJson(data)) {
              setAuthenticatedData(data);
            }
            setAuthenticationCompleted(true);
          })
          .catch(() => {
            setSetUpError(
              chatbotMetadata?.webhook_auth?.error_message || 'Authentication failed. Invalid user.',
            );
          });
      } else {
        setSetUpError(chatbotMetadata?.webhook_auth?.error_message || 'Authentication failed. Invalid user.');
      }
    }
  }, [chatbotMetadata, authenticationCompleted]);

  // logic for widget to show form based on certain scenarios
  // some chatbots won't have the form, only new
  // 1. can't show if disclaimer is visible
  // 2. only when everything fully rendered
  // 3. when no HE enabled (live chatting)
  // 4. when no streaming messages
  // 5. when no existing form data
  const showForm = useMemo(() => {
    if (chatbotMetadata) {
      const { form } = chatbotMetadata;
      return (
        !!form &&
        !!chatbotMetadata &&
        !showDisclaimer &&
        currentSession?.human_escalation_status !== 'active' &&
        !streaming &&
        !formData &&
        form.fields.some((field) => field.enabled) &&
        (form.show_after_qa === 0 ||
          (!!chatMessages &&
            chatMessages.length - chatbotMetadata.initial_messages.length === form.show_after_qa))
      );
    }
    return false;
  }, [chatMessages, chatbotMetadata, showDisclaimer, currentSession, streaming, formData]);

  // if curentSession exists generate messages
  useEffect(() => {
    if (currentSession) {
      sessionService.getSessionMessages(currentSession.uuid).then((data) => {
        setChatMessages(data);
      });
    }
  }, [currentSession]);

  // reset - fresh chat
  const chatReset = () => {
    if (currentSession) {
      emitEvent?.('leave_chat', currentSession.uuid);
    }
    // find and reset cookie if exists
    deleteSessionCookie(cookieSessionKey, currentSession?.uuid);
    setCurrentSession(undefined);
    setChatMessages(undefined);
    setMessageAwaitingOnSM(defaultQueryToStream);
  };

  // refetch messages on streaming completion
  const refetchChatMessages = (uuid: string) => {
    sessionService.getSessionMessages(uuid).then((data) => {
      setQueryToStream(defaultQueryToStream);
      setStreaming(false);
      setStreamingResponse('');
      setChatMessages(data);
      scrollIfAtBottom(0);
    });
  };

  const updateChatboSession = (data: { [key: string]: any }) => {
    if (currentSession) {
      // update session to mark it as unread
      sessionService
        .updateChatbotSession(currentSession.uuid, {
          meta_json: JSON.stringify(data),
        })
        .then((session) => setCurrentSession(session));
    }
  };

  const createNewSession = (initialData?: { [key: string]: any }) => {
    const disclaimerAcceptedTime = findCookieByKey(cookieDisclaimerKey)?.split('=')[1];
    sessionService
      .createChatbotSession((chatbot as Chatbot).uuid, {
        // use authenticated data as context
        ...(authenticatedData &&
          chatbotMetadata?.webhook_auth?.use_response_as_context && {
            initial_variables: authenticatedData,
          }),
        meta_json: JSON.stringify({
          origin: isEmbedded ? 'widget-iframe' : 'link',
          read: false,
          chatbot_meta: chatbotMetadata,
          // if widget embedded to domain attach current parent domain
          ...(isEmbedded &&
            parentDomain && {
              parent_domain: parentDomain,
            }),
          // when customr submits the form data from session
          // only if data has any keys
          ...(formData &&
            Object.keys(formData).length > 0 && {
              form_data: formData,
            }),
          // user authentication data from user identity verification feature
          ...(authenticatedData && {
            auth_data: authenticatedData,
          }),
          // pass accepted disclaimer time if exists
          ...(disclaimerAcceptedTime && {
            disclaimer_accepted_at: disclaimerAcceptedTime,
          }),
          ...initialData,
        }),
      })
      .then((data) => {
        if (chatbotMetadata?.chat_history?.enabled) {
          addCookie(`${cookieSessionKey}${data.uuid}`, chatbotMetadata?.chat_history?.time || 1);
        }
        setCurrentSession(data);
      });
  };

  const finishFormFlow = (data?: FormData) => {
    const result = data || {};
    setFormData(result);
    if (isSessionStorageAvailable()) {
      sessionStorage.setItem(sessionFormSubmissionKey, JSON.stringify(result));
    }
    if (data) {
      setFormDataAwaitsForSubmission(true);
      if (currentSession) {
        updateChatboSession({ form_data: data });
      } else {
        createNewSession({ form_data: data });
      }
    }
  };

  // stream messages
  useEffect(() => {
    if (queryToStream.value !== '' && !streaming && currentSession) {
      setStreaming(true);
      scrollToBottom(300);
      setStreamingResponse('');
      const { pre_canned_response, value } = queryToStream;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${axios.defaults.baseURL}/api/session/${currentSession.uuid}/stream-message`, true);
      xhr.onprogress = () => {
        // stream response only if it's selected within chatbot meta
        if (!chatbotMetadata?.ai_full_response_style) {
          setStreamingResponse(xhr.responseText);
        }
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setStreamingResponse(xhr.responseText); // final message
            refetchChatMessages(currentSession.uuid);
          } else {
            refetchChatMessages(currentSession.uuid);
          }
        }
      };
      xhr.setRequestHeader('Authorization', `${axios.defaults.headers.common.Authorization}`);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(JSON.stringify({ query: value, ...(pre_canned_response && { pre_canned_response }) }));
    }
  }, [queryToStream, streaming, currentSession]);

  useEffect(() => {
    if (chatMessages && messageAwaitingOnSM.value) {
      setQueryToStream(messageAwaitingOnSM);
      setMessageAwaitingOnSM(defaultQueryToStream);
    }
  }, [chatMessages]);

  // if curent session exists and form data await for submission, send data through api
  useEffect(() => {
    if (currentSession && formData && formDataAwaitsForSubmission) {
      setFormDataAwaitsForSubmission(false);
      chatbotService
        .submitUserForm(chatbotId, {
          ...formData,
          session_uuid: currentSession.uuid,
        })
        .catch(() => {
          // handle axios error
        });
    }
  }, [currentSession, formData, formDataAwaitsForSubmission]);

  // if new chat, currentSession doesn't exist
  // 1. create new session and assign to chat
  // 2. otherwise straight call streaming
  const generateAIMessage = (message: QueryToStream) => {
    // first check if currentSession exists if no create one
    if (currentSession) {
      setQueryToStream(message);
    } else {
      setMessageAwaitingOnSM(message);
      createNewSession();
    }
  };

  // when user updated meta_json or any other filed of single message
  const updateChatMessage = (newMessage: ChatMessageType) => {
    if (chatMessages) {
      setChatMessages(
        [...chatMessages].map((message) => (message.uuid === newMessage.uuid ? newMessage : message)),
      );
    }
  };

  // generate message in HE mode
  const generateRealTimeMessage = (messageValue: string) => {
    const message = createRealTimeMessage('user', messageValue);
    emitEvent?.('message', message);
    setChatMessages((prev) => (prev ? [...prev, message] : [message]));
    scrollToBottom(300);
  };

  const { chatMainColors, userMessageColors, chatBotMessageColors, size } = useMemo(() => {
    return {
      chatMainColors: generateChatMainColors(chatbotMetadata?.theme),
      userMessageColors: generateUserMessageColors(chatbotMetadata),
      chatBotMessageColors: generateChatBotMessageColors(chatbotMetadata),
      size: chatbotMetadata?.chat_size || 'md',
    };
  }, [chatbotMetadata]);

  const isChatDisabled = useMemo(() => {
    return streaming || showDisclaimer || showForm;
  }, [streaming, showDisclaimer, showForm]);

  if (setUpError) {
    return <BotInvalidSetup theme={defaultTheme as ThemeType} error={setUpError} />;
  }

  if (!chatbot || !chatbotMetadata || !authenticationCompleted || !sessionSearchCompleted) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full flex flex-col',
        parentDomain && !isWidget ? 'border-[1px] rounded-[0.75rem]' : '',
        chatbotMetadata.theme === 'dark' ? 'dark-theme' : '',
      )}
      style={{
        background: chatMainColors.background,
        borderColor: chatMainColors.border,
        fontFamily: chatbotMetadata?.font_style || '',
      }}
    >
      <ChatHeader
        chatId={chatbot.uuid}
        size={size}
        metadata={chatbotMetadata}
        canSeeProfilePicture={canSeeProfilePicture}
        chatReset={chatReset}
        chatMainColors={chatMainColors}
        isWidget={isWidget}
        isActionsDisabled={streaming}
      />
      <ChatScrollContainer
        streaming={streaming}
        streamingResponse={streamingResponse}
        className={cn(
          'flex-1 flex flex-col overflow-auto overflow-x-hidden relative',
          chatSizeVariants.chat.container[size],
        )}
      >
        {!chatMessages && (
          <ChatInitialMessages
            chatbotMeta={chatbotMetadata}
            userMessageColors={userMessageColors}
            size={size}
            initialMessages={chatbotMetadata.initial_messages}
            chatBotMessageColors={chatBotMessageColors}
          />
        )}
        {chatMessages?.map((message, index) => {
          const isAIChatMessage = isChatMessage(message);
          const isInitialMessage = index + 1 <= chatbotMetadata.initial_messages.length;
          const isFirstInitialMessage = isInitialMessage && index === 0;

          // handle style for real time messages if next message is from the same person
          const isPreviousSameType =
            !isAIChatMessage && message?.type === (chatMessages[index - 1] as RealtimeChatMessageType)?.type;
          const isNextSameType =
            !isAIChatMessage && message?.type === (chatMessages[index + 1] as RealtimeChatMessageType)?.type;

          return isAIChatMessage ? (
            <ChatMessage
              size={size}
              isInitialMessage={isInitialMessage}
              isFirstInitialMessage={isFirstInitialMessage}
              key={message.uuid}
              message={message}
              chatbotMeta={chatbotMetadata}
              userMessageColors={userMessageColors}
              chatBotMessageColors={chatBotMessageColors}
              chatMainColors={chatMainColors}
              updateMessage={updateChatMessage}
            />
          ) : (
            <RealtimeChatMessage
              size={size}
              key={message.uuid}
              message={message}
              chatbotMeta={chatbotMetadata}
              isPreviousSameType={isPreviousSameType}
              isNextSameType={isNextSameType}
              userMessageColors={userMessageColors}
              chatBotMessageColors={chatBotMessageColors}
              chatMainColors={chatMainColors}
            />
          );
        })}
        {streaming && (
          <ChatStreamingResponse
            size={size}
            chatbotMeta={chatbotMetadata}
            userMessageColors={userMessageColors}
            chatBotMessageColors={chatBotMessageColors}
            queryToStream={queryToStream}
            streamingResponse={streamingResponse}
          />
        )}
      </ChatScrollContainer>
      {chatbotMetadata.suggested_prompts.length > 0 &&
        currentSession?.human_escalation_status !== 'active' && (
          <ChatSuggestedPrompts
            botMeta={chatbotMetadata}
            size={size}
            disabled={isChatDisabled}
            suggestedPrompts={chatbotMetadata.suggested_prompts}
            chatMainColors={chatMainColors}
            generateMessage={generateAIMessage}
            canShowIcons={chatbotMetadata?.show_suggested_prompt_icons}
            hidden={showDisclaimer || showForm}
          />
        )}
      <ChatInput
        size={size}
        disabled={isChatDisabled}
        placeholder={chatbotMetadata?.textfield_placeholder}
        inputLength={chatbotMetadata?.input_length}
        generateMessage={(message: QueryToStream) => {
          if (currentSession?.human_escalation_status === 'active') {
            generateRealTimeMessage(message.value);
          } else {
            generateAIMessage(message);
          }
        }}
        chatMainColors={chatMainColors}
        showWatermark={showWatermark}
        hidden={showDisclaimer || showForm}
      />
      {showForm && (
        <WidgetForm
          chatMessagesExists={!!chatMessages}
          chatbotMeta={chatbotMetadata}
          size={size}
          chatMainColors={chatMainColors}
          userMessageColors={userMessageColors}
          hide={finishFormFlow}
        />
      )}
      {showDisclaimer && (
        <WidgetDisclaimer
          cookieDisclaimerKey={cookieDisclaimerKey}
          chatbotMeta={chatbotMetadata}
          size={size}
          chatMainColors={chatMainColors}
          userMessageColors={userMessageColors}
          hide={() => setShowDisclaimer(false)}
        />
      )}
      {showWatermark && (
        <button
          type="button"
          className={cn(
            'p-2 border-t transition-all outline-none rounded-b-[0.75rem] flex items-center justify-center',
            chatSizeVariants.poweredByText[size],
            poweredByClass(
              chatbotMetadata.theme,
              !canRemoveGPTMark || !!chatbotMetadata?.custom_watermark?.url,
            ),
          )}
          style={{
            borderColor: chatMainColors.border,
          }}
          onClick={() => {
            if (!canRemoveGPTMark) {
              if (appData?.website_url) {
                window.open(appData?.website_url, '_blank');
              }
            } else if (chatbotMetadata?.custom_watermark?.url) {
              window.open(chatbotMetadata?.custom_watermark?.url, '_blank');
            }
          }}
        >
          {canRemoveGPTMark ? (
            <>
              {chatbotMetadata?.custom_watermark?.logo && (
                <img
                  className={cn('rounded-full', chatSizeVariants.poweredByLogo[size])}
                  src={chatbotMetadata?.custom_watermark?.logo}
                  alt="Watermark logo"
                />
              )}
              {chatbotMetadata?.custom_watermark?.label}
            </>
          ) : (
            <>
              Powered by&nbsp;<span className="font-semibold">{appData?.name}</span>
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default Widget;
