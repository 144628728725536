import React, { useRef, useState } from 'react';
import { ChatBotMeta } from 'utils/bot';
import { Loader2, PanelBottom, Image as IconImage, UploadIcon } from 'lucide-react';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Button } from 'components/ui/button';
import contentService from 'api/content';
import { Chatbot, FileResponseData } from 'models/api/response.types';
import { useSelector } from 'react-redux';
import useChatbots from 'hooks/useChatbots';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';
import { useAlerts } from 'providers/AlertProvider';
import ImageCropperDialog from 'components/Dialogs/ImageCropperDialog';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { Badge } from 'components/ui/badge';
import { cn } from 'utils/cn';

interface WatermarkAppearanceProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const WatermarkAppearance: React.FC<WatermarkAppearanceProps> = ({ chatInterface, setChatInterface }) => {
  const { addAlert } = useAlerts();
  const { updateChatbotByUUID } = useChatbots();
  const { mainWebsite } = useWhitelabelData();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { canUseCustomWatermark } = useSubscriptionInfo();
  const watermarkLogoRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageToUpload, setImageToUpload] = useState<File | undefined>(undefined);

  const updateWatermarkLogo = (file: File) => {
    setLoading(true);
    contentService.uploadChatbotFile(
      chatbot.uuid,
      file,
      'chatbot_watermark',
      (response?: FileResponseData) => {
        if (response) {
          const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
          updateChatbotByUUID(chatbot.uuid, {
            ...chatbot,
            meta_json: JSON.stringify({
              ...meta,
              custom_watermark: { ...meta?.custom_watermark, logo: response.url },
            }),
            // need for img rerendering
            modified_at: new Date().toISOString(),
          });
          setChatInterface({
            ...chatInterface,
            custom_watermark: {
              ...chatInterface?.custom_watermark,
              logo: response.url,
            },
          });
        }
        setLoading(false);
      },
    );
  };

  const deleteWatermarkLogo = () => {
    contentService.deleteChatbotFile(chatbot.uuid, 'chatbot_watermark').then(() => {
      const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
      updateChatbotByUUID(chatbot.uuid, {
        ...chatbot,
        meta_json: JSON.stringify({ ...meta, custom_watermark: { ...meta?.custom_watermark, logo: '' } }),
      });
      setChatInterface({
        ...chatInterface,
        custom_watermark: {
          ...chatInterface?.custom_watermark,
          logo: '',
        },
      });
    });
  };

  return (
    <>
      <AccordionItem
        id="customizations-watermark"
        disabled={!canUseCustomWatermark}
        className="border bg-background rounded-md transition-all relative"
        value="watermark"
      >
        {!canUseCustomWatermark && (
          <Badge className="absolute -top-2 p-1 right-12 rounded-md">Upgrade Required</Badge>
        )}
        <AccordionTrigger
          className={cn('px-6 tetx-left hover:no-underline', !canUseCustomWatermark && 'opacity-40')}
        >
          <div>
            <div className="flex items-centrer gap-2">
              <PanelBottom strokeWidth={1.75} className="w-6 h-6" />
              Watermark
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Specify custom watermark to reflect your unique identity.
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="watermark-logo"
            >
              Logo
            </Label>
            <p className="text-sm text-muted-foreground">
              Upload your watermark logo to brand the chatbot interface, clearly identifying your organization
              as the provider.
            </p>
            <div className="flex items-center gap-2">
              <div
                id="watermark-logo-container"
                className="w-[50px] bg-background h-[50px] relative rounded-full border-[1px] flex items-center justify-center"
              >
                {!chatInterface?.custom_watermark?.logo ? (
                  <IconImage strokeWidth={1.75} className="w-6 h-6" />
                ) : (
                  <img
                    className="w-[50px] h-[50px] rounded-full"
                    src={`${chatInterface.custom_watermark.logo}?v=${chatbot.modified_at}`}
                    alt="Watermark Logo"
                  />
                )}
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <Button
                    disabled={!canUseCustomWatermark || loading}
                    size="sm"
                    variant="outline"
                    className="bg-background"
                    onClick={() => {
                      if (!loading) {
                        watermarkLogoRef?.current?.click();
                      }
                    }}
                  >
                    {loading ? (
                      <Loader2 className="mr-2 w-4 h-4 animate-spin transition-all" />
                    ) : (
                      <UploadIcon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                    )}
                    Upload
                  </Button>
                  <Button
                    disabled={!chatInterface?.custom_watermark?.logo}
                    size="sm"
                    variant="ghost"
                    onClick={deleteWatermarkLogo}
                  >
                    Remove
                  </Button>
                </div>
                <p className="text-muted-foreground text-xs mt-1">
                  Supports JPG, PNG, and SVG files up to 1MB
                </p>
              </div>
            </div>
            <Input
              className="hidden"
              accept="image/png, image/jpg, image/svg+xml"
              ref={watermarkLogoRef}
              id="profile-picture"
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  if (file.size <= 1000000) {
                    setImageToUpload(file);
                    e.target.value = '';
                  } else {
                    addAlert({
                      severity: 'error',
                      message: alerts.IMAGE_SIZE_EXCEEDED,
                    });
                  }
                }
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="watermark-label"
            >
              Label
            </Label>
            <p className="text-sm text-muted-foreground">
              Company logo, name, or any other text or symbol you wish to use as a watermark.
            </p>
            <Input
              id="watermark-label"
              type="text"
              disabled={!canUseCustomWatermark}
              value={chatInterface?.custom_watermark?.label}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  custom_watermark: {
                    ...chatInterface?.custom_watermark,
                    label: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="watermark-url"
            >
              URL
            </Label>
            <p className="text-sm text-muted-foreground">
              Provide the URL where the watermark will navigate users.
            </p>
            <Input
              id="watermark-url"
              type="text"
              placeholder={mainWebsite}
              disabled={!canUseCustomWatermark}
              value={chatInterface?.custom_watermark?.url}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  custom_watermark: {
                    ...chatInterface?.custom_watermark,
                    url: e.target.value,
                  },
                });
              }}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
      <ImageCropperDialog
        title="Watermark Logo"
        img={imageToUpload}
        cropperCallback={(croppedImage?: File) => {
          if (croppedImage) {
            updateWatermarkLogo(croppedImage);
          }
          setImageToUpload(undefined);
        }}
      />
    </>
  );
};

export default WatermarkAppearance;
