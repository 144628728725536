import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { ArrowRight } from 'lucide-react';
import ChatBubbleIntegration from './ChatBubbleIntegration';
import IframeIntegaration from './IframeIntegration';

const WordpressIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/wordpress/wordpress.jpg"
            alt="Wordpress"
          />
          <h1 className="text-xl font-semibold">Add to Wordpress</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6" defaultValue="iframe">
        <TabsList>
          <TabsTrigger value="iframe">Add to Page</TabsTrigger>
          <TabsTrigger value="bubble-manual">Chat Button (Manually)</TabsTrigger>
          <TabsTrigger value="bubble-plugin">Chat Button (Plugin)</TabsTrigger>
        </TabsList>
        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
          In order to experience the fully functional capabilities of the chatbot within your development
          environment, we recommend enabling the <strong>&quot;Allow all domains&quot;</strong> option within
          the Chatbot settings. This will allow you to explore and test the chatbot&apos;s functionalities
          seamlessly. Feel free to disable this feature once your website is ready for deployment.
        </div>
        <TabsContent value="iframe">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Go to <strong>&quot;Visit Site&quot;</strong>.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-1.png" alt="Go to Visit Site" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Click on <strong>&quot;Edit site&quot;</strong>.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-2.png" alt="Edit site" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Click on <strong>&quot;Add a block +&quot;</strong> button.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-3.png" alt="Add a block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4. Select <strong>&quot;Blocks&quot;</strong> tab and choose{' '}
            <strong>&quot;Custom HTML&quot;</strong> from the list.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-4.png" alt="Custom HTML" />
          <p className="font-normal text-muted-foreground text-base my-4">
            5. Copy and paste our code to HTML block and click <strong>&quot;Save&quot;</strong>.
          </p>
          <IframeIntegaration />
          <div className="my-4 text-sm py-2 px-4 bg-blue-50 border border-blue-200 rounded-md">
            You can change the height value (e.g., 800px for taller or 300px for shorter) to fit your design.
            Experiment until it looks perfect.
          </div>
          <img src="/img/integrations/wordpress/iframe-5.png" alt="Paste the code" />
        </TabsContent>
        <TabsContent value="bubble-manual">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Go to <strong>&quot;Visit Site&quot;</strong>.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-1.png" alt="Go to Visit Site" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Click on <strong>&quot;Edit site&quot;</strong>.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-2.png" alt="Edit site" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Click on <strong>&quot;Add a block +&quot;</strong> button.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-3.png" alt="Add a block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4. Select <strong>&quot;Blocks&quot;</strong> tab and choose{' '}
            <strong>&quot;Custom HTML&quot;</strong> from the list.
          </p>
          <img src="/img/integrations/wordpress/iframe-widget-4.png" alt="Custom HTML" />
          <p className="font-normal text-muted-foreground text-base my-4">
            5. Copy and paste our code to HTML block and click <strong>&quot;Save&quot;</strong>.
          </p>
          <ChatBubbleIntegration />
          <img className="mt-4" src="/img/integrations/wordpress/widget-5.png" alt="Paste code to form" />
        </TabsContent>
        <TabsContent className="space-y-2" value="bubble-plugin">
          <h1 className="font-bold">Easy setup with our official plugin</h1>
          <p className="text-sm text-muted-foreground">
            We offer a plugin that simplifies the installation process and helps you set up everything with
            ease. Click the button below to visit our plugin page for more information and installation
            instructions.
          </p>
          <Button
            onClick={() => window.open('https://wordpress.com/plugins/gpt-trainer', '_blank')}
            className="w-fit"
          >
            Go to Plugin Page <ArrowRight className="ml-2 h-4 w-4" strokeWidth={1.75} />
          </Button>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default WordpressIntegration;
