/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useMemo, useState } from 'react';
import { Label } from 'components/ui/label';
import { CheckIcon, ChevronDown, MessagesSquare } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Switch } from 'components/ui/switch';
import moment from 'moment-timezone';
import { Input } from 'components/ui/input';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface Timezone {
  name: string;
  utcOffset: string;
}

interface Conversations extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const SettingsConversations: React.FC<Conversations> = ({ chatInterface, setChatInterface }) => {
  const { sourceTrackingEnabled } = useSubscriptionInfo();
  const [timeZoneQuery, setTimeZoneQuery] = useState<string>('');

  const timeZones: Timezone[] = useMemo(() => {
    return moment.tz.names().map((timezone) => {
      const offsetMinutes = moment().tz(timezone).utcOffset();
      const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
      const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
      const offsetSign = offsetMinutes >= 0 ? '+' : '-';
      const utcOffset = `UTC${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutesRemainder).padStart(2, '0')}`;
      return {
        name: timezone.replace(/_/g, ' '),
        utcOffset,
      };
    });
  }, []);

  const timeZonesToShow = useMemo(() => {
    if (timeZoneQuery) {
      return timeZones.filter((timezone) =>
        timezone.name.toLowerCase().includes(timeZoneQuery.toLowerCase()),
      );
    }
    return timeZones;
  }, [timeZoneQuery]);

  return (
    <AccordionItem
      id="customizations-conversations"
      className="border bg-background rounded-md transition-all"
      value="conversations"
    >
      <AccordionTrigger className="px-6 text-left hover:no-underline">
        <div>
          <div className="flex items-centrer gap-2">
            <MessagesSquare strokeWidth={1.75} className="w-6 h-6" />
            Conversations
          </div>
          <p className="text-xs text-muted-foreground ml-8">
            Time zone, caching, response scoring, and source tracking.
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="chat-history">
            Chatbot Time Zone
          </Label>
          <p className="text-muted-foreground text-sm">
            Chatbot can understand and provide the current time based on the specified timezone for accurate
            scheduling, event reminders, and time-related queries. This ensures that all time references are
            correctly aligned with specified timezone.
          </p>
          <Combobox
            value={chatInterface?.time_zone?.replace(/_/g, ' ') || 'UTC'}
            onChange={(value: string) => {
              setChatInterface({
                ...chatInterface,
                time_zone: value?.replace(/ /g, '_'),
              });
            }}
          >
            <div id="time_zone" className="relative">
              <ComboboxInput
                placeholder="UTC"
                className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                displayValue={() => chatInterface?.time_zone?.replace(/_/g, ' ') || 'UTC'}
                onChange={(e) => {
                  setTimeZoneQuery(e.target.value);
                }}
              />
              <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDown strokeWidth={1.75} className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ComboboxOptions
                  modal
                  className="absolute w-full z-[50] px-1 mt-1 max-h-60 overflow-auto rounded-md bg-background py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {timeZonesToShow.length === 0 ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      No available time zone.
                    </div>
                  ) : (
                    timeZonesToShow.map((timezone) => {
                      const { name, utcOffset } = timezone;
                      const isSelected =
                        chatInterface?.time_zone?.replace(/_/g, ' ') === name || name === 'UTC';
                      return (
                        <ComboboxOption
                          key={name}
                          className="relative flex hover:bg-accent cursor-pointer w-full select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-5"
                          value={name}
                        >
                          <span className="block truncate ml-2">
                            {name}
                            <span className="ml-2 text-xs text-muted-foreground">{utcOffset}</span>
                          </span>
                          {isSelected && (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                              <CheckIcon
                                strokeWidth={1.75}
                                className="h-4 w-4 text-success"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </ComboboxOption>
                      );
                    })
                  )}
                </ComboboxOptions>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="chat-history">
            Chat Session Caching
          </Label>
          <p className="text-muted-foreground text-sm">
            Cache user chat sessions in so they can continue where they left off the next time they visit the
            chatbot. If disabled, each new chat starts afresh.
          </p>
          <Switch
            id="chat-history"
            checked={chatInterface?.chat_history?.enabled || false}
            onCheckedChange={(enable) => {
              setChatInterface({
                ...chatInterface,
                chat_history: {
                  ...chatInterface?.chat_history,
                  enabled: enable,
                },
              });
            }}
          />
        </div>
        {chatInterface?.chat_history?.enabled && (
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="duration">
              History Storage Duration
            </Label>
            <p className="text-muted-foreground text-sm">
              Set the time frame for how long your conversations are stored in cache.
            </p>
            <div className="gap-2 flex items-center flex-wrap text-sm">
              <span className="text-muted-foreground">Duration of</span>
              <Input
                className="w-20 inline-block"
                type="number"
                value={chatInterface?.chat_history?.time || 1}
                min={1}
                max={4}
                onChange={(e) => {
                  const int = parseInt(e.target.value, 10);
                  setChatInterface({
                    ...chatInterface,
                    chat_history: {
                      ...chatInterface?.chat_history,
                      time: int > 4 ? 4 : int,
                    },
                  });
                }}
              />
              <span className="text-muted-foreground">week(s)</span>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="secondary-actions">
            Response Actions
          </Label>
          <p className="text-muted-foreground text-sm">
            Activate this feature to add scoring and copy actions under each response. Users can score the
            answer or copy it with a single click.
          </p>
          <Switch
            id="secondary-actions"
            checked={chatInterface?.response_actions_enabled || false}
            onCheckedChange={(enable) => {
              setChatInterface({
                ...chatInterface,
                response_actions_enabled: enable,
              });
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="in-text-citations">
            In-text Citations
          </Label>
          <p className="text-muted-foreground text-sm">
            Our AI bot credits sources and includes links to specific information it used from your trained
            data, promoting a reliable and informed conversation.
          </p>
          {sourceTrackingEnabled ? (
            <Switch
              id="in-text-citations"
              checked={chatInterface?.show_citations || false}
              onCheckedChange={(show) => {
                setChatInterface({
                  ...chatInterface,
                  show_citations: show,
                });
              }}
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Switch className="bg-input" id="in-text-citations" disabled />
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    This feature is not available with your current plan. Upgrade your plan to access this
                    customization option.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="source-access">
            Source Access
          </Label>
          <p className="text-muted-foreground text-sm">
            When a source document or website is referenced in a chatbot response, allow users to access and
            download the referenced document or access the URL.
          </p>
          {sourceTrackingEnabled ? (
            <Switch
              id="source-access"
              checked={chatInterface?.access_source_data || false}
              onCheckedChange={(allow) => {
                setChatInterface({
                  ...chatInterface,
                  access_source_data: allow,
                });
              }}
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Switch className="bg-input" id="source-access" disabled />
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    This feature is not available with your current plan. Upgrade your plan to access this
                    customization option.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default SettingsConversations;
