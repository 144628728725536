import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import {
  BrainCircuit,
  Cable,
  Eye,
  FileSpreadsheet,
  FunctionSquare,
  LocateFixed,
  MoreHorizontal,
  Play,
  RefreshCcw,
  Shield,
  Trash2,
  Unplug,
} from 'lucide-react';
import React, { useMemo } from 'react';
import { useAlerts } from 'providers/AlertProvider';
import { Badge } from 'components/ui/badge';
import { cn } from 'utils/cn';
import { useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import { AIAgent } from 'models/api/response.types';
import { AIModels, AgentModel, aiModelMessageCreditsLimit } from 'utils/agent';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';
import { alerts } from 'utils/alert';
import StatusDot from 'components/helpers/StatusDot';

const AgentCard: React.FC<{
  agent: AIAgent;
  selectAgent: () => void;
  deleteAgent: () => void;
}> = ({ agent, selectAgent, deleteAgent }) => {
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { agents, updateAgent } = useAiAgents();

  const {
    name,
    description,
    enabled,
    active,
    type,
    meta_json,
    data_source_uuids,
    embedding_status,
    tool_functions,
  } = agent;

  const {
    aiModel,
    allSourcesEnabled,
    functionsCount,
    isSuccess,
    isFailed,
    canDeleteDisable,
    isPreCanned,
    isSpamDefense,
    isUserFacing,
  } = useMemo(() => {
    const { model, use_all_sources } = JSON.parse(meta_json);
    const activeFacingAgents =
      agents?.filter((ag) => ag.type === 'user-facing' && !!ag.active && !!ag.enabled) || [];
    const isAlwaysActive = agent.type === 'user-facing' && (agents?.length || 1) === 1;

    return {
      isPreCanned: agent.type === 'pre-canned',
      isSpamDefense: agent.type === 'spam-defense',
      isUserFacing: agent.type === 'user-facing',
      aiModel: model as AgentModel,
      allSourcesEnabled: use_all_sources,
      functionsCount: tool_functions.length,
      isSuccess: isAlwaysActive || embedding_status === 'success',
      isFailed: !isAlwaysActive && embedding_status === 'error',
      canDeleteDisable:
        type !== 'user-facing' ||
        activeFacingAgents.length - (!!agent.active && !!agent.enabled ? 1 : 0) >= 1,
    };
  }, [agent, agents]);

  const selectedModelCredits = useMemo(() => {
    return aiModelMessageCreditsLimit[aiModel];
  }, [aiModel]);

  const sendErrorMessage = (message: string) => {
    addAlert({
      severity: 'error',
      message,
      timeout: 5000,
    });
  };

  const triggerDeletion = () => {
    if (canDeleteDisable) {
      deleteAgent();
    } else {
      sendErrorMessage(alerts.SINGLE_AGENT_DELETE_ERROR);
    }
  };

  const triggerRetrain = () => {
    agentService.updateAIAgent(agent.uuid, { retrain: true }).then((res) => {
      updateAgent(res);
    });
  };

  const triggerAgentEnabling = () => {
    if (enabled && !canDeleteDisable) {
      sendErrorMessage(
        `Cannot disable this agent as it is the only active and enabled User Facing agent. Please create a new active and enabled User Facing agent before attempting to disable this one`,
      );
    } else {
      agentService.updateAIAgent(agent.uuid, { enabled: !enabled ? 1 : 0 }).then((res) => {
        updateAgent(res);
      });
    }
  };

  return (
    <Card
      id={`agent-${agent.uuid}`}
      className={cn(
        `flex group flex-col border transition-all overflow-hidden relative`,
        active && 'cursor-pointer hover:border-primary hover:shadow-sm',
      )}
      onClick={() => {
        if (active) {
          selectAgent();
        }
      }}
    >
      {!active && <div className="absolute h-full w-full left-0 top-[52px] opacity-50 bg-background z-10" />}
      <CardHeader className="flex flex-row items-center gap-2 space-y-0 p-4 pb-0">
        <StatusDot
          status={!enabled || !active ? 'offline' : isSuccess ? 'success' : isFailed ? 'error' : 'loading'}
        />
        <CardTitle className="line-clamp-1 leading-normal">{name}</CardTitle>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="px-2 py-0 shadow-none ml-auto h-8 opacity-0 group-hover:opacity-100"
            >
              <MoreHorizontal strokeWidth={1.75} className="h-4 w-4 text-secondary-foreground" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" forceMount>
            {!!active && (
              <>
                {isFailed && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      triggerRetrain();
                    }}
                  >
                    <RefreshCcw strokeWidth={1.75} className="mr-2 h-4 w-4" /> Retrain
                  </DropdownMenuItem>
                )}
                {!!enabled && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`${routePaths.preview}?agent_uuid=${agent.uuid}`);
                    }}
                  >
                    <Play strokeWidth={1.75} className="mr-2 h-4 w-4" /> Live Test
                  </DropdownMenuItem>
                )}
                {enabled ? (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      triggerAgentEnabling();
                    }}
                  >
                    <Unplug strokeWidth={1.75} className="mr-2 h-4 w-4" /> Disable
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      triggerAgentEnabling();
                    }}
                  >
                    <Cable strokeWidth={1.75} className="mr-2 h-4 w-4" /> Connect
                  </DropdownMenuItem>
                )}
              </>
            )}
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                triggerDeletion();
              }}
              className="focus:text-destructive focus:bg-destructive/10"
            >
              <Trash2 strokeWidth={1.75} className="mr-2 h-4 w-4" /> Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent className="p-4 flex-1 flex flex-col relative">
        <CardDescription className="line-clamp-3 mb-4">{description}</CardDescription>
        <div className="flex items-center gap-2 mt-auto whitespace-nowrap flex-wrap">
          {isUserFacing ? (
            <>
              <Badge
                variant="outline"
                className={cn(
                  'font-medium px-2 py-1 rounded-md',
                  !enabled ? 'bg-slate-50 border-slate-500' : 'bg-green-50 border-green-600',
                )}
              >
                {!enabled ? (
                  <Unplug className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                ) : (
                  <Eye className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                )}
                {!enabled ? 'Disabled' : 'User Facing'}
              </Badge>
              <Badge variant="outline" className="font-medium px-2 py-1 rounded-md">
                <BrainCircuit className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                {AIModels[aiModel]}, {selectedModelCredits} mc/message
              </Badge>
              {(allSourcesEnabled || data_source_uuids.length > 0) && (
                <Badge variant="outline" className="font-medium px-2 py-1 rounded-md">
                  <FileSpreadsheet className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                  {allSourcesEnabled ? 'All Sources' : `${data_source_uuids.length} Sources`}
                </Badge>
              )}
              {functionsCount > 0 && (
                <Badge variant="outline" className="font-medium px-2 py-1 rounded-md">
                  <FunctionSquare className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                  {functionsCount} Functions
                </Badge>
              )}
            </>
          ) : (
            <>
              {isSpamDefense && (
                <Badge
                  variant="outline"
                  className="font-medium px-2 py-1 rounded-md bg-destructive/5 border-destructive"
                >
                  <Shield className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                  Spam Defense
                </Badge>
              )}
              {isPreCanned && (
                <Badge
                  variant="outline"
                  className="font-medium px-2 py-1 rounded-md bg-yellow-50 border-yellow-500"
                >
                  <LocateFixed className="w-[14px] h-[14px] mr-2" strokeWidth={1.75} />
                  Fixed Response
                </Badge>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AgentCard;
