import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Label } from 'components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from 'components/ui/select';
import { Slider } from 'components/ui/slider';
import { Textarea } from 'components/ui/textarea';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Asterisk, Check, ChevronsUpDown } from 'lucide-react';
import { AIAgentEditData, AgentTokenDistribution } from 'models/api/response.types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AIModels,
  AgentModel,
  agentTokenDistribution,
  aiModelMessageCreditsLimit,
  functionCallingDisabledModels,
  aiModelCharacterLimit,
} from 'utils/agent';
import { cn } from 'utils/cn';

type GroupedAIModels = {
  [key: string]: { [subKey: string]: AgentModel[] };
};

const getPlatformKey = (item: string): string => {
  switch (true) {
    case item.startsWith('gpt'):
      return 'openai';
    case item.startsWith('claude'):
      return 'anthropic';
    case item.startsWith('gemini'):
      return 'gemini';
    default:
      return '';
  }
};

const UserFacingOptions: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  const { isGPT4Enabled, maxAIModelSize } = useSubscriptionInfo();
  const [promptError, setPromptError] = useState<boolean>(false);

  const promptCharacterLimit = useMemo(() => {
    return aiModelCharacterLimit[data.meta.model];
  }, [data.meta.model]);

  useEffect(() => {
    const { prompt } = data;
    if (prompt.length === 0) {
      setPromptError(true);
    } else if (promptError) {
      setPromptError(false);
    }
  }, [data]);

  useEffect(() => {
    if (promptCharacterLimit < data.prompt.length) {
      setPromptError(true);
    } else if (promptError && data.prompt.length > 0) {
      setPromptError(false);
    }
  }, [promptCharacterLimit, data.prompt]);

  const groupedAIModelsToShow: GroupedAIModels = useMemo(() => {
    return Object.keys(AIModels).reduce(
      (acc: GroupedAIModels, item) => {
        const platform = getPlatformKey(item);

        // if licensee doesn't have api key for platform, return empty platform
        if (!data?.llm_platforms?.includes(platform as any)) return acc;

        if (item.startsWith('gpt')) {
          if (!acc.GPT) {
            acc.GPT = {
              'GPT-3.5': [],
              'GPT-4': [],
              'GPT-4o': [],
              'GPT-4o-mini': [],
            };
          }
          if (item.startsWith('gpt-3.5')) {
            acc.GPT['GPT-3.5'].push(item as AgentModel);
          } else if (item.startsWith('gpt-4o')) {
            if (item.includes('mini')) {
              acc.GPT['GPT-4o-mini'].push(item as AgentModel);
            } else {
              acc.GPT['GPT-4o'].push(item as AgentModel);
            }
          } else {
            acc.GPT['GPT-4'].push(item as AgentModel);
          }
        } else if (item.startsWith('gemini')) {
          if (!acc.Gemini) {
            acc.Gemini = {
              'Gemini 1.5 Flash': [],
              'Gemini 1.5 Pro': [],
            };
          }
          if (item.includes('flash')) {
            acc.Gemini['Gemini 1.5 Flash'].push(item as AgentModel);
          } else {
            acc.Gemini['Gemini 1.5 Pro'].push(item as AgentModel);
          }
        } else if (item.startsWith('claude')) {
          if (!acc.Claude) {
            acc.Claude = {
              'Claude 3.5 Sonnet': [],
              'Claude 3 Opus': [],
              'Claude 3 Haiku': [],
            };
          }
          if (item.includes('sonnet')) {
            acc.Claude['Claude 3.5 Sonnet'].push(item as AgentModel);
          } else if (item.includes('opus')) {
            acc.Claude['Claude 3 Opus'].push(item as AgentModel);
          } else {
            acc.Claude['Claude 3 Haiku'].push(item as AgentModel);
          }
        }

        return acc;
      },
      {
        GPT: {
          'GPT-3.5': [],
          'GPT-4': [],
          'GPT-4o': [],
          'GPT-4o-mini': [],
        },
        Claude: {
          'Claude 3.5 Sonnet': [],
          'Claude 3 Opus': [],
          'Claude 3 Haiku': [],
        },
        Gemini: {
          'Gemini 1.5 Flash': [],
          'Gemini 1.5 Pro': [],
        },
      },
    );
  }, [data?.llm_platforms, data?.type]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="agent-model">
          Model{' '}
        </Label>
        <p className="text-sm text-muted-foreground">
          Choose the large language model (LLM) to power your Agent.
        </p>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="max-w-[300px] hover:bg-transparent font-normal flex items-center justify-between"
              variant="outline"
            >
              <p className="text-left">
                {AIModels[data.meta.model]}
                <span className="text-muted-foreground ml-2 text-xs font-medium">
                  {aiModelMessageCreditsLimit[data.meta.model]} mc/message
                </span>
              </p>
              <ChevronsUpDown strokeWidth={1.75} className="h-4 w-4 min-w-[16px] opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[300px]">
            {Object.keys(groupedAIModelsToShow).map((item) => {
              const image =
                item === 'GPT'
                  ? '/img/open-ai-logo.svg'
                  : item === 'Gemini'
                    ? '/img/gemini.webp'
                    : '/img/claude.webp';
              // return null if models doesn't supported;
              if (Object.values(groupedAIModelsToShow[item]).every((model) => model.length === 0))
                return null;

              return (
                <span key={item}>
                  <DropdownMenuLabel className="flex items-center gap-2">
                    <img className="w-6 h-6" src={image} alt={item} />
                    {item}
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {Object.keys(groupedAIModelsToShow[item]).map((key) => {
                    // if no models allowed in specifi model section, return null for it
                    if (!groupedAIModelsToShow[item][key].length) return null;
                    // section disabled based on GPT-4 enabled property
                    // GPT-4 disabled allows users to use gpt-3.5 , gpt-4o-mini, claude - haiku, gemini - flash
                    const isDisabled =
                      !isGPT4Enabled &&
                      !['GPT-3.5', 'GPT-4o-mini', 'Claude 3 Haiku', 'Gemini 1.5 Flash'].includes(key);

                    return (
                      <DropdownMenuSub key={key}>
                        <DropdownMenuSubTrigger
                          className={cn(isDisabled && 'opacity-30')}
                          disabled={isDisabled}
                        >
                          {key}
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent className="w-[300px]">
                            {groupedAIModelsToShow[item][key].map((model) => {
                              const isSelected = model === data.meta.model;
                              const isModelDisabled =
                                maxAIModelSize < aiModelCharacterLimit[model as AgentModel];
                              return (
                                <DropdownMenuItem
                                  disabled={isModelDisabled}
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      meta: {
                                        ...data.meta,
                                        model: model as AgentModel,
                                      },
                                    });
                                  }}
                                  key={model}
                                  className="flex items-center gap-2 justify-between"
                                >
                                  <span>
                                    {AIModels[model]}
                                    <span className="text-muted-foreground ml-2 text-xs font-medium">
                                      {aiModelMessageCreditsLimit[model]} mc/message
                                    </span>
                                  </span>
                                  {isSelected && (
                                    <Check strokeWidth={1.75} className="h-4 w-4 text-success" />
                                  )}
                                </DropdownMenuItem>
                              );
                            })}
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    );
                  })}
                  <DropdownMenuSeparator />
                </span>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {data.type === 'user-facing' && (
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="token-distribution">
            Token Limit Distribution
          </Label>
          <p className="text-sm text-muted-foreground">
            Set how many tokens you wish to reserve for each part of the LLM call. The overall token limit
            depends on the LLM you picked, but you can fine tune the token distribution based on your
            particular use case.
          </p>
          <Select
            value={data?.meta?.token_distribution || 'default'}
            onValueChange={(val: AgentTokenDistribution) => {
              setData({
                ...data,
                meta: {
                  ...data.meta,
                  token_distribution: val,
                },
              });
            }}
          >
            <SelectTrigger className="max-w-[300px]">
              <p>{agentTokenDistribution[data?.meta?.token_distribution || 'default'].title}</p>
            </SelectTrigger>
            <SelectContent className="max-w-[300px]">
              <SelectGroup>
                {Object.keys(agentTokenDistribution).map((tokenDistribution) => {
                  // 2k models doesn't work with function calling
                  // need to disabled function distribution model for them
                  const isDisabled =
                    functionCallingDisabledModels.includes(data?.meta?.model) &&
                    tokenDistribution === 'max_function_calling';
                  return (
                    <SelectItem disabled={isDisabled} key={tokenDistribution} value={tokenDistribution}>
                      <p>{agentTokenDistribution[tokenDistribution as AgentTokenDistribution].title}</p>
                      <p className="text-xs text-muted-foreground">
                        {agentTokenDistribution[tokenDistribution as AgentTokenDistribution].description}
                      </p>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
          {functionCallingDisabledModels.includes(data?.meta?.model) &&
            data?.meta?.token_distribution === 'max_function_calling' && (
              <p className="ml-1 text-xs text-warning">
                2k models don&apos;t support function calling, agent will use the default token distribution
                option instead.
              </p>
            )}
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex items-end">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="prompt">
            Prompt
            <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
          </Label>
        </div>
        <p className="text-sm text-muted-foreground">
          A prompt acts as the instruction that shapes the AI&apos;s responses. The prompt&apos;s
          effectiveness hinges on its clarity and specificity, so be as explicit as possible when crafting it.
        </p>
        <Textarea
          id="prompt"
          className="min-h-[230px]"
          value={data.prompt}
          rows={3}
          maxLength={promptCharacterLimit}
          placeholder="Enter the prompt"
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setPromptError(true);
            } else if (promptError && promptCharacterLimit < data.prompt.length) {
              setPromptError(false);
            }
            setData({
              ...data,
              prompt: e.target.value,
            });
          }}
        />
        <p
          className={cn(
            'text-xs text-muted-foreground ml-1',
            promptError && data.prompt.length > 0 ? 'text-destructive' : 'text-muted-foreground',
          )}
        >
          Characters: {data.prompt.length} / {promptCharacterLimit}
        </p>
        {promptError && (
          <p className="ml-1 text-xs text-destructive">
            {data.prompt.length > 0
              ? "Your prompt exceeds model limits. Shorten, split, or change the model. Extra characters won't be processed by AI."
              : "Prompt is required field and can't be empty."}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-2 mb-8">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="temperature">
          Temperature: {data.meta.temperature}
        </Label>
        <p className="text-sm text-muted-foreground">
          Adjust this setting to influence the unpredictability of the AI&apos;s responses. A lower
          temperature results in more consistent and predictable replies, while a higher setting encourages
          creativity and variation in the agent&apos;s answers.
        </p>
        <Slider
          id="temperature"
          className="mt-2"
          value={[data.meta.temperature]}
          max={1}
          step={0.1}
          onValueChange={(value) => {
            setData({
              ...data,
              meta: {
                ...data.meta,
                temperature: value[0],
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default UserFacingOptions;
