/* eslint-disable prefer-template */
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { Button } from 'components/ui/button';
import { isMobile } from 'react-device-detect';
import routePaths from 'routes/routePaths';
import { useNavigate } from 'react-router-dom';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Badge } from 'components/ui/badge';
import { isPrimaryProduct } from 'utils/domain';
import StatusDot from 'components/helpers/StatusDot';
import { DataRow } from 'utils/sources';
import { sourceRetrainLoadingStatuses } from './SourceDetailsDrawer';

export const sourceLoadingStatuses = [
  'assigned',
  'await',
  'embedding',
  'running',
  'chunking',
  'downloading',
  'queued',
  'extracting',
];

export const sourceErrorStatuses = [
  'error',
  'fail',
  'error:not found',
  'error:no text',
  'error:download',
  'error:content type',
  'error:robots txt',
  'error:storage',
  'error:token',
  'error:insufficient credits',
  'error:extraction',
  'error:rate limit',
  'error:scraper rate limit',
  'error:image upload limit',
];

const errors: { [key: string]: string } = {
  'error:scraper rate limit':
    'You have reached the maximum allowed URL extractions for today. Please retry this URL tomorrow.',
  'error:insufficient credits':
    'Insufficient Feature Credits available to train this source. To continue, please enhance your Feature Credits amount',
  'error:no text_video':
    'Failed to transcribe text from the video. Please check the video URL and try again.',
  'error:no text': "Can't extract text from the source.",
  'error:not found':
    'The requested content could not be retrieved from the provided source. It may no longer be available or the source may be incorrect. Please verify the source and try again.',
  'error:download':
    'Unable to download website content: the site may use dynamic loading, have access restrictions, or be experiencing issues.',
  'error:content type':
    'We are unable to extract content from the provided URL due to an unsupported content type.',
  'error:storage': 'Your storage limit has been exceeded.',
  'error:token': 'Your token limit has been exceeded.',
  uknown: 'Error processing data source. The issue may be temporary or require manual review.',
};

export const sourceFinalStates = [...sourceErrorStatuses, 'success'];

const SourceStatus: React.FC<{
  source: DataRow;
  retryDataSource: (uuid: string) => void;
}> = ({ source, retryDataSource }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot);
  const navigate = useNavigate();
  const { uuid, status, canRetry, retrain_status } = source;
  const [proceedForward, setProceedForward] = useState<boolean>(!isMobile);

  const canUpgradePlan = useMemo(() => {
    return chatbot && !chatbot.permissions;
  }, [chatbot]);

  const { isStorageLimit, isTokenLimit, isDownloadFailed, isRetraining, currentStatus } = useMemo(() => {
    const isDownloadFailure = status === 'error:download';
    const errorStatus = isDownloadFailure || !sourceErrorStatuses.includes(status) ? 'uknown' : status;
    return {
      isStorageLimit: status === 'error:storage',
      isTokenLimit: status === 'error:token',
      isDownloadFailed: isDownloadFailure,
      isRetraining: sourceRetrainLoadingStatuses.includes(retrain_status),
      currentStatus: errorStatus,
    };
  }, [source]);

  // source in training status, remove cached data source chunks
  useEffect(() => {
    if (source.type === 'url' && (isRetraining || sourceLoadingStatuses.includes(status))) {
      queryClient.removeQueries({ queryKey: ['dataSourceChunks', source.uuid] });
    }
  }, [[source]]);

  if (!sourceErrorStatuses.includes(status) || isRetraining) {
    return (
      <div>
        <Badge
          variant="outline"
          className="w-fit border-none py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <StatusDot
            className="h-4 w-4 p-1"
            status={status === 'success' && !isRetraining ? 'success' : 'loading'}
          />
          {(sourceLoadingStatuses.includes(status) || isRetraining) && 'Training'}
          {status === 'success' && !isRetraining && 'Trained'}
        </Badge>
      </div>
    );
  }

  return (
    <div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild className="p-2">
            <Button
              variant="outline"
              size="sm"
              className="py-1 h-7"
              onClick={(e) => {
                e.stopPropagation();
                if (proceedForward) {
                  if (canRetry) {
                    retryDataSource(uuid);
                  } else if (canUpgradePlan) {
                    navigate(routePaths.upgrade);
                  }
                } else {
                  setProceedForward(true);
                }
              }}
            >
              <StatusDot className="h-4 w-4 p-1 mr-2" status={canRetry ? 'info' : 'error'} />
              {canRetry ? (
                'Re-try'
              ) : (
                <>
                  {isTokenLimit && 'Token Limit'}
                  {isStorageLimit && 'Storage Limit'}
                  {!isStorageLimit && !isTokenLimit && 'Error'}
                </>
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top" align="end">
            <p className="font-normal whitespace-normal">
              {canRetry ? (
                <>
                  {`${errors[currentStatus]}` +
                    (isDownloadFailed && isPrimaryProduct
                      ? ' You can try to enable the advanced scraper and attempt to retrain the source one more time.'
                      : ' You can re-try to process your source by cliking on the button or contact us for more information.')}
                </>
              ) : (
                <>
                  {errors[currentStatus]}
                  {(isStorageLimit || isTokenLimit) && (
                    <>
                      {canUpgradePlan
                        ? 'If you wish to continue using this source, please upgrade your subscription by clicking on the button.'
                        : 'If you wish to continue using this source, please ask owner to increase storage limit.'}
                    </>
                  )}
                  {!isStorageLimit &&
                    !isTokenLimit &&
                    'Error processing data source. The issue may be temporary or require manual review. Contact us for more information.'}
                </>
              )}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default SourceStatus;
