/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { isPrimaryProduct } from 'utils/domain';
import { Button } from 'components/ui/button';
import { PlusCircle, X } from 'lucide-react';
import { Label } from 'components/ui/label';
import { ChatbotHumanEscalation } from 'models/api/response.types';
import { Switch } from 'components/ui/switch';

const HumanEscalationOptions: React.FC<{
  settings: ChatbotHumanEscalation['human_escalation_settings'];
  setSettings: (settings: ChatbotHumanEscalation['human_escalation_settings']) => void;
}> = ({ settings, setSettings }) => {
  const [newMonitoredMessage, setNewMonitoredMessage] = useState<string>('');
  return (
    <>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="human-requested-message"
        >
          Human Agent Requested Message
        </Label>
        <p className="text-sm text-muted-foreground">
          When the AI Supervisor detects that a human agent is needed, this message will be sent to the user.
        </p>
        <Textarea
          className="min-h-[100px] bg-background"
          id="human-requested-message"
          rows={3}
          placeholder="Your request to speak with a human agent has been received. Please wait for a few moments, and you will be connected to a human representative who will be able to assist you further. You can continue to chat with our AI assistant in the meantime. Thank you for your patience!"
          value={settings.human_requested_message}
          onChange={(e) => {
            setSettings({
              ...settings,
              human_requested_message: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="support_requested"
        >
          Human Escalation Notifications
        </Label>
        <p className="text-sm text-muted-foreground">
          Enable to receive notifications when users request to chat with a human.{' '}
          {isPrimaryProduct && (
            <>
              To receive notifications properly from our app, follow our guide that will help you to enable
              all necessary settings.{' '}
              <a
                href="https://guide.gpt-trainer.com/human-support-escalation#enabled-human-support-notifications"
                target="_blank"
                rel="noreferrer"
                className="text-secondary font-medium hover:underline"
              >
                Learn More
              </a>
            </>
          )}
        </p>
        <Switch
          id="support_requested"
          checked={settings.notifications_enabled}
          onCheckedChange={(allow) => {
            if (allow && Notification.permission !== 'granted') {
              Notification.requestPermission();
            }
            setSettings({
              ...settings,
              notifications_enabled: allow,
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="notification-sound"
        >
          Notification Sound
        </Label>
        <p className="text-sm text-muted-foreground">
          Enable to hear a notification sound whenever a human support chat request is received.
        </p>
        <Switch
          id="notification-sound"
          checked={settings.sound_enabled}
          onCheckedChange={(allow) => {
            if (allow) {
              new Audio('/audio/chat-notification.mp3').play().catch((err) => {
                console.error('Playback failed:', err);
              });
            }
            setSettings({
              ...settings,
              sound_enabled: allow,
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="live-chat-starts-message"
        >
          Live Chat Starts Announcement
        </Label>
        <p className="text-sm text-muted-foreground">
          When the live chat feature is activated, you can choose to display a custom announcement to your
          users.
        </p>
        <Input
          id="live-chat-starts-message"
          className="bg-background"
          value={settings.live_chat_start_announcement || ''}
          onChange={(e) => {
            setSettings({
              ...settings,
              live_chat_start_announcement: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="live-chat-ends-message"
        >
          Live Chat Ends Announcement
        </Label>
        <p className="text-sm text-muted-foreground">
          After a live chat session is concluded, you can choose to display a custom message to your users.
        </p>
        <Input
          id="live-chat-ends-message"
          className="bg-background"
          value={settings.live_chat_end_announcement || ''}
          onChange={(e) => {
            setSettings({
              ...settings,
              live_chat_end_announcement: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="live-chat-ends-message"
        >
          AI Output Alert Triggers
        </Label>
        <p className="text-sm text-muted-foreground">
          Stay on top of your AI-generated interactions by adding specific messages for the AI to monitor. If
          the AI generates a response similar to one of your monitored messages, you&apos;ll receive an in-app
          notification. Quickly access the relevant chat to review and ensure the response aligns with your
          standards, allowing for timely intervention when needed.
        </p>
        {(!settings.monitored_messages || settings.monitored_messages.length < 10) && (
          <>
            <p className="text-sm text-muted-foreground">
              Enter the message you want to add for monitoring. You can add up to 10 messages for monitoring.
            </p>
            <div className="flex items-center gap-6">
              <Input
                className="bg-background"
                id="agent-title"
                type="text"
                placeholder="Sorry, I don't know the answer."
                maxLength={250}
                value={newMonitoredMessage}
                onChange={(e) => {
                  setNewMonitoredMessage(e.target.value);
                }}
              />
              <Button
                disabled={newMonitoredMessage.length === 0}
                onClick={() => {
                  setSettings({
                    ...settings,
                    monitored_messages: settings.monitored_messages
                      ? [newMonitoredMessage, ...settings.monitored_messages]
                      : [newMonitoredMessage],
                  });
                  setNewMonitoredMessage('');
                }}
              >
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Add new
              </Button>
            </div>
          </>
        )}
      </div>
      {settings.monitored_messages?.length > 0 && (
        <div className="flex flex-col gap-4">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="agent-title"
          >
            Messages to Monitor
          </Label>
          <div className="flex items-center gap-2 flex-wrap">
            {settings.monitored_messages.map((message, index) => (
              <div
                key={index}
                className="w-fit flex items-start justify-between break-all max-w-full overflow-hidden space-x-4 rounded-lg rounded-bl-none shadow-sm border px-3 py-[9px] text-sm"
                style={{ background: '#F2F5F9', color: '#303030' }}
              >
                <p>{message}</p>
                <button
                  type="button"
                  onClick={() => {
                    setSettings({
                      ...settings,
                      monitored_messages: settings.monitored_messages.filter(
                        (_item, currentIndex) => currentIndex !== index,
                      ),
                    });
                  }}
                  className="mt-[5px]"
                >
                  <X
                    strokeWidth={1.75}
                    className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default HumanEscalationOptions;
