/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { CirclePlus, GripVertical, Pencil, Plus, X } from 'lucide-react';
import { Checkbox } from 'components/ui/checkbox';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { v4 as uuidv4 } from 'uuid';
import { Badge } from 'components/ui/badge';
import { Switch } from 'components/ui/switch';
import { ChatBotMeta, ChatbotFormField, ChatbotFormType, defaultChatbotData } from 'utils/bot';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { cn } from 'utils/cn';
import AddDropdownOptionsDialog from 'components/Dialogs/AddDropDownOptionsDialog';
import { Textarea } from 'components/ui/textarea';

const customInputOptions: ChatbotFormType[] = ['text', 'dropdown', 'checkbox'];

const ChatbotFormBuilder: React.FC<{
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}> = ({ chatInterface, setChatInterface }) => {
  const [addDropdownOptions, setAddDropdownOptions] = useState<ChatbotFormField | undefined>(undefined);

  const addDefaultForm = (field_label?: string, newField?: boolean) => {
    const formFields = defaultChatbotData.form.fields.map((f) => {
      return { ...f, enabled: f.label === field_label };
    });

    if (newField) {
      formFields.push({
        id: uuidv4(),
        label: 'Custom field',
        type: 'text',
        enabled: true,
        default: false,
        required: false,
      });
    }

    setChatInterface({
      ...chatInterface,
      form: {
        title: defaultChatbotData.form.title,
        description: defaultChatbotData.form.description,
        fields: formFields,
        show_after_qa: 1,
        is_mandatory: false,
        button_text: defaultChatbotData.form.button_text,
      },
    });
  };

  const setFormTextDefaultField = (label: string) => {
    // for users with older bot implementation
    if (!chatInterface?.form) {
      addDefaultForm(label);
    } else {
      const updatedFields = chatInterface.form.fields.map((field, index) => ({
        ...field,
        enabled: field.label === label && index <= 3 ? !field.enabled : field.enabled,
      }));
      setChatInterface({
        ...chatInterface,
        form: {
          ...chatInterface.form,
          fields: updatedFields,
        },
      });
    }
  };

  const addCustomField = () => {
    if (!chatInterface?.form) {
      addDefaultForm(undefined, true);
    } else {
      const newFields = chatInterface.form.fields;
      newFields.push({
        id: uuidv4(),
        label: 'Custom field',
        type: 'text',
        enabled: true,
        default: false,
        required: false,
      });
      setChatInterface({
        ...chatInterface,
        form: {
          ...chatInterface.form,
          fields: newFields,
        },
      });
    }
  };

  const removeCustomFormField = (fieldId: string) => {
    setChatInterface({
      ...chatInterface,
      form: {
        ...chatInterface.form,
        fields: chatInterface.form.fields.filter((field) => field.id !== fieldId),
      },
    });
  };

  const updateCustomField = (
    fieldId: string,
    value: {
      label?: string;
      type?: ChatbotFormType;
      required?: boolean;
      dropdown_options?: string[];
    },
  ) => {
    const newFields = chatInterface.form.fields.map((field) => {
      return {
        ...field,
        label: fieldId === field.id && value.label !== undefined ? value.label : field.label,
        type: fieldId === field.id && value.type ? value.type : field.type,
        dropdown_options:
          fieldId === field.id && value.dropdown_options
            ? value.dropdown_options
            : field.dropdown_options || [],
        required:
          fieldId === field.id && value.required !== undefined ? value.required : field?.required || false,
      };
    });
    setChatInterface({
      ...chatInterface,
      form: {
        ...chatInterface.form,
        fields: newFields,
      },
    });
  };

  const columnDragEnd = (response: DropResult) => {
    // number of default fields
    const treshhold = chatInterface?.form?.fields?.filter(
      (item) => item.default === true && item.enabled === true,
    ).length;
    if (
      !response.destination ||
      response.destination.index === response.source.index ||
      (treshhold && (response.destination.index < treshhold - 1 || response.source.index < treshhold - 1))
    )
      return;
    const { destination, source } = response;
    const items = [...chatInterface.form.fields.slice(3)];
    const [reorderedItem] = items.splice(source.index - treshhold, 1);
    items.splice(destination.index - treshhold, 0, reorderedItem);
    setChatInterface({
      ...chatInterface,
      form: {
        ...chatInterface.form,
        fields: [...chatInterface.form.fields.slice(0, 3), ...items],
      },
    });
  };

  const { isDragDisabled, isFormMandatory, fieldsToShow } = useMemo(() => {
    if (chatInterface?.form) {
      return {
        isDragDisabled: chatInterface.form?.fields.every((field) => !field.enabled),
        isFormMandatory: chatInterface.form?.fields.some((field) => field?.required && field.enabled),
        fieldsToShow: chatInterface.form?.fields.filter((field) => field.enabled),
      };
    }
    return {
      isDragDisabled: false,
      isFormMandatory: false,
      fieldsToShow: [],
    };
  }, [chatInterface.form]);
  return (
    <>
      <div className="flex items-center gap-4 flex-wrap">
        <div className="flex items-center space-x-2">
          <Checkbox
            className="bg-background"
            id="email"
            checked={chatInterface?.form?.fields[0].enabled}
            onClick={() => {
              setFormTextDefaultField('Email');
            }}
          />
          <label htmlFor="email" className="text-md font-medium leading-none tracking-tight">
            Email
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            className="bg-background"
            id="name"
            checked={chatInterface?.form?.fields[1].enabled}
            onClick={() => {
              setFormTextDefaultField('Name');
            }}
          />
          <label htmlFor="name" className="text-md font-medium leading-none tracking-tight">
            Name
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            className="bg-background"
            id="phone"
            checked={chatInterface?.form?.fields[2].enabled}
            onClick={() => {
              setFormTextDefaultField('Phone');
            }}
          />
          <label htmlFor="phone" className="text-md font-medium leading-none tracking-tight">
            Phone
          </label>
        </div>
        {(!chatInterface?.form || chatInterface?.form?.fields?.length < 6) && (
          <Button size="default" onClick={addCustomField}>
            <CirclePlus className="w-4 h-4 mr-2" />
            Custom field
          </Button>
        )}
      </div>
      {chatInterface?.form?.fields?.some((field) => field.enabled) && (
        <>
          <div className="grid w-full items-center gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="form-title">
              Title
            </Label>
            <Input
              className="bg-background"
              type="text"
              id="form-title"
              autoComplete="off"
              value={chatInterface.form.title}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  form: {
                    ...chatInterface.form,
                    title: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="form-description">
              Description
            </Label>
            <p className="text-sm text-muted-foreground">
              Provide a detailed description of the form&apos;s purpose and any important information users
              should know. You can use Markdown elements for formatting.{' '}
              <a
                className="text-secondary font-medium hover:underline"
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </p>
            <Textarea
              className="min-h-[80px] bg-background"
              id="form-description"
              value={chatInterface?.form?.description || ''}
              placeholder="Let us know how to contact you ..."
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  form: {
                    ...chatInterface.form,
                    description: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div>
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="form-fields">
              Fields
            </Label>
            <DragDropContext onDragEnd={columnDragEnd}>
              <Droppable droppableId="droppablePrompts" direction="vertical">
                {(provided) => (
                  <div className="mt-1" ref={provided.innerRef} {...provided.droppableProps}>
                    {fieldsToShow.map((field, index) => (
                      <Draggable
                        key={`${field.id}`}
                        draggableId={`${field.id}`}
                        index={index}
                        isDragDisabled={isDragDisabled || field.default}
                      >
                        {(secondaryProvided) => (
                          <div
                            ref={secondaryProvided.innerRef}
                            {...secondaryProvided.draggableProps}
                            className={cn(index !== fieldsToShow.length - 1 ? 'mb-4' : '')}
                          >
                            <div className="flex items-center gap-2">
                              <Input
                                className="bg-background"
                                autoComplete="off"
                                disabled={field.default}
                                value={field.label}
                                placeholder={field.label}
                                onChange={(e) => {
                                  updateCustomField(field.id, {
                                    label: e.target.value,
                                  });
                                }}
                              />
                              {!field.default && (
                                <>
                                  <Select
                                    value={field.type}
                                    onValueChange={(value) => {
                                      updateCustomField(field.id, {
                                        type: value as ChatbotFormType,
                                      });
                                    }}
                                  >
                                    <SelectTrigger className="w-[150px] bg-background">
                                      <SelectValue placeholder="Option" />
                                    </SelectTrigger>
                                    <SelectContent className="z-[100]">
                                      {customInputOptions.map((option) => (
                                        <SelectItem key={option} value={option}>
                                          {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <X
                                    strokeWidth={1.75}
                                    className="w-4 min-w-[16px] h-4 hover:text-destructive cursor-pointer transition-colors"
                                    onClick={() => {
                                      removeCustomFormField(field.id);
                                    }}
                                  />
                                  {!isDragDisabled && (
                                    <button type="button" {...secondaryProvided.dragHandleProps}>
                                      <GripVertical
                                        strokeWidth={1.75}
                                        className="w-6 h-6 text-muted-foreground hover:text-black transition-all"
                                      />
                                    </button>
                                  )}
                                </>
                              )}
                              <div className="flex items-center space-x-2">
                                <Label
                                  className="text-sm text-muted-foreground"
                                  htmlFor={`field-${field.id}`}
                                >
                                  Required
                                </Label>
                                <Switch
                                  id={`field-${field.id}`}
                                  checked={field?.required}
                                  onCheckedChange={(required) => {
                                    updateCustomField(field.id, {
                                      required,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {field.label.length === 0 ? (
                              <p className="text-sm text-destructive mt-1 ml-1">
                                Label field can&apos;t be empty.
                              </p>
                            ) : (
                              <>
                                {chatInterface.form.fields?.some(
                                  (f) => f.label === field.label && f.id !== field.id,
                                ) && (
                                  <p className="text-xs text-destructive mt-1 ml-1">Label must be unique</p>
                                )}
                              </>
                            )}
                            {field.type === 'checkbox' && (
                              <p className="text-sm text-muted-foreground mt-1 ml-1">
                                The link may be included within the message as per the{' '}
                                <a
                                  className="font-medium text-secondary hover:underline"
                                  href="https://www.markdownguide.org/basic-syntax/#links"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  guidelines provided
                                </a>
                                .
                              </p>
                            )}
                            {field.type === 'dropdown' && (
                              <div className="flex items-center gap-2 flex-wrap mt-2 ml-1">
                                {(!field?.dropdown_options || field.dropdown_options.length === 0) && (
                                  <p className="text-xs text-destructive ml-1">
                                    At least one option is required.
                                  </p>
                                )}
                                {field?.dropdown_options?.map((option, idx) => (
                                  <Badge
                                    key={idx}
                                    variant="outline"
                                    className="overflow-hidden rounded-md bg-background"
                                  >
                                    <p className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                                      {option}
                                    </p>
                                  </Badge>
                                ))}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setAddDropdownOptions(field);
                                  }}
                                >
                                  <Badge variant="default" className="rounded-md">
                                    {field?.dropdown_options && field?.dropdown_options?.length > 0 ? (
                                      <>
                                        <Pencil strokeWidth={1.75} className="w-3 h-3 min-w-3 mr-1" />
                                        Edit options
                                      </>
                                    ) : (
                                      <>
                                        <Plus strokeWidth={1.75} className="w-3 h-3 min-w-3 mr-1" />
                                        Add new option
                                      </>
                                    )}
                                  </Badge>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="grid w-full items-center gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="qa-show-form">
              Pre-Form Q&As
            </Label>
            <p className="text-muted-foreground text-sm">
              Users can ask a few questions before the form is shown. Once the limit is reached, the form will
              be shown.
            </p>
            <Input
              id="qa-show-form"
              className="w-20 inline-block bg-background"
              type="number"
              autoComplete="off"
              value={
                chatInterface?.form?.show_after_qa !== undefined ? chatInterface?.form?.show_after_qa : 1
              }
              min={0}
              max={20}
              onChange={(e) => {
                const int = parseInt(e.target.value, 10);
                setChatInterface({
                  ...chatInterface,
                  form: {
                    ...chatInterface.form,
                    show_after_qa: int,
                  },
                });
              }}
            />
            {chatInterface?.form?.show_after_qa === 0 && (
              <p className="text-warning text-xs ml-1">Form appears immediately, upon entering the chat.</p>
            )}
          </div>
          {isFormMandatory && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <Label
                  className="text-md font-medium leading-none tracking-tight"
                  htmlFor="form_is_mandatory"
                >
                  Mandatory Form Submission
                </Label>
                <p className="text-muted-foreground text-sm">
                  By selecting this option, require users to submit the form before starting the chat.
                </p>
              </div>
              <Switch
                id="form_is_mandatory"
                checked={chatInterface.form?.is_mandatory || false}
                onCheckedChange={(enable) => {
                  setChatInterface({
                    ...chatInterface,
                    form: {
                      ...chatInterface.form,
                      is_mandatory: enable,
                    },
                  });
                }}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="form-submit-button">
              Submit Button Label
            </Label>
            <p className="text-muted-foreground text-sm">
              The button that users has to click to submit the form.
            </p>
            <Input
              className="bg-background"
              id="form-submit-button"
              type="text"
              placeholder="Submit"
              value={chatInterface?.form?.button_text || ''}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  form: {
                    ...chatInterface.form,
                    button_text: e.target.value,
                  },
                });
              }}
            />
          </div>
        </>
      )}
      {addDropdownOptions && (
        <AddDropdownOptionsDialog
          field={addDropdownOptions}
          close={() => {
            setAddDropdownOptions(undefined);
          }}
          addDropdownOptions={updateCustomField}
        />
      )}
    </>
  );
};

export default ChatbotFormBuilder;
