/* eslint-disable no-useless-concat */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { AIAgent, ChatSession, Chatbot, ChatMessage as ChatMessageType } from 'models/api/response.types';
import sessionService from 'api/session';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { canShowHumanEscalationPreviewMessage, getSelectedChatbot } from 'store/reducers/ui';
import ChatInput from 'components/helpers/chat/ChatInput';
import ChatStreamingResponse from 'components/helpers/chat/StreamingResponse/ChatStreamingResponse';
import ChatMessage from 'components/helpers/chat/ChatMessage/ChatMessage';
import ChatInitialMessages from 'components/helpers/chat/ChatInitialMessages';
import ChatHeader from 'components/helpers/chat/ChatHeader';
import {
  ChatBotMeta,
  generateChatBotMessageColors,
  generateChatMainColors,
  generateUserMessageColors,
  chatSizeVariants,
  poweredByClass,
  WidgetSize,
} from 'utils/bot';
import { cn } from 'utils/cn';
import ChatSuggestedPrompts from 'components/helpers/chat/ChatSuggestedPrompts';
import ChatScrollContainer from 'components/helpers/chat/ChatScrollContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import DebugMessage from 'components/helpers/chat/ChatMessage/DebugMessage';
import { QueryToStream, defaultQueryToStream } from 'models/api/chat.types';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { isChatMessage, scrollIfAtBottom, scrollToBottom } from 'utils/chat';
import useAiAgents from 'hooks/useAiAgents';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';

const Preview: React.FC = () => {
  const { addAlert } = useAlerts();
  const { mainWebsite, appTitle } = useWhitelabelData();
  const { canUseCustomWatermark, canRemoveGPTMark, canSeeProfilePicture, refetchChatbotSubscription } =
    useSubscriptionInfo();
  const { agents } = useAiAgents();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const navigate = useNavigate();
  const showHumanEscalationPreviewMessage = useSelector(canShowHumanEscalationPreviewMessage);
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const urlSearchParams = new URLSearchParams(search);
  const singleAgentParam = urlSearchParams.get('agent_uuid') || '';
  const [currentSession, setCurrentSession] = useState<ChatSession | undefined>(undefined);
  const [messageAwaitingOnSM, setMessageAwaitingOnSM] = useState<QueryToStream>(defaultQueryToStream);
  const [queryToStream, setQueryToStream] = useState<QueryToStream>(defaultQueryToStream);
  const [streamingResponse, setStreamingResponse] = useState('');
  const [streaming, setStreaming] = useState(false);
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const [debugModeInProgress, setDebugModeInProgress] = useState<boolean>(false);
  const [testAgent, setTestAgent] = useState<AIAgent | undefined>(undefined);
  const sessionMessagesQueryKey = ['session-messages', currentSession?.uuid];

  // for shopwing warning that HE is disabled in Preview
  useEffect(() => {
    if (chatbot.human_escalation_enabled && showHumanEscalationPreviewMessage) {
      addAlert({
        severity: 'preview_he_disabled',
        message: alerts.HE_PREVIEW,
      });
    }
  }, [chatbot]);

  const { data: chatMessages } = useQuery({
    queryKey: sessionMessagesQueryKey,
    queryFn: () => sessionService.getSessionMessages(currentSession?.uuid || ''),
    enabled: !!currentSession,
    refetchInterval: () => {
      if (debugModeInProgress && !streaming) {
        return 5_000;
      }
      return false;
    },
  });

  const metadata = useMemo(() => {
    return JSON.parse(chatbot.meta_json) as ChatBotMeta;
  }, [chatbot]);

  // if chatbot doesn't use default Inter font, load font and display it
  useEffect(() => {
    if (metadata?.font_style && metadata?.font_style !== 'Inter') {
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${metadata?.font_style?.replace('"', '')}:wght@300;400;500;600&display=swap`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }
  }, []);

  useEffect(() => {
    if (
      chatMessages &&
      chatMessages.some(
        (message) =>
          isChatMessage(message) &&
          message?.background_pending_tasks !== undefined &&
          message.background_pending_tasks !== 0,
      )
    ) {
      setDebugModeInProgress(true);
    } else if (debugModeInProgress) {
      setDebugModeInProgress(false);
    }
  }, [chatMessages]);

  // check if use single agent
  useEffect(() => {
    if (singleAgentParam && agents) {
      const index = agents.findIndex((ag) => ag.uuid === singleAgentParam && ag.active);
      if (index > -1) {
        setTestAgent(agents[index]);
      }
    }
  }, [agents]);

  // on chatbot change start new session
  useEffect(() => {
    setCurrentSession(undefined);
    setMessageAwaitingOnSM(defaultQueryToStream);
  }, [chatbot.uuid]);

  const refetchChatMessages = () => {
    // also refetch usage
    refetchChatbotSubscription();
    queryClient.invalidateQueries({ queryKey: sessionMessagesQueryKey }).then(() => {
      setStreaming(false);
      setStreamingResponse('');
      setQueryToStream(defaultQueryToStream);
      scrollIfAtBottom(0, 24);
    });
  };

  useEffect(() => {
    if (queryToStream.value !== '' && !streaming && currentSession) {
      setStreaming(true);
      setStreamingResponse('');
      scrollToBottom(300);
      queryClient.cancelQueries({ queryKey: sessionMessagesQueryKey });

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${axios.defaults.baseURL}/api/session/${currentSession.uuid}/stream-message`, true);
      xhr.onprogress = () => {
        // stream response only if it's selected within chatbot meta
        if (!metadata.ai_full_response_style) {
          setStreamingResponse(xhr.responseText);
        }
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setStreamingResponse(xhr.responseText); // final message
            refetchChatMessages();
          } else {
            refetchChatMessages();
          }
        }
      };
      xhr.setRequestHeader('Authorization', `${axios.defaults.headers.common.Authorization}`);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      const { pre_canned_response, value } = queryToStream;
      xhr.send(JSON.stringify({ query: value, ...(pre_canned_response && { pre_canned_response }) }));
    }
  }, [queryToStream, streaming, currentSession]);

  useEffect(() => {
    if (chatMessages && messageAwaitingOnSM) {
      setQueryToStream(messageAwaitingOnSM);
      setMessageAwaitingOnSM(defaultQueryToStream);
    }
  }, [chatMessages]);

  const chatReset = () => {
    setCurrentSession(undefined);
    setDebugMode(false);
    setMessageAwaitingOnSM(defaultQueryToStream);
  };

  const generateMessage = (message: QueryToStream) => {
    // first check if currentSession exist if no create one
    if (currentSession) {
      setQueryToStream(message);
    } else {
      // creating session
      setMessageAwaitingOnSM(message);
      sessionService
        .createChatbotSession(chatbot.uuid, {
          meta_json: JSON.stringify({
            chatbot_meta: metadata,
            origin: 'preview',
            read: true,
            ...(testAgent && {
              test_target_agent_uuid: testAgent?.uuid,
            }),
          }),
        })
        .then((data) => {
          setCurrentSession(data);
        });
    }
  };

  // when user updated meta_json or any other filed of single message
  const updateChatMessage = (newMessage: ChatMessageType) => {
    if (chatMessages) {
      queryClient.setQueryData(
        sessionMessagesQueryKey,
        [...chatMessages].map((message) => (message.uuid === newMessage.uuid ? newMessage : message)),
      );
    }
  };

  const showWatermark = useMemo(() => {
    return !canRemoveGPTMark || (canUseCustomWatermark && !!metadata?.custom_watermark?.label);
  }, [canRemoveGPTMark, metadata, canUseCustomWatermark]);

  const isChatDisabled = useMemo(() => {
    return streaming;
  }, [streaming]);

  const { chatMainColors, userMessageColors, chatBotMessageColors, size } = useMemo(() => {
    return {
      chatMainColors: generateChatMainColors(metadata?.theme),
      userMessageColors: generateUserMessageColors(metadata),
      chatBotMessageColors: generateChatBotMessageColors(metadata),
      size: metadata?.chat_size || ('md' as WidgetSize),
    };
  }, [metadata]);

  return (
    <div
      id="preview-iframe"
      style={{ fontFamily: metadata?.font_style }}
      className={cn(
        'py-4 px-4 sm:px-6 h-full flex overflow-y-auto relative',
        metadata.theme === 'dark' ? 'dark-theme' : '',
      )}
    >
      <div
        className="shadow-sm relative overflow-auto flex flex-1 rounded-md border-[1px] flex-col min-h-[500px] sm:min-h-0 transition-colors"
        style={{
          background: chatMainColors?.background,
          borderColor: chatMainColors.border,
        }}
      >
        <ChatHeader
          isPreview
          metadata={metadata}
          canSeeProfilePicture={canSeeProfilePicture}
          chatReset={chatReset}
          isActionsDisabled={streaming}
          size={size}
          chatMainColors={chatMainColors}
          debugMode={debugMode}
          canUseDebugMode={!!chatMessages}
          setDebugMode={setDebugMode}
          isDebugModeInProgress={debugModeInProgress || streaming}
        />
        <ChatScrollContainer
          streaming={streaming}
          streamingResponse={streamingResponse}
          className={cn(
            'flex-1 flex flex-col overflow-auto overflow-x-hidden relative',
            chatSizeVariants.chat.container[size],
          )}
        >
          {!chatMessages && (
            <ChatInitialMessages
              size={size}
              chatbotMeta={metadata}
              userMessageColors={userMessageColors}
              initialMessages={metadata.initial_messages}
              chatBotMessageColors={chatBotMessageColors}
            />
          )}
          {debugMode ? (
            <>
              {chatMessages
                ?.slice(metadata.initial_messages.length || 0)
                .map(
                  (message) =>
                    isChatMessage(message) && (
                      <DebugMessage
                        key={message?.uuid || uuidv4()}
                        message={message}
                        chatbotMeta={metadata}
                        userMessageColors={userMessageColors}
                        chatBotMessageColors={chatBotMessageColors}
                      />
                    ),
                )}
            </>
          ) : (
            <>
              {chatMessages?.map((message: any, index) => {
                const isInitialMessage = index + 1 <= metadata.initial_messages.length;
                const isFirstInitialMessage = isInitialMessage && index === 0;
                return (
                  <ChatMessage
                    isInitialMessage={isInitialMessage}
                    isFirstInitialMessage={isFirstInitialMessage}
                    size={size}
                    key={message?.uuid || uuidv4()}
                    message={message}
                    chatbotMeta={metadata}
                    userMessageColors={userMessageColors}
                    chatBotMessageColors={chatBotMessageColors}
                    chatMainColors={chatMainColors}
                    updateMessage={updateChatMessage}
                  />
                );
              })}
            </>
          )}
          {streaming && (
            <ChatStreamingResponse
              size={size}
              chatbotMeta={metadata}
              queryToStream={queryToStream}
              streamingResponse={streamingResponse}
              userMessageColors={userMessageColors}
              chatBotMessageColors={chatBotMessageColors}
            />
          )}
        </ChatScrollContainer>
        {!debugMode && (
          <>
            {metadata?.suggested_prompts?.length > 0 && (
              <ChatSuggestedPrompts
                botMeta={metadata}
                size={size}
                disabled={isChatDisabled}
                suggestedPrompts={metadata.suggested_prompts}
                generateMessage={generateMessage}
                chatMainColors={chatMainColors}
                canShowIcons={metadata?.show_suggested_prompt_icons}
              />
            )}
            <ChatInput
              size={size}
              disabled={isChatDisabled}
              placeholder={metadata?.textfield_placeholder}
              inputLength={metadata?.input_length}
              autoFocus
              generateMessage={generateMessage}
              chatMainColors={chatMainColors}
              showWatermark={showWatermark}
            />
          </>
        )}
        {(showWatermark || testAgent) && (
          <button
            type="button"
            className={cn(
              'p-2 border-t transition-all outline-none flex items-center justify-center',
              chatSizeVariants.poweredByText[size],
              testAgent
                ? 'bg-primary text-text-primary font-medium cursor-pointer'
                : poweredByClass(metadata.theme, !canRemoveGPTMark || !!metadata?.custom_watermark?.url),
            )}
            style={{
              borderColor: chatMainColors.border,
            }}
            onClick={() => {
              if (testAgent) {
                navigate(routePaths.agents);
              } else if (!canRemoveGPTMark) {
                if (mainWebsite) {
                  window.open(mainWebsite, '_blank');
                }
              } else if (metadata?.custom_watermark?.url) {
                window.open(metadata?.custom_watermark?.url, '_blank');
              }
            }}
          >
            {testAgent ? (
              `Click to exit test mode for ${testAgent.name}`
            ) : canRemoveGPTMark ? (
              <>
                {metadata?.custom_watermark?.logo && (
                  <img
                    className={cn('rounded-full', chatSizeVariants.poweredByLogo[size])}
                    src={metadata?.custom_watermark?.logo}
                    alt="Watermark logo"
                  />
                )}
                {metadata?.custom_watermark?.label}
              </>
            ) : (
              <>
                Powered by&nbsp;<span className="font-semibold">{appTitle}</span>
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Preview;
