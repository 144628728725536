/* eslint-disable import/prefer-default-export */
import { PermissionType } from 'components/helpers/CollaboratorPermissionBadge';
import { Chatbot } from 'models/api/response.types';
import { NavigateFunction } from 'react-router-dom';
import routePaths from 'routes/routePaths';

const availableRoutes: { [key: string]: string } = {
  meta: routePaths.customizations,
  'chats-leads': routePaths.inbox,
  dashboard: routePaths.dashboard,
  sources: routePaths.sources,
  agent: routePaths.agents,
};

export const checkRouteValidation = (
  currentRoute: string,
  navigate: NavigateFunction,
  chatbot_permissions?: string,
) => {
  if (!chatbot_permissions) return;

  const permissions: PermissionType[] = JSON.parse(chatbot_permissions);

  if (
    (currentRoute === routePaths.agents && !permissions.includes('agent')) ||
    (currentRoute === routePaths.dashboard && !permissions.includes('dashboard')) ||
    (currentRoute === routePaths.inbox && !permissions.includes('chats-leads')) ||
    (currentRoute === routePaths.sources && !permissions.includes('sources')) ||
    (currentRoute === routePaths.customizations && !permissions.includes('meta')) ||
    currentRoute === routePaths.collaborators
  ) {
    navigate(availableRoutes[permissions[0]]);
  }
};

export const hasInboxAccessByChatbot = (chatbot?: Chatbot) => {
  if (chatbot) {
    const { permissions } = chatbot;
    if (permissions) {
      const permissionsList: PermissionType[] = JSON.parse(permissions);
      return permissionsList.includes('chats-leads');
    }
    return true;
  }
  return false;
};

export const hasAIAgentAccessByChatbot = (chatbot?: Chatbot) => {
  if (chatbot) {
    const { permissions } = chatbot;
    if (permissions) {
      const permissionsList: PermissionType[] = JSON.parse(permissions);
      return permissionsList.includes('agent');
    }
    return true;
  }
  return false;
};
