import axios from 'axios';
import {
  ChatBotUpdateBody,
  Chatbot,
  ChatbotHumanEscalation,
  ChatbotHumanEscalationUpdateBody,
  ChatbotLabel,
  ChatbotLabelCreateBody,
  ChatbotLabelUpdateBody,
  ChatbotTransferOwnershipTicket,
  Chatbots,
  CreateChatbotBody,
  InvitationList,
  InviteBody,
  UpdatePermissionBody,
} from 'models/api/response.types';

const getUserChatbots = () => axios.get<Chatbots>(`/api/user/chatbots`).then((res) => res.data);

const createChatbot = (bot: CreateChatbotBody) =>
  axios.post<Chatbot>(`/api/chatbot/create`, bot).then((res) => res.data);

const deleteChatbot = (uuid: string) =>
  axios.delete<Chatbot>(`/api/chatbot/${uuid}/delete`).then((res) => res.data);

const updateChatbot = (uuid: string, data: ChatBotUpdateBody) =>
  axios.post<Chatbot>(`/api/chatbot/${uuid}/update`, data).then((res) => res.data);

const getChatbotById = (uuid: string) => axios.get<Chatbot>(`/api/chatbot/${uuid}`).then((res) => res.data);

const submitUserForm = (uuid: string, userData: { [key: string]: any }) =>
  axios.post(`/api/chatbot/${uuid}/webhook-form-submission`, userData);

const updateChatbotDomain = (uuid: string, meta: string) =>
  axios.post(`/api/custom-domain/${uuid}/update`, {
    meta_json: meta,
  });

const getChatbotInvitations = (uuid: string) =>
  axios.get<InvitationList>(`/chatbot/${uuid}/invitations`).then((res) => res.data);

const getChatbotTransferRequest = (uuid: string) =>
  axios.get<ChatbotTransferOwnershipTicket>(`/chatbot/${uuid}/transfer-request `).then((res) => res.data);

const initiateChatbotTransfer = (uuid: string, email: string) =>
  axios.post<ChatbotTransferOwnershipTicket>(`/api/chatbot/${uuid}/initiate-transfer`, { email });

const declineChatbotTransfer = (uuid: string) => axios.post(`/api/chatbot/${uuid}/decline-transfer`);

const acceptChatbotTransfer = (uuid: string) => axios.post(`/api/chatbot/${uuid}/accept-transfer`);

const deleteChatbotTransfer = (transfer_uuid: string) =>
  axios.post<ChatbotTransferOwnershipTicket>(`/api/chatbot/transfer-request/${transfer_uuid}/delete`);

const inviteCollaborator = (uuid: string, body: InviteBody) =>
  axios.post(`/api/chatbot/${uuid}/invite-user`, body).then((res) => res.data);

const deleteInvite = (uuid: string) => axios.post(`/api/invitation/${uuid}/delete`).then((res) => res.data);

const deletePreviousOwner = (uuid: string) =>
  axios.post(`/api/chatbot/transfer-request/${uuid}/complete`).then((res) => res.data);

const declineInvitation = (chatbot_uuid: string, uuid: string) =>
  axios
    .post(`/api/chatbot/${chatbot_uuid}/decline-invitation`, {
      invitation_uuid: uuid,
    })
    .then((res) => res.data);

const acceptInvitation = (chatbot_uuid: string, uuid: string) =>
  axios
    .post(`/api/chatbot/${chatbot_uuid}/accept-invitation`, {
      invitation_uuid: uuid,
    })
    .then((res) => res.data);

const leaveChatbot = (uuid: string) =>
  axios.post(`/api/chatbot/${uuid}/leave`, { uuid }).then((res) => res.data);

const updateCollaboratorPermissions = (chatbot_uuid: string, body: UpdatePermissionBody) =>
  axios.post(`/api/chatbot/${chatbot_uuid}/update-user-permissions`, body).then((res) => res.data);

const authenticateCustomerUser = (uuid: string, token: string) =>
  axios.post(`/api/chatbot/${uuid}/webhook-auth`, { token }).then((res) => res.data);

const getChatbotLabels = (uuid: string) =>
  axios.get<ChatbotLabel[]>(`/chatbot/${uuid}/chatbot-labels`).then((res) => res.data);

const createChatbotLabel = (uuid: string, body: ChatbotLabelCreateBody) =>
  axios.post<ChatbotLabel>(`/chatbot/${uuid}/label/create`, body).then((res) => res.data);

const updateChatbotLabel = (uuid: string, body: ChatbotLabelUpdateBody) =>
  axios.post<ChatbotLabel>(`/chatbot-label/${uuid}/update`, body).then((res) => res.data);

const deleteChatbotLabel = (uuid: string) =>
  axios.delete(`/chatbot-label/${uuid}/delete`).then((res) => res.data);

const getChatbotHumanEscalationSettings = (uuid: string) =>
  axios.get<ChatbotHumanEscalation>(`/chatbot/${uuid}/human-escalation/settings`).then((res) => res.data);

const udpateChatbotHumanEscalationSettings = (uuid: string, body: ChatbotHumanEscalationUpdateBody) =>
  axios
    .post<ChatbotHumanEscalation>(`/chatbot/${uuid}/human-escalation/update`, body)
    .then((res) => res.data);

const duplicateChatbot = (uuid: string) =>
  axios.post<Chatbot>(`/chatbot/${uuid}/duplicate`).then((res) => res.data);

const chatbotService = {
  getUserChatbots,
  createChatbot,
  deleteChatbot,
  updateChatbot,
  getChatbotById,
  deletePreviousOwner,
  submitUserForm,
  authenticateCustomerUser,
  updateChatbotDomain,
  inviteCollaborator,
  getChatbotInvitations,
  deleteChatbotTransfer,
  declineInvitation,
  acceptInvitation,
  updateCollaboratorPermissions,
  getChatbotTransferRequest,
  initiateChatbotTransfer,
  declineChatbotTransfer,
  leaveChatbot,
  deleteInvite,
  acceptChatbotTransfer,
  getChatbotLabels,
  createChatbotLabel,
  updateChatbotLabel,
  deleteChatbotLabel,
  getChatbotHumanEscalationSettings,
  udpateChatbotHumanEscalationSettings,
  duplicateChatbot,
};

export default chatbotService;
