import React, { useRef, useState } from 'react';
import { ChatBotMeta } from 'utils/bot';
import {
  MessageSquare,
  Image as IconImage,
  Loader2,
  UploadIcon,
  MessageCircleDashed,
  MessageCircle,
} from 'lucide-react';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { SketchPicker } from 'react-color';
import ChatbotInitialMessagesAppearance from 'components/helpers/ChatbotCustomizations/ChatbotInitialMessagesAppearance';
import ChatbotUserMessageColorAppearance from 'components/helpers/ChatbotCustomizations/ChatbotUserMessageColorAppearance';
import { Switch } from 'components/ui/switch';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot, FileResponseData } from 'models/api/response.types';
import { Button } from 'components/ui/button';
import { useAlerts } from 'providers/AlertProvider';
import useChatbots from 'hooks/useChatbots';
import contentService from 'api/content';
import { alerts } from 'utils/alert';
import ImageCropperDialog from 'components/Dialogs/ImageCropperDialog';
import { Toggle } from 'components/ui/toggle';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';

interface MessagesAppearanceProps extends React.HTMLProps<HTMLDivElement> {
  chatInterface: ChatBotMeta;
  initialMessages: string;
  setInitialMessages: (messages: string) => void;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const MessagesAppearance: React.FC<MessagesAppearanceProps> = ({
  chatInterface,
  initialMessages,
  setInitialMessages,
  setChatInterface,
}) => {
  const { addAlert } = useAlerts();
  const { updateChatbotByUUID } = useChatbots();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const buttonAvatarRef = useRef<HTMLInputElement>(null);
  const [imageToUpload, setImageToUpload] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const updateAvatarLogo = (file: File) => {
    setLoading(true);
    contentService.uploadChatbotFile(chatbot.uuid, file, 'chatbot_avatar', (response?: FileResponseData) => {
      if (response) {
        const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
        updateChatbotByUUID(chatbot.uuid, {
          ...chatbot,
          meta_json: JSON.stringify({
            ...meta,
            message_avatar: {
              ...meta.message_avatar,
              logo: `${response.url}?v=${new Date().toISOString()}`,
            },
          }),
          // need for img rerendering
          modified_at: new Date().toISOString(),
        });
        setChatInterface({
          ...chatInterface,
          message_avatar: {
            ...chatInterface?.message_avatar,
            logo: `${response.url}?v=${new Date().toISOString()}`,
          },
        });
      }
      setLoading(false);
    });
  };

  const deleteAvatarLogo = () => {
    contentService.deleteChatbotFile(chatbot.uuid, 'chatbot_avatar').then(() => {
      const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
      updateChatbotByUUID(chatbot.uuid, {
        ...chatbot,
        meta_json: JSON.stringify({ ...meta, message_avatar: { ...meta.message_avatar, logo: '' } }),
      });
      setChatInterface({
        ...chatInterface,
        message_avatar: {
          ...chatInterface?.message_avatar,
          logo: '',
        },
      });
    });
  };

  return (
    <>
      <AccordionItem
        id="customizations-messages"
        className="border bg-background rounded-md transition-all"
        value="messages"
      >
        <AccordionTrigger className="px-6 text-left hover:no-underline">
          <div>
            <div className="flex items-centrer gap-2">
              <MessageSquare strokeWidth={1.75} className="w-6 h-6" />
              Messages
            </div>
            <p className="text-xs text-muted-foreground ml-8">
              Text display style, message bubbles appearance, initial greeting text, font color.
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="ai_response_style"
            >
              AI Response Style
            </Label>
            <p className="text-muted-foreground text-sm">
              Streaming response works like ChatGPT, where words appear in the chat bubble as they are
              generated, allowing users to read the message as it’s produced. Simultaneous response resembles
              human-to-human chat, showing a &quot;loading&quot; indicator, with the full message displayed
              only after it’s completely generated.
            </p>
            <div id="ai_response_style" className="flex items-center gap-2">
              <Toggle
                aria-label="streaming"
                pressed={!chatInterface.ai_full_response_style}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    ai_full_response_style: false,
                  });
                }}
              >
                <MessageCircleDashed strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Streaming
              </Toggle>
              <Toggle
                aria-label="simultaneous"
                pressed={chatInterface.ai_full_response_style}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    ai_full_response_style: true,
                  });
                }}
              >
                <MessageCircle strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Simultaneous
              </Toggle>
            </div>
          </div>
          <ChatbotInitialMessagesAppearance
            chatInterface={chatInterface}
            initialMessages={initialMessages}
            setInitialMessages={setInitialMessages}
            setChatInterface={setChatInterface}
          />
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="message-time"
            >
              Initial Message Timing Control
            </Label>
            <p className="text-sm text-muted-foreground">
              You can specify the number of seconds after which the chat will populate initial messages. To
              disable this feature and prevent automatic population, simply enter -1 in the input field.
            </p>
            <Input
              id="message-time"
              type="number"
              min={-1}
              max={100}
              value={chatInterface.show_initial_messages_time}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  show_initial_messages_time: parseInt(e.target.value, 10),
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="message-avatar"
            >
              Enable Avatars
            </Label>
            <p className="text-sm text-muted-foreground">
              Display avatars with each message. Live messages use the account image if available, otherwise,
              the avatar logo. If both don&apos;t exist, a default icon will be used.
            </p>
            <Switch
              id="message-avatar"
              checked={chatInterface?.message_avatar?.enabled || false}
              onCheckedChange={(allowed) => {
                setChatInterface({
                  ...chatInterface,
                  message_avatar: {
                    ...chatInterface.message_avatar,
                    enabled: allowed,
                  },
                });
              }}
            />
          </div>
          {chatInterface?.message_avatar?.enabled && (
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center"
                htmlFor="avatar-logo"
              >
                Avatar Logo
              </Label>
              <p className="text-sm text-muted-foreground">
                Set a specific avatar logo for AI-generated messages, providing a distinct and recognizable
                icon to differentiate AI responses from live messages.
              </p>
              <div className="flex items-center gap-2">
                <div
                  id="avatar-logo-container"
                  className="w-[50px] bg-background h-[50px] relative rounded-full border-[1px] flex items-center justify-center"
                >
                  {!chatInterface?.message_avatar?.logo ? (
                    <IconImage strokeWidth={1.75} className="w-6 h-6" />
                  ) : (
                    <img
                      className="w-[50px] h-[50px] rounded-full"
                      src={`${chatInterface.message_avatar.logo}?v=${chatbot.modified_at}`}
                      alt="Chatbot Avatar"
                    />
                  )}
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <Button
                      disabled={loading}
                      size="sm"
                      variant="outline"
                      className="bg-background"
                      onClick={() => {
                        if (!loading) {
                          buttonAvatarRef?.current?.click();
                        }
                      }}
                    >
                      {loading ? (
                        <Loader2 className="mr-2 w-4 h-4 animate-spin transition-all" />
                      ) : (
                        <UploadIcon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                      )}
                      Upload
                    </Button>
                    <Button
                      disabled={!chatInterface?.message_avatar?.logo}
                      size="sm"
                      variant="ghost"
                      onClick={deleteAvatarLogo}
                    >
                      Remove
                    </Button>
                  </div>
                  <p className="text-muted-foreground text-xs mt-1">
                    Supports JPG, PNG, and SVG files up to 1MB
                  </p>
                </div>
              </div>
              <Input
                className="hidden"
                accept="image/png, image/jpg, image/svg+xml"
                ref={buttonAvatarRef}
                id="profile-picture"
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    if (file.size <= 1000000) {
                      setImageToUpload(file);
                      e.target.value = '';
                    } else {
                      addAlert({
                        severity: 'error',
                        message: alerts.IMAGE_SIZE_EXCEEDED,
                      });
                    }
                  }
                }}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="message-time"
            >
              Chatbot Message Color
            </Label>
            <p className="text-sm text-muted-foreground">
              Choose a distinct color for messages answered by AI.
            </p>
            <div className="flex items-center gap-4 w-fit">
              <Popover>
                <PopoverTrigger asChild>
                  <div
                    className="p-1 w-[110px] rounded-md border flex items-center gap-2 cursor-pointer hover:scale-[1.02] transition-all"
                    style={{
                      borderColor: chatInterface.chatbot_message_color,
                    }}
                  >
                    <div
                      id="chatbot-message-color"
                      className="w-[25px] h-[25px] rounded-md"
                      style={{
                        backgroundColor: chatInterface.chatbot_message_color,
                      }}
                    />
                    <p className="text-sm font-normal text-muted-foreground">
                      {chatInterface.chatbot_message_color}
                    </p>
                  </div>
                </PopoverTrigger>
                <PopoverContent className="p-0 w-auto">
                  <SketchPicker
                    color={chatInterface.chatbot_message_color}
                    onChange={(color: { hex: string }) => {
                      setChatInterface({
                        ...chatInterface,
                        chatbot_message_color: color.hex,
                      });
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <ChatbotUserMessageColorAppearance
            chatInterface={chatInterface}
            setChatInterface={setChatInterface}
          />
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="name"
            >
              Custom Placeholder Text
            </Label>
            <p className="text-sm text-muted-foreground">
              Enter your own placeholder text for the chat input field, guiding users with a hint or
              instruction for their queries.
            </p>
            <Input
              id="name"
              type="text"
              value={chatInterface?.textfield_placeholder || ''}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  textfield_placeholder: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="source-button-label">
              Source Button Label
            </Label>
            <p className="text-muted-foreground text-sm">
              The button that becomes visible to users to access your sources.
            </p>
            <Input
              id="source-button-label"
              type="text"
              placeholder="Sources"
              value={chatInterface?.source_label || ''}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  source_label: e.target.value,
                });
              }}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
      <ImageCropperDialog
        title="Avatar Logo"
        img={imageToUpload}
        cropperCallback={(croppedImage?: File) => {
          if (croppedImage) {
            updateAvatarLogo(croppedImage);
          }
          setImageToUpload(undefined);
        }}
      />
    </>
  );
};

export default MessagesAppearance;
