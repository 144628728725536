/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Label } from 'components/ui/label';
import { KeyRound } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Switch } from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { useAlerts } from 'providers/AlertProvider';
import { isPrimaryProduct } from 'utils/domain';
import { alerts } from 'utils/alert';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { cn } from 'utils/cn';
import { Badge } from 'components/ui/badge';

interface AuthenticationProps extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean;
  isUrlError: boolean;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const Authentication: React.FC<AuthenticationProps> = ({
  disabled,
  isUrlError,
  chatInterface,
  setChatInterface,
}) => {
  const { addAlert } = useAlerts();

  return (
    <AccordionItem
      id="customizations-authentication"
      disabled={disabled}
      className="border bg-background rounded-md transition-all relative"
      value="authentication"
    >
      {disabled && <Badge className="absolute -top-2 p-1 right-12 rounded-md">Upgrade Required</Badge>}
      <AccordionTrigger className={cn('px-6 text-left hover:no-underline', disabled && 'opacity-40')}>
        <div>
          <div className="flex items-centrer gap-2">
            <KeyRound strokeWidth={1.75} className="w-6 h-6" />
            User Identity Verification
          </div>
          <p className="text-xs text-muted-foreground ml-8">
            Connect the AI chat with your own existing user authentication system. Pass user-specific
            information to the AI for personalized engagement.
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="border-t py-6 px-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="user-authentication">
            Enable User Identity
          </Label>
          {isPrimaryProduct && (
            <p className="text-muted-foreground text-sm">
              <a
                className="font-bold text-secondary hover:underline"
                href="https://guide.gpt-trainer.com/user-identity"
                target="_blank"
                rel="noreferrer"
              >
                Detailed Intructions
              </a>{' '}
              on how integrate authentication feature in your chatbot.
            </p>
          )}
          <Switch
            disabled={disabled}
            id="user-authentication"
            checked={chatInterface?.webhook_auth?.enabled || false}
            onCheckedChange={(show) => {
              let switchVisibility = false;
              if (show && chatInterface?.visibility !== 'hybrid') {
                switchVisibility = true;
                addAlert({
                  severity: 'warning',
                  message: alerts.UNAVAILABLE_MODE,
                  timeout: 10000,
                });
              }

              setChatInterface({
                ...chatInterface,
                ...(switchVisibility && {
                  visibility: 'hybrid',
                }),
                webhook_auth: {
                  ...chatInterface?.webhook_auth,
                  enabled: show,
                },
              });
            }}
          />
        </div>
        {chatInterface?.webhook_auth?.enabled && (
          <>
            <div className="flex flex-col gap-2">
              <Label className="text-md font-semibold leading-none tracking-tight flex items-center">
                API Endpoint
              </Label>
              <p className="text-sm text-muted-foreground">
                We will send tokens for validation to this URL through a{' '}
                <strong className="text-secondary">POST</strong> request.
              </p>
              <div>
                <Input
                  type="text"
                  autoComplete="off"
                  disabled={disabled}
                  value={chatInterface?.webhook_auth?.url || ''}
                  placeholder="https://my-custom-site.com/webhook/"
                  onChange={(e) => {
                    setChatInterface({
                      ...chatInterface,
                      webhook_auth: {
                        ...chatInterface.webhook_auth,
                        url: e.target.value,
                      },
                    });
                  }}
                />
                {isUrlError && chatInterface?.webhook_auth?.url && (
                  <p className="text-xs text-destructive ml-1 mt-1">Invalid API.</p>
                )}
                {!chatInterface?.webhook_auth?.url && (
                  <p className="text-xs text-destructive ml-1 mt-1">API is required.</p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="save-data">
                Save Data on Success
              </Label>
              <p className="text-muted-foreground text-sm">
                Ensures that all data received during a successful authentication process is securely stored.
                This data becomes accessible for you at any time in <strong>Chats</strong> tab.
              </p>
              <Switch
                disabled={disabled}
                id="save-data"
                checked={chatInterface?.webhook_auth?.save_response || false}
                onCheckedChange={(save) => {
                  setChatInterface({
                    ...chatInterface,
                    webhook_auth: {
                      ...chatInterface?.webhook_auth,
                      save_response: save,
                    },
                  });
                }}
              />
            </div>
            {chatInterface?.webhook_auth?.save_response && (
              <div className="flex flex-col gap-2">
                <Label className="text-md font-medium leading-none tracking-tight" htmlFor="save-data">
                  Allow AI to Access Data
                </Label>
                <p className="text-muted-foreground text-sm">
                  By enabling this option, the AI will be aware of all saved contextual data and can use it
                  during the chat to provide more relevant and personalized responses.
                </p>
                <Switch
                  disabled={disabled}
                  id="save-data"
                  checked={chatInterface?.webhook_auth?.use_response_as_context ?? false}
                  onCheckedChange={(save) => {
                    setChatInterface({
                      ...chatInterface,
                      webhook_auth: {
                        ...chatInterface?.webhook_auth,
                        use_response_as_context: save,
                      },
                    });
                  }}
                />
              </div>
            )}
            <div className="grid w-full items-center gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight"
                htmlFor="authentication-error-message"
              >
                Error Message
              </Label>
              <p className="text-muted-foreground text-sm">
                Personalized error message here that will be displayed users in case of invalid authorization.
              </p>
              <Input
                type="text"
                id="authentication-error-message"
                autoComplete="off"
                placeholder="Authentication failed. Invalid user."
                disabled={disabled}
                value={chatInterface?.webhook_auth?.error_message || ''}
                onChange={(e) => {
                  setChatInterface({
                    ...chatInterface,
                    webhook_auth: {
                      ...chatInterface.webhook_auth,
                      error_message: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

export default Authentication;
