/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { TabsContent } from 'components/ui/tabs';
import { Textarea } from 'components/ui/textarea';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { AIAgentEditData } from 'models/api/response.types';
import { Asterisk } from 'lucide-react';
import PreCannedSpamDefenseOptions from './PreCannedSpamDefenseOptions';
import UserFacingOptions from './UserFacingOptions';

const GeneralTab: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  const [nameError, setNameError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);

  useEffect(() => {
    const { name, description } = data;
    if (name.length === 0) {
      setNameError(true);
    } else if (nameError) {
      setNameError(false);
    }

    if (description.length === 0) {
      setDescriptionError(true);
    } else if (descriptionError) {
      setDescriptionError(false);
    }
  }, [data]);

  return (
    <TabsContent className="flex flex-col gap-6 mt-0" value="general">
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="agent-title"
        >
          Agent Name
          <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
        </Label>
        <p className="text-sm text-muted-foreground">
          A distinctive name for your AI agent that will help you identify it among others.
        </p>
        <Input
          id="agent-title"
          type="text"
          maxLength={250}
          value={data.name}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setNameError(true);
            } else if (nameError) {
              setNameError(false);
            }
            setData({
              ...data,
              name: e.target.value,
            });
          }}
        />
        {nameError && (
          <p className="ml-1 text-xs text-destructive">Title is required field and can&apos;t be empty.</p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="agent-description"
        >
          Agent Description
          <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
        </Label>
        <p className="text-sm text-muted-foreground">
          This description is crucial for defining the AI agent&apos;s purpose and operational scope. It helps
          the AI understand its role, objectives, and the tasks it is expected to perform. Be as detailed and
          explicit as possible when writing the description.
        </p>
        <Textarea
          className="min-h-[100px]"
          id="agent-description"
          rows={3}
          value={data.description}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setDescriptionError(true);
            } else if (descriptionError) {
              setDescriptionError(false);
            }
            setData({
              ...data,
              description: e.target.value,
            });
          }}
        />
        {descriptionError && (
          <p className="ml-1 text-xs text-destructive">
            Description is required field and can&apos;t be empty.
          </p>
        )}
      </div>
      {data.type === 'user-facing' && <UserFacingOptions data={data} setData={setData} />}
      {['pre-canned', 'spam-defense'].includes(data.type) && (
        <PreCannedSpamDefenseOptions data={data} setData={setData} />
      )}
    </TabsContent>
  );
};

export default GeneralTab;
