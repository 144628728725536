import React, { Fragment, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Asterisk } from 'lucide-react';
import { Input } from 'components/ui/input';
import { Switch } from 'components/ui/switch';
import { Toggle } from 'components/ui/toggle';
import { ChatbotVariable } from 'utils/bot';

const CreateEditChatbotVariableDialog: React.FC<{
  variableToEdit?: ChatbotVariable;
  currentVariables: ChatbotVariable[];
  close: (variable?: ChatbotVariable) => void;
}> = ({ variableToEdit, currentVariables, close }) => {
  const [name, setName] = useState<string>(variableToEdit?.name || '');
  const [description, setDescription] = useState<string>(variableToEdit?.description || '');
  const [example, setExample] = useState<string>(variableToEdit?.example || '');
  const [type, setType] = useState<'string' | 'number' | 'boolean'>(variableToEdit?.type || 'string');
  const [defaultValue, setDefaultValue] = useState<{ content: string; static: number }>(
    variableToEdit?.default_value || { content: '', static: 0 },
  );

  const existingVariableNames: string[] = useMemo(() => {
    return currentVariables.map((param: ChatbotVariable) => param.name);
  }, [currentVariables]);

  const isNameExistsError = useMemo(() => {
    return (
      name !== variableToEdit?.name &&
      existingVariableNames.some((var_name) => var_name.toLocaleLowerCase() === name.toLocaleLowerCase())
    );
  }, [name]);

  const validToUpdate = useMemo(() => {
    return !isNameExistsError && name && description;
  }, [name, description]);

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full overflow-hidden items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-h-full flex flex-col max-w-xl transform overflow-hidden rounded-md bg-background py-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900 px-6">
                  {variableToEdit ? 'Edit Variable' : 'Create New Variable'}
                </DialogTitle>
                <div className="mt-4 flex flex-col gap-4 flex-1 overflow-auto px-6">
                  <div>
                    <Label className="flex items-center" htmlFor="variable-name">
                      Name
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      This is the variable name. Variables must have unique names. We recommend that you
                      follow standard snakecase convention{' '}
                      <span className="text-secondary font-medium">(e.g., variable_one, variable_two).</span>
                    </p>
                    <Input
                      id="variable-name"
                      className="mt-2"
                      type="text"
                      autoFocus
                      value={name}
                      maxLength={50}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, description, example, type, default_value: defaultValue });
                        }
                      }}
                    />
                    {isNameExistsError && (
                      <p className="text-xs text-destructive ml-1 mt-1">
                        Name already exists and used by another variable within your agents.
                      </p>
                    )}
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="variable-description">
                      Description
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      Define the purpose of the variable. Please be as explicit as possible because the AI
                      relies on this description to identify the variable in which to store extracted data
                      from functions or the conversation.
                    </p>
                    <Input
                      id="variable-description"
                      className="mt-2"
                      type="text"
                      value={description}
                      maxLength={250}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, type, description, example, default_value: defaultValue });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="variable-description">
                      Data Type
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      Define the data type of the variable. This helps the AI understand the format of the
                      data being collected and facilitate better consistency.
                    </p>
                    <div id="variable-type" className="flex items-center gap-2 flex-wrap mt-2">
                      <Toggle
                        aria-label="string"
                        pressed={type === 'string'}
                        className="whitespace-nowrap"
                        onPressedChange={() => {
                          setType('string');
                        }}
                      >
                        Text
                      </Toggle>
                      <Toggle
                        aria-label="number"
                        pressed={type === 'number'}
                        className="whitespace-nowrap"
                        onPressedChange={() => {
                          setType('number');
                        }}
                      >
                        Number
                      </Toggle>
                      <Toggle
                        aria-label="boolean"
                        pressed={type === 'boolean'}
                        className="whitespace-nowrap"
                        onPressedChange={() => {
                          setType('boolean');
                        }}
                      >
                        True / False
                      </Toggle>
                    </div>
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="variable-example">
                      Example
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      Provide examples for the format of the data that should go into the variable{' '}
                      <span className="text-secondary font-medium">
                        (e.g., for email addresses: alice@company.com, bob@school.edu, calvin@agency.org)
                      </span>
                      .
                    </p>
                    <Input
                      id="variable-example"
                      className="mt-2"
                      type="text"
                      value={example}
                      maxLength={250}
                      onChange={(e) => {
                        setExample(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, description, example, type, default_value: defaultValue });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="default-value">
                      Default value
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      Value that will be automatically applied upon the initial creation of the variable.
                    </p>
                    <Input
                      id="default-value"
                      className="mt-2"
                      type="text"
                      value={defaultValue.content}
                      maxLength={50}
                      onChange={(e) => {
                        setDefaultValue({ ...defaultValue, content: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, description, example, type, default_value: defaultValue });
                        }
                      }}
                    />
                  </div>
                  {defaultValue.content && (
                    <div>
                      <Label className="flex items-center" htmlFor="fixed-value">
                        Fixed value
                      </Label>
                      <p className="text-sm text-muted-foreground mt-1">
                        When enabled, prevents the AI agent from updating the variable&apos;s value, ensuring
                        it remains constant.
                      </p>
                      <Switch
                        id="fixed-value"
                        checked={!!defaultValue.static}
                        onCheckedChange={(enable) => {
                          setDefaultValue({
                            ...defaultValue,
                            static: enable ? 1 : 0,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-4 mt-6 justify-end px-6">
                  <Button variant="outline" onClick={() => close()}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      close({ name, description, example, type, default_value: defaultValue });
                    }}
                    disabled={!validToUpdate}
                  >
                    {variableToEdit ? 'Save' : 'Create'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateEditChatbotVariableDialog;
